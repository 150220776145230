/* eslint-disable */
import React, { useState } from "react";
import { useAuth } from "../../AuthContext"; // make sure the import path is correct
import { signIn } from "aws-amplify/auth";

// CareplanONE Website React components
import { Box, Button, TextField, Container, Typography } from "@mui/material";

import BackgroundImage from "assets/images/cp1-bg-paywall.jpg";
import { useVisiblityTimer } from 'useVisiblityTimer';

function Paywall() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { logIn } = useAuth();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      // Using the same email for username and password
      try {
        let username = email;
        let password = "Welcome!";

        const { isSignedIn, nextStep } = await signIn({ username, password });

        console.log(`${isSignedIn} and ${JSON.stringify(nextStep)}`);
        if (isSignedIn) {
            logIn(); 
        }
      } catch (error) {
        console.log("error signing in", error);
      }

      // Here, you might want to redirect the user, store user data, or update the UI
    } catch (error) {
      console.error("error signing in:", error);
      // Here, handle the error. You might want to show an error message to the user
    }
  };

  return (
    <>
      <Box
        sx={({ breakpoints, typography: { size } }) => ({
          margin: 0,
          padding: 0,
          height: "100vh", // Makes Box take up the full viewport height
          position: "relative", // This is important for the pseudo-element positioning
          textAlign: "center",
          display: "flex", // Make this a flex container
          justifyContent: "center", // Horizontally center children
          alignItems: "center", // Vertically center children

          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${BackgroundImage})`,
            backgroundPosition: "bottom",
            backgroundSize: "130%",
            backgroundRepeat: "no-repeat",
            transform: "scaleX(-1)",
            zIndex: -1,
            [breakpoints.down("lg")]: {
              backgroundSize: "cover",
              backgroundPosition: "center",
            },
          },
        })}
      >
        <Box
          sx={({ breakpoints, typography: { size } }) => ({
            width: "30%",
            [breakpoints.down("lg")]: {
              width: "95%",
            },
          })}
        >
          <Typography variant="h4" color="common.white">
            COMING SOON
          </Typography>
          <Typography variant="body2" color="common.white">
            We’re currently invite only. If you’ve been invited, verify your email down below to
            enter.
          </Typography>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            sx={{ margin: "20px 0", input: { color: "white" } }} // Adds some margin around the TextField
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ bgcolor: "white", color: "black" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default Paywall;
 