/* eslint-disable */

import { Box } from "@mui/material";
import MuiLink from "@mui/material/Link";
import defaultLogo from '../../assets/images/cp1-logo.png';

export function LogoLink({ logo = defaultLogo, textAlign = { xs: "left", sm: "left", md: "right" } }) {
    return (
        <Box sx={{ textAlign: textAlign, mx: 0, mb: "1%", padding: 0 }}>
            <Box component={MuiLink} href="/#home">
                <Box component="img" src={logo} sx={{ width: { xs: "8em", sm: "11em" } }} />
            </Box>
        </Box>
    );
}