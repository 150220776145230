/* eslint-disable */
const { Box, Container, Typography, Card } = require("@mui/material");
import MuiLink from "@mui/material/Link";

// styles
import colors from "../../../../assets/theme/base/colors";
const { white, grey, cpAqua, black } = colors;
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import bgExec from "../../../../assets/images/cp1-execdashboard-flipped.png";
import logo from "../../../../assets/images/cp1-logo-dark-mode-white-careplan-link.png";
import { Link } from "react-router-dom";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';
function ProductDescription() {
    const [isVisible, visibleTime, ref] = useVisiblityTimer('ProductDescription', { threshold: 0.5 });
    const theme = useTheme();
    const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box component="section" id="product-description" ref={ref}>
            <Box
                component="header"
                minHeight="50vh"
                width="100%"
                sx={({ breakpoints, typography: { size } }) => ({
                    padding: "2%",
                    paddingTop: "3%",
                    paddingBottom: "3%",
                    display: "grid",
                })}
            >
                <Container maxWidth='xl'>
                    <LogoLink logo={logo} textAlign="left" />
                </Container>
                <Box
                    minHeight="50vh"
                    width="100%"
                    sx={({ breakpoints, typography: { size }, functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                        backgroundImage: `${linearGradient(
                            rgba(gradients.dark.main, 0),
                            rgba(gradients.dark.state, 0)
                        )}, url(${bgExec})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center 80%',
                        backgroundRepeat: "no-repeat",
                        textAlign: "left",
                        [breakpoints.down('md')]: {
                            backgroundPosition: 'center 10%',
                            minHeight: '500px'
                        },
                    })}
                >
                </Box>
                <Container maxWidth={isSmOrDown ? false : 'xl'} disableGutters={isSmOrDown}>
                    <Box component="header"
                        width="100%"
                        height="100%"
                        sx={() => ({
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center"
                        })}>
                        <Card sx={{
                            py: "2%",
                            mt: { xs: "-20em", ls: "-19em", sm: "-30%", md: "-12%", lg: "-9%" },
                            width: "100%",
                            backgroundColor: "rgba(255, 255, 255, 0.93)", // white with 85% opacity

                        }}>
                            <Container width="xl">
                                <Box sx={{
                                    mt: "1%", height: "29%", display: "flex",
                                    flexDirection: { xs: "column", sm: "row" }
                                }}>
                                    <Box
                                        padding="1%"
                                        sx={{
                                            backgroundColor: "#d9d9d9",
                                            width: { xs: "100%", sm: "79%" },
                                            mr: { xs: "0%", sm: "1%" },
                                            padding: "1%"
                                        }}>
                                        <Typography variant="body2">
                                            CareplanONE simplifies
                                            the care journey from preparation to recovery across the entire spectrum of surgeries, procedures, and disease management. CareplanONE packages care plans, educational videos, messaging, remote patient monitoring, and more into an accessible web-based experience that’s proven to engage patients, improve outcomes and increase patient satisfaction. All this engagement data is shared with the patient’s care team, helping clinicians make more well-informed decisions.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            backgroundColor: "#2b2a3d",
                                            width: { xs: "100%", sm: "20%" },
                                            padding: "1%",
                                            mt: { xs: "1%", sm: "0%" },
                                        }}>
                                        <Typography variant="body2" color="#e8717c">
                                            Learn more <br />
                                        </Typography>
                                        <Typography variant="body2" color="#848d8d">
                                            Watch videos, calculate your ROI,  and speak to an expert at {" "}
                                        </Typography>
                                        <Typography variant="body2" color={white.main}>
                                            careplanone.com.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Container>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default ProductDescription;