/* eslint-disable */

// @mui material components
import Container from "@mui/material/Container";

// styles
import colors from "assets/theme/base/colors";
const { white, grey, cpAqua } = colors;

// Images
import PhoneImage from "assets/images/cp1-phone.png";
import WomanImage from "assets/images/cp1-woman.png";
import logo from "../../../assets/images/cp1-logo.png";

import { Box, Card, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import DefaultCounterCard from "framework/Cards/CounterCards/DefaultCounterCard";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from "useVisiblityTimer";

function How() {
  const [isVisible, visibleTime, ref] = useVisiblityTimer("How", { threshold: 0.5 });
  return (
    <div ref={ref}>
      <Box
        component="header"
        width="100%"
        sx={({ breakpoints, typography: { size } }) => ({
          display: "grid",
        })}
      >
        <Container maxWidth="xl">
          <LogoLink />
          <Typography
            variant="h3"
            color="black"
            sx={({ breakpoints, typography: { size } }) => ({
              /*marginLeft: '40%',
              marginRight: '40%',*/
              marginBottom: "1%",
            })}
          >
            Engage patients <br />
            Measure outcomes
          </Typography>
          <Typography variant="body2" color="black" mb="2%">
            When selecting a population health management platform, you should look for software
            that’s effective at both engaging patients and measuring outcomes. Used by thousands of
            patients daily, CareplanONE makes it easy to connect with patients and achieve your
            population health goals.
          </Typography>
          <Card
            sx={{
              p: 2,
              pb: 0,
              mt: 2,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              color="#44d6c9ff" // if your Typography component is designed to accept 'color' prop like Material-UI's Typography
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#44d6c9ff", // alternatively, set the color here within 'sx'
              })}
            >
              Engage patients during their recovery and make it easy to stay connected
            </Typography>
            <Typography
              variant="h4"
              color="black"
              sx={({ breakpoints, typography: { size } }) => ({})}
            >
              and make it easy to stay connected
            </Typography>
            <Box
              sx={({ breakpoints, typography: { size } }) => ({
                display: "flex",
                mt: "2%",
                [breakpoints.down("lg")]: {
                  display: "block",
                },
              })}
            >
              <Box
                sx={({ breakpoints, typography: { size } }) => ({
                  width: "40%",
                  [breakpoints.down("lg")]: {
                    width: "100%",
                  },
                })}
              >
                <DefaultCounterCard
                  count={80}
                  animateCount={{ isVisible }}
                  suffix="%"
                  description="8 out of 10 patients enroll
                  with CareplanONE. While
                  other systems collect
                  dust, CareplanONE
                  collects vital data for your
                  practice."
                  color={cpAqua.main}
                />
              </Box>
              <Box
                sx={({ breakpoints, typography: { size } }) => ({
                  width: "40%",
                  [breakpoints.down("lg")]: {
                    width: "100%",
                  },
                })}
              >
                <DefaultCounterCard
                  count={81}
                  animateCount={{ isVisible }}
                  suffix="%"
                  description="On average 81% of
                  patients are compliant
                  with CareplanONE, which
                  means they are
                  completing activities and
                  recording them in the app."
                  color={cpAqua.main}
                />
              </Box>
              <Box
                sx={({ breakpoints, typography: { size } }) => ({
                  width: "20%",
                  pb: 0,
                  [breakpoints.down("lg")]: {
                    width: "100%",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
              >
                <img src={PhoneImage} />
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              p: 2,
              pb: 0,
              mt: 2,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              color="#44d6c9ff" // if your Typography component is designed to accept 'color' prop like Material-UI's Typography
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#44d6c9ff", // alternatively, set the color here within 'sx'
              })}
            >
              Collect data and analyze trends
            </Typography>
            <Typography
              variant="h4"
              color="black"
              sx={({ breakpoints, typography: { size } }) => ({})}
            >
              with patient-reported outcomes
            </Typography>
            <Box
              sx={({ breakpoints, typography: { size } }) => ({
                display: "flex",
                mt: "2%",
                [breakpoints.down("lg")]: {
                  display: "block",
                },
              })}
            >
              <Box
                sx={({ breakpoints, typography: { size } }) => ({
                  width: "26.6%",
                  [breakpoints.down("lg")]: {
                    width: "100%",
                  },
                })}
              >
                <DefaultCounterCard
                  count={31}
                  animateCount={{ isVisible }}
                  suffix=" points"
                  description="average 3-month
                  improvement;
                  Hip Disability and
                  Osteoarthritis Score"
                  color={cpAqua.main}
                />
              </Box>
              <Box
                sx={({ breakpoints, typography: { size } }) => ({
                  width: "26.6%",
                  marginRight: "10px",
                  [breakpoints.down("lg")]: {
                    width: "100%",
                  },
                })}
              >
                <DefaultCounterCard
                  count={21}
                  animateCount={{ isVisible }}
                  suffix=" points"
                  description="average 3-month
                  improvement;
                  Disabilities of the Arm,
                  Shoulder, and Hand"
                  color={cpAqua.main}
                />
              </Box>
              <Box
                sx={({ breakpoints, typography: { size } }) => ({
                  width: "26.6%",
                  [breakpoints.down("lg")]: {
                    width: "100%",
                  },
                })}
              >
                <DefaultCounterCard
                  count={26.6}
                  animateCount={{ isVisible }}
                  suffix=" points"
                  description="average 3-month
                  improvement;
                  Knee Disability and
                  Osteoarthritis Score"
                  color={cpAqua.main}
                />
              </Box>
              <Box
                sx={({ breakpoints, typography: { size } }) => ({
                  width: "20%",
                  pb: 0,
                  mb: -3,
                  [breakpoints.down("lg")]: {
                    width: "100%",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
              >
                <img src={WomanImage} />
              </Box>
            </Box>
          </Card>
          {/*When this button is clicked, it should be replaced by the circle in the image*/}
          {/*<Grid container spacing={3} justifyContent="center" pt={{ xs: 16, lg: 6 }}>
            <Grid item xs={12} md={1}>
              <Box
                display="flex"
                flexDirection={{ xs: "row", md: "column" }}
                justifyContent="center"
                alignItems="center"
                px={2}
                mb={{ xs: 8, md: 0 }}
              >
                <Tooltip title="My Profile" placement="right">
                  <MKAvatar
                    src={team1}
                    alt="Profile Picture"
                    size="lg"
                    variant="rounded"
                    sx={{ cursor: "pointer" }}
                  />
                </Tooltip>

                <Box my={{ xs: 0, md: 2 }} mx={{ xs: 2, md: 0 }}>
                  <Tooltip title="Home" placement="right">
                    <Button
                      iconOnly
                      size="large"
                      sx={({ palette: { black }, borders: { borderRadius } }) => ({
                        color: black.main,
                        borderRadius: borderRadius.lg,
                      })}
                    >
                      <Icon color="inherit">home</Icon>
                    </Button>
                  </Tooltip>
                </Box>
                <Box mb={{ xs: 0, md: 2 }} mr={{ xs: 2, md: 0 }}>
                  <Tooltip title="Search" placement="right">
                    <Button
                      iconOnly
                      size="large"
                      sx={({ palette: { black }, borders: { borderRadius } }) => ({
                        color: black.main,
                        borderRadius: borderRadius.lg,
                      })}
                    >
                      <Icon color="inherit">search</Icon>
                    </Button>
                  </Tooltip>
                </Box>
                <Tooltip title="Minimize" placement="right">
                  <Button
                    iconOnly
                    size="large"
                    sx={({ palette: { black }, borders: { borderRadius } }) => ({
                      color: black.main,
                      borderRadius: borderRadius.lg,
                    })}
                  >
                    <Icon color="inherit">more_horiz</Icon>
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} lg={11} xl={8}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "center", md: "flex-start" }}
                ml={{ xs: 1, md: 4 }}
                mt={-1}
              >
                <Box>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: ({ typography: { d1, h2 } }) => ({
                        xs: h2.fontSize,
                        lg: d1.fontSize,
                      }),
                    }}
                    color="white"
                    lineHeight={1}
                  >
                    28&deg;C
                  </Typography>
                  <Typography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                    textTransform="uppercase"
                  >
                    cloudy
                  </Typography>
                </Box>
                <Box component="img" src={sunCloud} width="30%" />
              </Box>
              <Box mt={3} mb={8} ml={{ xs: 1, md: 4 }} mr={{ xs: 1, md: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TodoList />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box mb={3}>
                      <TodoCard />
                    </Box>
                    <Emails />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box mb={3}>
                      <MediaPlayer />
                    </Box>
                    <Messages />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
                  </Grid>*/}
        </Container>
      </Box>
    </div>
  );
}

export default How;
