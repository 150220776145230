// @mui icons
/*import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";*/

// CareplanONE Website React components
import { Typography } from "@mui/material";
import Logo from "assets/images/cp1-logo.png";

// Images

const date = new Date().getFullYear();

export default {
  brand: {
    route: "/",
    image: Logo,
  },
  /*socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/careplanone/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/careplanone",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/careplanoneofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],*/
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/" },
        { name: "blog", href: "/" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "providers", href: "/" },
        { name: "patients", href: "/" },
        { name: "investors", href: "/" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/" },
        { name: "knowledge center", href: "/" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/" },
        { name: "privacy policy", href: "/" },
        { name: "licenses (EULA)", href: "/" },
      ],
    },
  ],
  copyright: (
    <Typography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <Typography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        CareplanONE
      </Typography>
      .
    </Typography>
  ),
};
