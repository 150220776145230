/* eslint-disable */

// @mui material components
import Container from "@mui/material/Container";

// styles
import colors from "assets/theme/base/colors";
const { cpAqua } = colors;

// Images
import PhoneImage from "assets/images/cp1-phone.png";
import WomanImage from "assets/images/cp1-woman.png";

import { Box, Card, Typography } from "@mui/material";
import DefaultCounterCard from "framework/Cards/CounterCards/DefaultCounterCard";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';

function How2() {
  const [isVisible, visibleTime, ref] = useVisiblityTimer('How2', { threshold: 0.5 });
  return (
    <div ref={ref}>
      <Box
        component="header"
        width="100%"
        sx={({ breakpoints, typography: { size } }) => ({
          display: "grid",
        })}
      >
        <Container maxWidth="xl">
          <LogoLink />
          <Typography
            variant="h3"
            color="black"
            sx={({ breakpoints, typography: { size } }) => ({
              /*marginLeft: '40%',
              marginRight: '40%',*/
              marginBottom: '1%'
            })}
          >
            Improve outcomes <br />Save money
          </Typography>
          <Typography
            variant="body2"
            color="black"
            sx={({ breakpoints, typography: { size } }) => ({
              /*marginLeft: '40%',
              marginRight: '40%',*/
              marginBottom: '2%'
            })}
          >
            When selecting a population health management platform, you should
            look for software that’s effective at both engaging patients and
            measuring outcomes. Used by thousands of patients daily, CareplanONE
            makes it easy to connect with patients and achieve your population
            health goals.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "column", md: "column", lg: 'row' }, height: "100%" }}>
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "68%" }, height: "100%" }}>
              <Card
                sx={{
                  p: 2,
                  pb: 0,
                  mt: 2,
                  backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <Typography variant="h4"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    mb: "auto"
                  })}
                >
                  Improve outcomes through better
                  patient engagement, reducing
                  readmissions to the hospital
                </Typography>
                <Box sx={({ breakpoints, typography: { size } }) => ({
                  display: "flex",
                  mt: "2%",
                  [breakpoints.down("lg")]: {
                    display: 'block'
                  }
                })}>
                  <Box sx={({ breakpoints, typography: { size } }) => ({
                    width: "40%",
                    [breakpoints.down("lg")]: {
                      width: '100%'
                    },
                  })}>
                    <DefaultCounterCard
                      count={15}
                      animateCount={{ isVisible }}
                      suffix="%"
                      description="National average readmission rate"
                    />
                  </Box>
                  <Box sx={({ breakpoints, typography: { size } }) => ({
                    width: "40%",
                    [breakpoints.down("lg")]: {
                      width: '100%'
                    },
                  })}>
                    <DefaultCounterCard
                      count={3.8}
                      animateCount={{ isVisible }}
                      suffix="%"
                      description="CareplanONE average readmission rate"
                      color={cpAqua.main}
                    />
                  </Box>
                  <Box sx={({ breakpoints, typography: { size } }) => ({
                    width: "20%",
                    pb: 0,
                    [breakpoints.down("lg")]: {
                      width: '100%',
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  })}>
                    <img src={WomanImage} />
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  p: 2,
                  pb: 0,
                  mt: 2,
                  backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <Typography variant="h4"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                  })}
                >
                  Save money and avoid costly
                  penalties, while providing your
                  patients with a better experience
                </Typography>
                <Box sx={({ breakpoints, typography: { size } }) => ({
                  display: "flex",
                  mt: "2%",
                  [breakpoints.down("lg")]: {
                    display: 'block'
                  },
                })}>
                  <Box sx={({ breakpoints, typography: { size } }) => ({
                    width: "40%",
                    [breakpoints.down("lg")]: {
                      width: '100%'
                    },
                  })}>
                    <DefaultCounterCard
                      count={15000}
                      animateCount={{ isVisible }}
                      prefix="$"
                      description="Average cost of a readmission"
                    />
                  </Box>
                  <Box sx={({ breakpoints, typography: { size } }) => ({
                    width: "40%",
                    [breakpoints.down("lg")]: {
                      width: '100%'
                    },
                  })}>
                    <DefaultCounterCard
                      count={613000}
                      animateCount={{ isVisible }}
                      prefix="$"
                      description="CareplanONE average annual savings"
                      color={cpAqua.main}
                    />
                  </Box>
                  <Box sx={({ breakpoints, typography: { size } }) => ({
                    width: "20%",
                    pb: 0,
                    mb: -3,
                    [breakpoints.down("lg")]: {
                      width: '100%',
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  })}>
                    <img src={PhoneImage} />
                  </Box>
                </Box>
              </Card>
            </Box>
            <Box sx={{ width: { md: "100%", lg: "30%" }, height: "100%" }}>
              <Card sx={{ mt: 2, p: 2, ml: { md: 0, lg: 2 }, }}>
                <Typography variant="h4">Collect data
                  and analyze
                  trends with
                  patient-reported
                  outcomes</Typography>
                <Box sx={{ mt: "2%" }}>
                  <DefaultCounterCard
                    count={31}
                    animateCount={{ isVisible }}
                    suffix=" points"
                    description="average 3-month
                      improvement;
                      Hip Disability and
                      Osteoarthritis Score"
                    color={cpAqua.main}
                  />
                </Box>
                <Box sx={{ mt: "2%" }}>
                  <DefaultCounterCard
                    count={21}
                    animateCount={{ isVisible }}
                    suffix=" points"
                    description="average 3-month improvement; Disabilities of the Arm, Shoulder, and Hand"
                    color={cpAqua.main}
                  />
                </Box>
                <Box sx={{ mt: "2%" }}>
                  <DefaultCounterCard
                    count={15}
                    animateCount={{ isVisible }}
                    suffix=" points"
                    description="average 3-month improvement; Knee Disability and Osteoarthritis Score"
                    color={cpAqua.main}
                  />
                </Box>
              </Card>
            </Box>
          </Box>
          {/*When this button is clicked, it should be replaced by the circle in the image*/}
          {/*<Grid container spacing={3} justifyContent="center" pt={{ xs: 16, lg: 6 }}>
            <Grid item xs={12} md={1}>
              <Box
                display="flex"
                flexDirection={{ xs: "row", md: "column" }}
                justifyContent="center"
                alignItems="center"
                px={2}
                mb={{ xs: 8, md: 0 }}
              >
                <Tooltip title="My Profile" placement="right">
                  <MKAvatar
                    src={team1}
                    alt="Profile Picture"
                    size="lg"
                    variant="rounded"
                    sx={{ cursor: "pointer" }}
                  />
                </Tooltip>

                <Box my={{ xs: 0, md: 2 }} mx={{ xs: 2, md: 0 }}>
                  <Tooltip title="Home" placement="right">
                    <Button
                      iconOnly
                      size="large"
                      sx={({ palette: { black }, borders: { borderRadius } }) => ({
                        color: black.main,
                        borderRadius: borderRadius.lg,
                      })}
                    >
                      <Icon color="inherit">home</Icon>
                    </Button>
                  </Tooltip>
                </Box>
                <Box mb={{ xs: 0, md: 2 }} mr={{ xs: 2, md: 0 }}>
                  <Tooltip title="Search" placement="right">
                    <Button
                      iconOnly
                      size="large"
                      sx={({ palette: { black }, borders: { borderRadius } }) => ({
                        color: black.main,
                        borderRadius: borderRadius.lg,
                      })}
                    >
                      <Icon color="inherit">search</Icon>
                    </Button>
                  </Tooltip>
                </Box>
                <Tooltip title="Minimize" placement="right">
                  <Button
                    iconOnly
                    size="large"
                    sx={({ palette: { black }, borders: { borderRadius } }) => ({
                      color: black.main,
                      borderRadius: borderRadius.lg,
                    })}
                  >
                    <Icon color="inherit">more_horiz</Icon>
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} lg={11} xl={8}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "center", md: "flex-start" }}
                ml={{ xs: 1, md: 4 }}
                mt={-1}
              >
                <Box>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: ({ typography: { d1, h2 } }) => ({
                        xs: h2.fontSize,
                        lg: d1.fontSize,
                      }),
                    }}
                    color="white"
                    lineHeight={1}
                  >
                    28&deg;C
                  </Typography>
                  <Typography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                    textTransform="uppercase"
                  >
                    cloudy
                  </Typography>
                </Box>
                <Box component="img" src={sunCloud} width="30%" />
              </Box>
              <Box mt={3} mb={8} ml={{ xs: 1, md: 4 }} mr={{ xs: 1, md: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TodoList />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box mb={3}>
                      <TodoCard />
                    </Box>
                    <Emails />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box mb={3}>
                      <MediaPlayer />
                    </Box>
                    <Messages />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
                  </Grid>*/}
        </Container>
      </Box>
    </div>
  );
}

export default How2;