/**
=========================================================
* CareplanONE Website
=========================================================






 =========================================================


*/

export default {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
