/* eslint-disable */
// @mui material components
import Container from "@mui/material/Container";
// Images
import PageThreeImage from "assets/images/cp1-pagethree.jpg";
import { Box, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import logo from "assets/images/cp1-logo.png";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';

function ProviderBenefits() {
    const [isVisible, visibleTime, ref] = useVisiblityTimer('ProviderBenefits', { threshold: 0.5 });

    return (
        <div ref={ref}>
            <Box
                component="header"
                width="100%"
                sx={() => ({
                    display: "grid",
                })}
            >
                <Container maxWidth="xl">
                    <LogoLink />
                    <Box
                        sx={({ breakpoints }) => ({
                            display: "flex",
                            flexDirection: "row",
                            height: "fit-content",
                            [breakpoints.down("sm")]: {
                                display: "grid",
                                gridTemplateRows: "auto auto", // Define two rows
                            },
                            /*marginLeft: '40%',
                            marginRight: '40%',*/
                        })}>
                        <Box sx={({ breakpoints }) => ({
                            width: "50%",
                            height: 'fit-content',
                            overflow: "hidden",
                            [breakpoints.down("sm")]: {
                                display: "none"
                            },
                        })}>
                            <Box component="img" src={PageThreeImage} sx={{
                                width: '100%',
                                height: '45em',
                                objectFit: 'cover',
                                objectPosition: 'center top',
                                overflow: "hidden"
                            }}/>
                        </Box>
                        <Box sx={({ breakpoints }) => ({
                            width: "50%",
                            paddingLeft: "1%",
                            [breakpoints.down("sm")]: {
                                width: "100%"
                            },
                        })}>
                            <Typography variant="h3" sx={() => ({
                                marginBottom: '7%',
                            })}>
                                How much money could you
                                save if 100% of your patients
                                were prepared for scheduled
                                surgeries?
                            </Typography>
                            <Typography variant="body2">
                                Annual Volume
                            </Typography>
                            <Typography variant="h4" sx={() => ({
                                marginBottom: '3%',
                            })}>
                                1,000 procedures
                            </Typography>
                            <Typography variant="body2">
                                Estimated Annual Savings
                            </Typography>
                            <Typography variant="h4" sx={() => ({
                                marginBottom: '3%',
                            })}>
                                $260,140
                            </Typography>
                            <Box sx={() => ({
                                display: "flex", flexDirection: {xs: "column", sm: "column", md: "row"}
                            })}>
                                <Typography variant="body2" sx={() => ({
                                    width: {xs: "100%", sm: "100%", md: "33%"}, pb: {xs: "3%", sm: "3%", md: 0},
                                })}>
                                    <strong>Financial Value</strong>
                                    <br />
                                    Increase
                                    efficiency
                                    <br />
                                    Reduce cost
                                    of care
                                    <br />
                                    Improve
                                    outcomes
                                    and patient
                                    experience
                                </Typography>
                                <Typography variant="body2" sx={() => ({
                                    width: {xs: "100%", sm: "100%", md: "33%"},
                                    paddingLeft: { xs: "0%", sm: "0%", md: "1%" },
                                    paddingRight: "1%",
                                    pb: {xs: "3%", sm: "3%", md: 0},
                                })}>
                                    <strong>Clinical Value</strong>
                                    <br />
                                    Patient stays connected
                                    <br />
                                    Reduce post-op mortality
                                    <br />
                                    Empower
                                    patients to
                                    self-manage
                                    their care
                                </Typography>
                                <Typography variant="body2" sx={() => ({
                                    width: {xs: "100%", sm: "100%", md: "33%"},
                                })}>
                                    <strong>Practice Value</strong>
                                    <br />
                                    Profit protection
                                    <br />
                                    Competitive advantage
                                    <br />
                                    Increase referrals and retention
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}

export default ProviderBenefits;