/* eslint-disable */

// @mui material components
import { Box, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import Container from "@mui/material/Container";

// Images
import LiveDemoImage from "assets/images/cp1-livedemo.jpg";
import logo from "../../../assets/images/cp1-logo.png";
import { Link } from "react-router-dom";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';

function ActionableData() {
  const [isVisible, visibleTime, ref] = useVisiblityTimer('ActionableData', { threshold: 0.5 });
  return (
    <div ref={ref}>
      <Box
        component="header"
        width="100%"
        sx={({ breakpoints, typography: { size } }) => ({
          display: "grid",
        })}
      >
        <Container maxWidth="xl">
          <LogoLink />
          <Typography variant="h3" color="black" sx={({ breakpoints, typography: { size } }) => ({
            marginBottom: '1%'
          })}>
            Actionable Data
          </Typography>
          <Typography variant="body2" color="black">
            CareplanONE simplifies the care journey from preparation to
            recovery across the entire spectrum of surgeries, procedures,
            and disease management. Comprehensive care plans—including
            videos, messaging, remote patient monitoring, and more—are
            accessible via a simple smartphone app.<br /><br />
            All engagement data is shared with the patient’s care team,
            helping clinicians make more well-informed decisions.
          </Typography>
          <img src={LiveDemoImage} width="100%" style={{ marginTop: '3%' }} />
        </Container>
      </Box>
    </div>
  )
}

export default ActionableData;