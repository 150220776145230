/**
=========================================================
* CareplanONE Website
=========================================================






 =========================================================


*/

/**
 * The base breakpoints for the CareplanONE Website React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire CareplanONE Website React using thie file.
 */

export default {
  values: {
    xs: 0,
    ls: 375,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};
