import { useState, useEffect, useRef } from "react";
import { record } from "aws-amplify/analytics";
import { fetchUserAttributes } from "aws-amplify/auth";

export const useVisiblityTimer = (componentName, options = {}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [visibleTime, setVisibleTime] = useState(0);
  const startTimeRef = useRef();
  const ref = useRef(null);

  const startTimer = () => {
    startTimeRef.current = Date.now();
    console.log(`${componentName} became visible`);
  };

  const stopTimer = async () => {
    if (startTimeRef.current) {
      const duration = Date.now() - startTimeRef.current;
      setVisibleTime(duration);
      startTimeRef.current = undefined;
      console.log(`${componentName} became invisible, visible for ${duration} ms`);
      // send component view duration to AWS Pinpoint
      if (duration > 3000) {
        const userAttributes = await fetchUserAttributes();
        console.log(userAttributes["custom:userType"]);
        record({
          name: "ComponentViewTime",
          attributes: {
            componentName: componentName,
            sessionEmail: userAttributes.email,
            userType: userAttributes["custom:userType"],
          },
          metrics: { millisecondsViewed: duration },
        });
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (!startTimeRef.current) {
          setIsVisible(true);
          startTimer();
        }
      } else {
        if (startTimeRef.current) {
          setIsVisible(false);
          stopTimer();
        }
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      stopTimer(); // Ensure timer is stopped when the component unmounts
    };
  }, [ref, options]);

  return [isVisible, visibleTime, ref];
};
