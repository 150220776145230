/*
=========================================================
* CareplanONE Website
=========================================================
*/

/* eslint-disable */
// PUT SEPARATORS BETWEEN COMPONENTS ON CARD
// @mui material components
import React from "react";
import { useState, useEffect } from "react";
// CareplanONE Website React examples
import DefaultNavbar from "framework/Navbars/DefaultNavbar";
import DefaultFooter from "framework/Footers/DefaultFooter";

// styles
import colors from "assets/theme/base/colors";
const { white, grey, cpAqua } = colors;

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/cp1-bg-intro.jpg";
import bgCircle from "assets/images/cp1-features-circle.png";
import { Box, Icon, Typography } from "@mui/material";
import How from "./components/How";
import ActionableData from "./components/ActionableData";
import Benefits from "./components/ProductBenefits";
import PatientBenefits from "./components/PatientBenefits";
import ProviderBenefits from "./components/ProviderBenefits";
import Offerings from "./components/Offerings";
import Description from "./components/ProductDescription";
import Savings from "./components/Savings";
import How2 from "./components/How2";
import Testimonials from "./components/Testimonials";
import CarePlans from "./components/OrthopaedicCarePlans";

import MuiLink from "@mui/material/Link";

function Home() {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const duration = 500; // Duration of the animation
    const initialScale = 0; // Initial scale (starts from 0)
    const maxScale = 1.1; // Maximum scale (a bit larger than normal)
    const endScale = 1; // End scale (normal size)
    const frameRate = 10; // 10 milliseconds per frame
    const totalFrames = duration / frameRate;
    let frame = 0;
  
    const interval = setInterval(() => {
      frame++;
      // Adjust the calculation for scaling factor
      let progress = frame / totalFrames;
      let newScale;
  
      if (progress <= 0.5) {
        // First half of the animation: scale up from 0 to 1.1
        newScale = initialScale + (maxScale - initialScale) * Math.sin(progress * Math.PI);
      } else {
        // Second half of the animation: scale down from 1.1 to 1
        newScale = maxScale - (maxScale - endScale) * Math.sin((progress - 0.5) * Math.PI);
      }
  
      setScale(newScale);
  
      if (frame >= totalFrames) {
        clearInterval(interval);
        setScale(endScale); // Ensure it ends at normal size
      }
    }, frameRate);
  
    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);  

  return (
    <Box>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/#",
          label: "request a demo",
          color: "white",
        }}
        transparent
        light
      />
      <Box
        component="header"
        id="home"
        minHeight="100vh"
        width="100%"
        sx={({ breakpoints, typography: { size } }) => ({
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.3),
              rgba(gradients.dark.state, 0.3)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 0%",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#EDEFF2",
          filter: "contrast(150%)",
          [breakpoints.down("xl")]: {
            backgroundPosition: "35% 0%",
          },
          [breakpoints.down("lg")]: {
            backgroundPosition: "35% 0%",
          },
          [breakpoints.down("md")]: {
            backgroundPosition: "35% 0%",
            minHeight: "85vh"
          },
          [breakpoints.down("sm")]: {
            backgroundPosition: "0% 0%",
            minHeight: "70vh"
          },
          [breakpoints.down("ls")]: {
            backgroundPosition: "0% 0%",
            minHeight: "60vh"
          },
          overflowX: "hidden",
          overflowX: "hidden",
          textAlign: "left", // Align inline content to the left
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start", // Align flex items to the start of the container
        })}
      >
        <Box
          sx={({ breakpoints, typography: { size } }) => ({
            marginTop: "5em",
            marginLeft: "10%",
            padding: "1%",
            width: "37%",
            textAlign: "left",
            [breakpoints.down("xl")]: {
              fontSize: "2xl",
              marginLeft: "5%",
              width: "50%",
            },
            [breakpoints.down("lg")]: {
              fontSize: "2xl",
              marginLeft: "10%",
            },
            [breakpoints.down("md")]: {
              fontSize: "2xl",
              width: "65%",
            },
            [breakpoints.down("sm")]: {
              fontSize: "2xl",
              width: "75%",
            },
          })}
        >
          <Box
            sx={({ breakpoints, typography: { size } }) => ({
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0),
                  rgba(gradients.dark.state, 0)
                )}, url(${bgCircle})`,
              backgroundSize: "500px", // This makes your background image cover the entire box
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "500px",
              width: "500px",
              textAlign: "left",
              display: "flex", // Enables flexbox
              flexDirection: "column", // Stacks flex items vertically
              justifyContent: "center", // Centers items vertically
              alignItems: "center", // Centers items horizontally
              transform: `scale(${scale})`, // Use scale for transformation
              transition: 'transform 0.1s ease-out', // Smooth transition for the pop effect
              [breakpoints.down("lg")]: {
                backgroundSize: "450px",
                height: "450px",
                width: "450px",
              },
              [breakpoints.down("sm")]: {
                backgroundSize: "325px",
                height: "325px",
                width: "325px",
              },
              [breakpoints.down("ls")]: {
                backgroundSize: "250px",
                height: "250px",
                width: "250px",
              },
            })}
          >
            <Typography
              variant="h2Logo"
              color={white.main}
              sx={({ breakpoints, typography: { size } }) => ({
                fontFamily: "Avenir",
                [breakpoints.down("lg")]: {
                  fontSize: size["3xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["lg"],
                },
              })}
            >
              Careplan
              <span style={{ color: cpAqua.dark }}>ONE</span>
            </Typography>
          </Box>
          <Typography
            id="tag-line"
            variant="h4Logo"
            color="#ffffff"
            lineHeight={1}
            sx={({ breakpoints, typography: { size } }) => ({
              fontFamily: "Avenir",
              mt: "2em",
              [breakpoints.down("lg")]: {
                fontSize: size["xl"],
              },
              [breakpoints.down("sm")]: {
                fontSize: size["lg"],
              },
            })}
            mr="2%"
          >
            Guide patients through their surgical or non-surgical procedure from preparation to
            recovery.
          </Typography>
          <Box textAlign="left" mt="10%">
            <Typography
              component={MuiLink}
              href="/#benefits-main"
              variant="body2"
              fontWeight="regular"
              color={cpAqua.main}
              style={{
                display: "flex", // Use flexbox for alignment
                alignItems: "center", // Vertically align items in the center
                //justifyContent: "center", // Horizontally align items in the center
                fontSize: { sm: "lg", lg: "3xl" },
              }}
            >
              Learn more <Icon>arrow_forward</Icon>
              {/* <Typography
              component={Link}
              href="/#benefits-main"
              variant="body2"
              fontWeight="regular"
              ml= "1%"
              color={white.main}
            >
              Our Value Propositions
            </Typography>
            <Typography
              component={Link}
              href="/#solutions-main"
              variant="body2"
              fontWeight="regular"
              ml= "1%"
              color={white.main}
            >
              Our Solutions
            </Typography>
            <Typography
              component={Link}
              href="/#technology-main"
              variant="body2"
              fontWeight="regular"
              ml= "1%"
              color={white.main}
            >
              Our Technology
            </Typography>
            <Typography
              component={Link}
              href="/#roi-main"
              variant="body2"
              fontWeight="regular"
              ml= "1%"
              color={white.main}
            >
              Your ROI
            </Typography> */}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -13,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.6),
          backdropFilter: "saturate(100%) blur(80px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >  */}

      <Box
        component="section"
        id="benefits-main"
        sx={{
          mt: "0.2%",
          paddingTop: "4%",
          paddingBottom: "3%",
          width: "100%",
        }}
      >
        <Benefits />
      </Box>
      <Box component="section" id="benefits-patient">
        <PatientBenefits />
      </Box>
      <Box
        component="section"
        id="benefits-address-early"
        sx={{
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          mt: "0.2%",
          paddingTop: "4%",
          paddingBottom: "3%",
        }}
      >
        <ActionableData />
      </Box>
      <Box
        component="section"
        id="benefits-value-based-program"
        sx={{
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          mt: "0.2%",
          paddingTop: "3%",
          paddingBottom: "3%",
        }}
      >
        <How />
      </Box>
      {/* </Card> */}
      <Box
        component="section"
        id="solutions-main"
        sx={{
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          width: "100%",
        }}
      >
        <Offerings />
      </Box>
      <Box
        component="section"
        id="solutions-orthopaedics"
        sx={{
          mt: "0.2%",
          paddingTop: "3%",
          paddingBottom: "3%",
          width: "100%",
        }}
      >
        <CarePlans />
      </Box>
      <Box
        component="section"
        id="technology-main"
        sx={{
          backgroundColor: cpAqua.main,
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          mt: "0.2%",
          width: "100%",
          pt: "3%",
          pb: "3%",
        }}
      >
        <Description />
      </Box>
      <Box
        component="section"
        id="testimonials"
        sx={{
          backgroundColor: cpAqua.main,
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          mt: "0.2%",
          width: "100%",
          pt: "3%",
          pb: "3%",
        }}
      >
        <Testimonials />
      </Box>
      <Box
        component="section"
        id="roi-main"
        sx={{
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          mt: "0.2%",
          paddingBottom: "3%",
          width: "100%",
        }}
      >
        <Savings />
      </Box>
      <Box
        component="section"
        id="roi-operation"
        sx={{
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          mt: "0.2%",
          paddingTop: "3%",
          paddingBottom: "3%",
          width: "100%",
        }}
      >
        <How2 />
      </Box>
      <Box
        component="section"
        id="roi-providers"
        sx={{
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          mt: "0.2%",
          paddingTop: "3%",
          paddingBottom: "3%",
        }}
      >
        <ProviderBenefits />
      </Box>
      {/* </Card> */}
      <Box pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </Box>
    </Box>
  );
}

export default Home;
