/* eslint-disable */
import { Box } from "@mui/material";
import React from "react";

function SavingsGraph() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      version="1.1"
      viewBox="0 0 297 140"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#d0d0d0"
          strokeWidth="4.575"
          d="M2.742 115.27h281.43v.527H2.742z"
        ></path>
        <path
          fill="#66e3d4"
          strokeWidth="2.73"
          d="M27.408 115.533a2.317 2.317 0 01-2.317 2.317 2.317 2.317 0 01-2.317-2.317 2.317 2.317 0 012.317-2.317 2.317 2.317 0 012.317 2.317z"
        ></path>
        <path
          fill="#66e3d4"
          strokeWidth="2.637"
          d="M25.354 22.15v93.536h-.527V22.15z"
        ></path>
        <path
          fill="#66e3d4"
          strokeWidth="2.73"
          d="M43.547 115.533a2.317 2.317 0 01-2.317 2.317 2.317 2.317 0 01-2.318-2.317 2.317 2.317 0 012.318-2.317 2.317 2.317 0 012.317 2.317z"
        ></path>
        <path
          fill="#66e3d4"
          strokeWidth="1.973"
          d="M41.493 63.346v52.34h-.527v-52.34z"
        ></path>
        <path
          fill="#66e3d4"
          strokeWidth="2.73"
          d="M59.685 115.533a2.317 2.317 0 01-2.317 2.317 2.317 2.317 0 01-2.317-2.317 2.317 2.317 0 012.317-2.317 2.317 2.317 0 012.317 2.317z"
        ></path>
        <path
          fill="#66e3d4"
          strokeWidth="1.406"
          d="M57.632 89.088v26.598h-.527V89.088z"
        ></path>
        <path
          fill="#e56670"
          strokeWidth="2.73"
          d="M128.851 115.533a2.317 2.317 0 01-2.317 2.317 2.317 2.317 0 01-2.317-2.317 2.317 2.317 0 012.317-2.317 2.317 2.317 0 012.317 2.317z"
        ></path>
        <path
          fill="#e56670"
          strokeWidth="2.637"
          d="M126.797 22.15v93.536h-.527V22.15z"
        ></path>
        <path
          fill="#6974c2"
          strokeWidth="2.73"
          d="M218.62 115.533a2.317 2.317 0 01-2.317 2.317 2.317 2.317 0 01-2.317-2.317 2.317 2.317 0 012.317-2.317 2.317 2.317 0 012.318 2.317z"
        ></path>
        <path
          fill="#6974c2"
          strokeWidth="2.637"
          d="M216.567 22.15v93.536h-.527V22.15z"
        ></path>
        <path
          fill="#6974c2"
          strokeWidth="2.73"
          d="M234.613 115.533a2.317 2.317 0 01-2.317 2.317 2.317 2.317 0 01-2.317-2.317 2.317 2.317 0 012.317-2.317 2.317 2.317 0 012.317 2.317z"
        ></path>
        <path
          fill="#6974c2"
          strokeWidth="1.972"
          d="M232.56 63.402v52.284h-.527V63.402z"
        ></path>
        <path
          fill="#6974c2"
          strokeWidth="2.73"
          d="M250.752 115.533a2.317 2.317 0 01-2.317 2.317 2.317 2.317 0 01-2.318-2.317 2.317 2.317 0 012.318-2.317 2.317 2.317 0 012.317 2.317z"
        ></path>
        <path
          fill="#6974c2"
          strokeWidth="1.415"
          d="M248.698 88.75v26.936h-.527V88.75z"
        ></path>
        <path
          fill="#66e3d4"
          strokeWidth="5.241"
          d="M34.013 25.091a2.635 2.635 0 01-2.636 2.636 2.635 2.635 0 01-2.635-2.636 2.635 2.635 0 012.635-2.635 2.635 2.635 0 012.636 2.635z"
        ></path>
        <g
          ariaLabel="1"
          style={{ lineHeight: "1.25" }}
          fill="#fff"
          strokeWidth="0.091"
          fontSize="3.645"
          transform="translate(0 -35)"
        >
          <path
            d="M30.814 59.022l.07.233.48-.15v2.271h.285v-2.57h-.211z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
        </g>
        <g
          ariaLabel="Pre-operative"
          style={{ lineHeight: "1.25" }}
          fill="#66e3d4"
          strokeWidth="0.1"
          fontFamily="Gotham"
          fontSize="3.996"
          transform="translate(0 -35)"
        >
          <path
            d="M37.158 59.294q0-.208-.076-.371-.072-.168-.212-.28-.136-.116-.332-.176-.191-.064-.427-.064h-1.047V61.2h.315v-.987h.68q.224 0 .423-.056.2-.06.348-.175.152-.116.24-.288t.088-.4zm-.32.012q0 .14-.056.256-.052.112-.156.196-.1.08-.243.124-.14.044-.312.044h-.692v-1.231h.708q.34 0 .543.156.208.151.208.455z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M37.989 60.377q0-.232.064-.407.064-.18.176-.3.112-.12.26-.18.151-.06.32-.06h.023v-.332q-.148-.004-.276.036-.127.04-.235.12-.108.076-.192.184t-.14.236v-.54h-.308V61.2h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M39.396 60.281h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .496-.104.2-.107.355-.283l-.192-.172q-.127.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.163-.22-.064-.132-.08-.3zm0-.228q.016-.151.072-.28.056-.127.143-.219.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.084.096.127.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M42.605 60.161v-.323h-1.111v.323z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M45.234 60.165q0-.22-.08-.415-.08-.196-.224-.34-.143-.148-.343-.232-.196-.088-.428-.088-.236 0-.435.088-.196.084-.34.232-.144.148-.224.348-.08.196-.08.415 0 .22.08.416.08.196.224.344.144.144.34.231.195.084.427.084.232 0 .432-.084.2-.087.343-.235.144-.148.224-.344.084-.2.084-.42zm-.316.008q0 .168-.055.316-.056.144-.156.252-.1.108-.24.172-.14.06-.308.06-.164 0-.304-.064-.14-.064-.243-.172-.1-.108-.16-.256-.056-.148-.056-.316 0-.167.056-.311.056-.148.152-.256.1-.112.24-.172.14-.064.307-.064.164 0 .304.064.14.064.244.176.104.108.16.256.06.148.06.315z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M47.876 60.165q0-.255-.084-.455t-.224-.336q-.14-.14-.32-.212-.176-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.176v-.416h-.307v2.706h.307v-1.035q.06.088.136.168.08.08.176.14.096.06.216.095.12.036.264.036.187 0 .363-.072.18-.075.316-.211.14-.14.224-.34.084-.2.084-.456zm-.316.004q0 .188-.06.34-.056.148-.156.252-.096.1-.232.156-.131.052-.28.052-.143 0-.28-.056-.13-.06-.239-.164-.104-.104-.168-.252-.06-.152-.06-.332 0-.18.06-.327.064-.148.168-.252.108-.104.24-.16.136-.06.28-.06.148 0 .28.056.131.056.231.164.1.104.156.252.06.148.06.331z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M48.627 60.281h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.063-.2-.187-.348-.124-.148-.304-.232-.18-.088-.408-.088-.215 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.328.22.188.075.4.075.295 0 .495-.104.2-.107.356-.283l-.192-.172q-.128.132-.284.212-.152.08-.368.08-.136 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.228q.016-.151.072-.28.056-.127.144-.219.088-.096.204-.148.115-.056.251-.056.156 0 .272.06.12.056.2.152.084.096.128.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M51.117 60.377q0-.232.064-.407.064-.18.175-.3.112-.12.26-.18.152-.06.32-.06h.024v-.332q-.148-.004-.276.036-.128.04-.236.12-.108.076-.191.184-.084.108-.14.236v-.54h-.308V61.2h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M53.075 59.378q.292 0 .451.144.164.14.164.42v.072q-.14-.04-.288-.064-.147-.024-.347-.024-.196 0-.36.044-.16.044-.28.127-.115.084-.18.212-.063.124-.063.288 0 .164.064.284.068.12.175.204.112.08.248.12.14.04.284.04.276 0 .455-.109.184-.107.292-.247v.311h.296v-1.262q0-.412-.228-.62-.228-.212-.651-.212-.228 0-.404.048-.176.044-.348.124l.092.252q.144-.068.292-.108.152-.044.336-.044zm.012.775q.192 0 .34.028.151.028.267.06v.2q0 .124-.056.228-.052.1-.148.176-.092.072-.22.116-.123.04-.267.04-.104 0-.2-.028-.092-.028-.164-.08-.072-.056-.116-.132-.04-.076-.04-.176 0-.2.156-.316.156-.116.448-.116z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M55.037 59.406h.655v-.272h-.655v-.623h-.308v.623h-.287v.272h.287v1.247q0 .156.044.268.044.112.12.183.08.068.188.1t.232.032q.112 0 .2-.024.091-.02.171-.064v-.263q-.08.04-.152.056-.067.016-.147.016-.156 0-.252-.076t-.096-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M56.595 58.683v-.34h-.351v.34zm-.024 2.517v-2.066h-.307V61.2z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M58.118 60.849l-.695-1.715h-.34l.895 2.082h.272l.9-2.082h-.333z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M59.697 60.281h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .495-.104.2-.107.356-.283l-.192-.172q-.128.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.063-.132-.08-.3zm0-.228q.016-.151.071-.28.056-.127.144-.219.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.083.096.127.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
        </g>
        <g
          ariaLabel="Save $25,210 annually per employee by reducing time spent on the phone."
          style={{ lineHeight: "1.25" }}
          fill="#66e3d4"
          strokeWidth="0.29"
          fontSize="3.996"
          transform="translate(0 -35)"
        >
          <path
            fill="#000"
            d="M28.986 65.319q0-.096.04-.18.044-.084.12-.144.08-.06.188-.096.112-.036.247-.036.216 0 .412.072.2.072.396.232l.184-.244q-.216-.172-.448-.255-.228-.088-.536-.088-.2 0-.367.06-.168.055-.292.16-.124.1-.192.24-.068.139-.068.307 0 .176.056.304.056.127.172.223.116.096.292.164.18.068.42.12.22.048.36.1.143.052.223.116.08.064.112.144.032.076.032.176 0 .215-.172.347-.168.132-.452.132-.291 0-.515-.096-.224-.1-.444-.3l-.196.232q.252.228.528.34.28.108.615.108.208 0 .384-.056.176-.056.3-.16.128-.104.2-.252.071-.148.071-.327 0-.324-.223-.508-.22-.184-.688-.288-.231-.048-.38-.1-.147-.055-.231-.12-.084-.067-.116-.147-.032-.08-.032-.18z"
          ></path>
          <path
            fill="#000"
            d="M31.995 65.595q.292 0 .452.144.164.14.164.42v.071q-.14-.04-.288-.064-.148-.024-.348-.024-.196 0-.36.044-.16.044-.28.128-.115.084-.179.212-.064.124-.064.287 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.283.04.276 0 .456-.108.184-.108.292-.248v.312h.295v-1.263q0-.412-.227-.62-.228-.211-.652-.211-.228 0-.403.048-.176.044-.348.124l.092.251q.144-.067.291-.107.152-.044.336-.044zm.012.775q.192 0 .34.028.152.028.268.06v.2q0 .124-.056.227-.052.1-.148.176-.092.072-.22.116-.124.04-.268.04-.104 0-.2-.028-.091-.028-.163-.08-.072-.056-.116-.132-.04-.076-.04-.175 0-.2.156-.316.155-.116.447-.116z"
          ></path>
          <path
            fill="#000"
            d="M34.313 67.065l-.695-1.714h-.34l.895 2.082h.272l.899-2.082h-.332z"
          ></path>
          <path
            fill="#000"
            d="M35.891 66.498h1.639q.004-.028.004-.052v-.044q0-.228-.064-.428-.064-.2-.188-.347-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.311.232-.132.143-.208.343-.072.196-.072.42 0 .24.08.44.084.2.223.343.14.14.328.22.188.076.4.076.296 0 .495-.104.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.151.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.131-.08-.3zm0-.228q.016-.152.072-.28.056-.128.144-.22.088-.095.204-.147.116-.056.252-.056.156 0 .272.06.12.056.2.151.083.096.127.224.048.128.06.268z"
          ></path>
          <path
            stroke="#66e3d4"
            d="M41.202 66.662q0-.304-.203-.488-.204-.188-.66-.292V64.9q.16.028.308.096.152.068.296.192l.172-.24q-.176-.136-.356-.216-.18-.083-.408-.103v-.24h-.255v.232q-.184.008-.34.068-.152.06-.264.16-.112.095-.176.227-.06.128-.06.284 0 .316.204.495.204.18.648.284v1q-.228-.025-.412-.12-.184-.097-.364-.26l-.18.24q.212.18.444.283.232.104.5.128v.4h.255v-.392q.188-.012.344-.068.16-.06.272-.16.112-.1.172-.232.063-.132.063-.295zm-1.094-.836q-.164-.04-.272-.087-.104-.052-.164-.112-.06-.06-.084-.132-.024-.072-.024-.156 0-.092.036-.172.04-.08.108-.14.072-.06.172-.096.1-.04.228-.044zm.787.856q0 .2-.152.327-.148.128-.404.14v-.955q.168.04.272.088.108.048.172.108.064.06.088.132.024.072.024.16z"
          ></path>
          <path
            stroke="#66e3d4"
            d="M42.697 64.863q.112 0 .216.04.104.036.18.108.076.068.12.168.048.096.048.22 0 .104-.032.204-.028.096-.1.2-.068.1-.18.215-.112.116-.272.26l-1.015.895v.244h1.954v-.288h-1.482l.747-.663q.184-.16.316-.292.131-.136.22-.26.087-.128.127-.255.044-.128.044-.284 0-.172-.064-.316-.064-.148-.18-.252-.115-.108-.275-.167-.156-.06-.348-.06-.184 0-.332.04-.144.04-.263.115-.116.076-.216.188-.1.112-.196.252l.232.168q.168-.236.34-.356.171-.124.411-.124z"
          ></path>
          <path
            stroke="#66e3d4"
            d="M44.26 65.982l.211.14q.124-.068.264-.112.144-.048.324-.048.156 0 .284.044.127.044.22.124.091.076.14.184.051.108.051.24 0 .136-.052.251-.048.112-.136.196-.088.084-.215.132-.124.044-.272.044-.216 0-.416-.1-.196-.104-.38-.283l-.207.227q.192.192.443.32.256.124.556.124.22 0 .403-.064.184-.068.316-.188.132-.124.204-.292.076-.171.076-.38 0-.2-.076-.359-.072-.16-.204-.268-.132-.112-.308-.172-.175-.06-.38-.06-.175 0-.303.037-.124.031-.248.087l.06-.895h1.335v-.291h-1.61z"
          ></path>
          <path
            stroke="#66e3d4"
            d="M46.493 67.765l.048.14q.228-.052.336-.18.108-.124.108-.368v-.36h-.368v.42h.144q.012.128-.052.208-.064.084-.216.14z"
          ></path>
          <path
            stroke="#66e3d4"
            d="M48.516 64.863q.111 0 .215.04.104.036.18.108.076.068.12.168.048.096.048.22 0 .104-.032.204-.028.096-.1.2-.068.1-.18.215-.112.116-.271.26l-1.015.895v.244h1.954v-.288h-1.483l.747-.663q.184-.16.316-.292.132-.136.22-.26.088-.128.128-.255.044-.128.044-.284 0-.172-.064-.316-.064-.148-.18-.252-.116-.108-.276-.167-.156-.06-.347-.06-.184 0-.332.04-.144.04-.264.115-.116.076-.216.188-.1.112-.195.252l.231.168q.168-.236.34-.356.172-.124.412-.124z"
          ></path>
          <path
            stroke="#66e3d4"
            d="M49.794 64.835l.076.256.528-.164v2.49h.312V64.6h-.232z"
          ></path>
          <path
            stroke="#66e3d4"
            d="M53.723 66.014q0-.295-.084-.555-.08-.264-.236-.46-.152-.2-.372-.312-.215-.115-.483-.115-.272 0-.492.115-.22.116-.375.316-.152.196-.236.46-.084.264-.084.56 0 .295.08.559.084.26.236.46.155.195.371.311.22.112.492.112.268 0 .487-.116.22-.116.372-.312.156-.2.24-.463.084-.264.084-.56zm-.328.008q0 .232-.06.444-.056.208-.168.367-.108.156-.268.252-.155.092-.351.092-.196 0-.356-.092-.16-.096-.272-.256-.112-.16-.171-.371-.06-.212-.06-.444t.06-.44q.06-.211.167-.367.112-.16.268-.252.16-.096.356-.096.196 0 .351.096.16.096.272.256.112.16.172.372.06.207.06.44z"
          ></path>
          <path
            fill="#000"
            d="M56.26 65.595q.292 0 .452.144.164.14.164.42v.071q-.14-.04-.288-.064-.148-.024-.348-.024-.196 0-.36.044-.16.044-.28.128-.115.084-.179.212-.064.124-.064.287 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.283.04.276 0 .456-.108.184-.108.292-.248v.312h.295v-1.263q0-.412-.227-.62-.228-.211-.652-.211-.228 0-.403.048-.176.044-.348.124l.092.251q.144-.067.292-.107.151-.044.335-.044zm.012.775q.192 0 .34.028.152.028.268.06v.2q0 .124-.056.227-.052.1-.148.176-.092.072-.22.116-.124.04-.268.04-.104 0-.2-.028-.091-.028-.163-.08-.072-.056-.116-.132-.04-.076-.04-.175 0-.2.156-.316.156-.116.447-.116z"
          ></path>
          <path
            fill="#000"
            d="M58.135 66.234q0-.144.043-.264.048-.12.132-.204.084-.087.196-.131.116-.048.248-.048.276 0 .424.167.147.168.147.456v1.207h.308v-1.283q0-.184-.056-.336-.052-.151-.156-.26-.1-.111-.247-.171-.144-.06-.332-.06-.268 0-.436.12-.168.116-.271.284v-.36h-.308v2.066h.308z"
          ></path>
          <path
            fill="#000"
            d="M60.596 66.234q0-.144.044-.264.048-.12.132-.204.084-.087.196-.131.116-.048.248-.048.275 0 .423.167.148.168.148.456v1.207h.308v-1.283q0-.184-.056-.336-.052-.151-.156-.26-.1-.111-.248-.171-.144-.06-.331-.06-.268 0-.436.12-.168.116-.272.284v-.36h-.308v2.066h.308z"
          ></path>
          <path
            fill="#000"
            d="M64.217 66.534q0 .144-.048.264-.048.12-.132.207-.08.084-.196.132-.112.044-.244.044-.275 0-.423-.168-.148-.168-.148-.455V65.35h-.308v1.283q0 .183.052.335.056.152.156.264.104.108.248.168.148.06.335.06.268 0 .436-.116.168-.12.272-.288v.36h.303V65.35h-.303z"
          ></path>
          <path
            fill="#000"
            d="M65.96 65.595q.29 0 .45.144.165.14.165.42v.071q-.14-.04-.288-.064-.148-.024-.348-.024-.196 0-.36.044-.16.044-.28.128-.115.084-.18.212-.063.124-.063.287 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.283.04.276 0 .456-.108.184-.108.292-.248v.312h.295v-1.263q0-.412-.228-.62-.227-.211-.65-.211-.229 0-.405.048-.175.044-.347.124l.092.251q.144-.067.291-.107.152-.044.336-.044zm.011.775q.192 0 .34.028.152.028.268.06v.2q0 .124-.056.227-.052.1-.148.176-.092.072-.22.116-.124.04-.268.04-.104 0-.2-.028-.092-.028-.163-.08-.072-.056-.116-.132-.04-.076-.04-.175 0-.2.156-.316.155-.116.447-.116z"
          ></path>
          <path fill="#000" d="M67.861 67.417V64.5h-.307v2.917z"></path>
          <path fill="#000" d="M68.912 67.417V64.5h-.307v2.917z"></path>
          <path
            fill="#000"
            d="M71.502 65.35h-.328l-.663 1.723-.751-1.722h-.34l.939 2.058q-.092.22-.188.304-.096.084-.236.084-.1 0-.175-.02-.072-.016-.148-.056l-.104.243q.104.052.208.076.103.028.231.028.232 0 .392-.132.164-.131.296-.451z"
          ></path>
          <path
            fill="#000"
            d="M75.274 66.382q0-.256-.084-.456t-.223-.335q-.14-.14-.32-.212-.176-.072-.36-.072-.144 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.175v-.415h-.308v2.705h.308v-1.035q.06.088.136.168.08.08.176.14.096.06.216.096.12.036.263.036.188 0 .364-.072.18-.076.316-.212.14-.14.223-.34.084-.2.084-.455zm-.315.004q0 .188-.06.34-.056.147-.156.251-.096.1-.232.156-.132.052-.28.052-.144 0-.28-.056-.131-.06-.24-.164-.103-.104-.167-.251-.06-.152-.06-.332 0-.18.06-.328.064-.148.168-.252.108-.103.24-.16.135-.06.28-.06.147 0 .28.057.13.056.23.163.1.104.157.252.06.148.06.332z"
          ></path>
          <path
            fill="#000"
            d="M76.026 66.498h1.638q.004-.028.004-.052v-.044q0-.228-.064-.428-.064-.2-.188-.347-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.143-.208.343-.072.196-.072.42 0 .24.08.44.084.2.224.343.14.14.327.22.188.076.4.076.296 0 .495-.104.2-.108.356-.284l-.192-.172q-.128.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.163-.22-.064-.131-.08-.3zm0-.228q.016-.152.072-.28.056-.128.143-.22.088-.095.204-.147.116-.056.252-.056.156 0 .272.06.12.056.2.151.083.096.127.224.048.128.06.268z"
          ></path>
          <path
            fill="#000"
            d="M78.515 66.594q0-.232.064-.408.064-.18.176-.3.112-.12.26-.18.152-.06.32-.06h.023v-.331q-.147-.004-.275.036-.128.04-.236.12-.108.076-.192.184-.084.107-.14.235v-.54h-.307v2.067h.307z"
          ></path>
          <path
            d="M28.874 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.408-.088-.215 0-.4.084-.179.084-.31.232-.133.144-.209.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.368.08-.135 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.251-.056.156 0 .272.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M32.695 70.855q-.084-.176-.248-.292-.164-.12-.416-.12-.132 0-.232.032t-.18.088q-.08.052-.143.124-.06.072-.112.148v-.348h-.308v2.066h.308v-1.178q0-.144.044-.26.044-.12.12-.208.08-.088.183-.136.104-.048.228-.048.248 0 .388.164.144.16.144.452v1.214h.303v-1.19q0-.152.044-.272.048-.12.124-.2.08-.084.184-.124.104-.044.22-.044.252 0 .392.164.143.16.143.46v1.206h.308v-1.278q0-.388-.204-.608-.204-.224-.563-.224-.136 0-.248.032-.108.032-.196.088-.088.056-.16.132-.068.076-.123.16z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M36.943 71.518q0-.255-.084-.455t-.224-.336q-.14-.14-.32-.212-.176-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.176v-.416h-.308v2.706h.308v-1.035q.06.088.136.168.08.08.176.14.096.06.216.095.12.036.263.036.188 0 .364-.071.18-.076.316-.212.14-.14.224-.34.084-.2.084-.456zm-.316.004q0 .188-.06.34-.056.148-.156.252-.096.1-.232.156-.132.052-.28.052-.143 0-.28-.056-.131-.06-.239-.164-.104-.104-.168-.252-.06-.152-.06-.332 0-.18.06-.327.064-.148.168-.252.108-.104.24-.16.136-.06.28-.06.147 0 .28.056.131.056.231.164.1.104.156.252.06.148.06.331z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M37.846 72.553v-2.917h-.308v2.917z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M40.587 71.518q0-.22-.08-.415-.08-.196-.224-.34-.144-.148-.343-.232-.196-.088-.428-.088-.236 0-.436.088-.195.084-.34.232-.143.148-.223.348-.08.196-.08.415 0 .22.08.416.08.196.224.344.144.144.34.232.195.083.427.083.232 0 .432-.083.2-.088.343-.236.144-.148.224-.344.084-.2.084-.42zm-.316.008q0 .168-.056.316-.056.144-.155.252-.1.108-.24.172-.14.06-.308.06-.164 0-.304-.064-.14-.064-.243-.172-.1-.108-.16-.256-.056-.148-.056-.316 0-.167.056-.311.056-.148.152-.256.1-.112.24-.172.14-.064.307-.064.164 0 .304.064.14.064.244.176.103.108.16.256.06.148.06.315z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M42.897 70.487h-.328l-.663 1.723-.751-1.723h-.34l.939 2.058q-.092.22-.188.304-.096.084-.236.084-.1 0-.175-.02-.072-.016-.148-.056l-.104.244q.104.052.208.076.103.028.231.028.232 0 .392-.132.164-.132.296-.452z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M43.448 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.131.144-.207.344-.072.196-.072.42 0 .24.08.439.084.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.156 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M45.814 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.408-.088-.215 0-.4.084-.179.084-.31.232-.133.144-.209.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.368.08-.135 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.155 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M51.281 71.518q0-.255-.084-.455t-.224-.336q-.14-.14-.32-.212-.175-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.175.144-.076.084-.14.176v-1.267h-.308v2.917h.308v-.395q.06.088.136.168.08.08.175.14.096.06.216.095.12.036.264.036.188 0 .364-.071.18-.076.315-.212.14-.14.224-.34.084-.2.084-.456zm-.316.004q0 .188-.06.34-.056.148-.156.252-.095.1-.231.156-.132.052-.28.052-.144 0-.28-.056-.132-.06-.24-.164-.103-.104-.167-.252-.06-.152-.06-.332 0-.18.06-.327.064-.148.167-.252.108-.104.24-.16.136-.06.28-.06.148 0 .28.056.132.056.231.164.1.104.156.252.06.148.06.331z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M53.623 70.487h-.328l-.663 1.723-.752-1.723h-.34l.94 2.058q-.092.22-.188.304-.096.084-.236.084-.1 0-.176-.02-.072-.016-.147-.056l-.104.244q.104.052.207.076.104.028.232.028.232 0 .392-.132.164-.132.296-.452z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M55.617 71.73q0-.232.064-.407.064-.18.176-.3.112-.12.26-.18.151-.06.32-.06h.023v-.332q-.148-.004-.276.036-.128.04-.235.12-.108.076-.192.184t-.14.236v-.54h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M57.024 71.634h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.063-.132-.08-.3zm0-.227q.016-.152.071-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.083.096.127.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M61.172 72.553v-2.917h-.308v1.247q-.06-.088-.14-.168-.076-.08-.172-.14-.096-.06-.216-.096-.12-.036-.263-.036-.188 0-.364.076-.176.072-.316.212-.14.136-.223.336-.084.2-.084.455 0 .256.084.456.083.2.223.34.14.136.316.207.176.072.364.072.144 0 .26-.036.12-.036.215-.1.096-.063.172-.147.08-.084.144-.176v.415zm-.296-1.035q0 .18-.064.332-.06.148-.168.252-.104.104-.24.164-.131.056-.275.056-.148 0-.28-.056-.132-.056-.232-.16-.1-.108-.16-.256-.056-.148-.056-.332 0-.187.056-.335.06-.152.156-.252.1-.104.232-.156.132-.056.284-.056.144 0 .275.06.136.056.24.16.108.104.168.252.064.148.064.327z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M63.326 71.67q0 .144-.048.264-.048.12-.132.208-.08.084-.196.132-.112.044-.244.044-.276 0-.423-.168-.148-.168-.148-.456v-1.207h-.308v1.283q0 .184.052.336.056.152.156.264.104.108.248.167.147.06.335.06.268 0 .436-.115.168-.12.272-.288v.36h.303v-2.067h-.303z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M66.095 72.202l-.196-.184q-.124.132-.28.22-.151.088-.351.088-.164 0-.304-.06-.136-.064-.24-.172-.1-.112-.16-.26-.056-.148-.056-.316 0-.167.056-.311.056-.148.156-.256.1-.112.236-.172.136-.064.292-.064.212 0 .355.092.148.092.272.22l.204-.216q-.072-.076-.156-.144-.084-.068-.184-.116-.1-.052-.22-.08-.12-.028-.267-.028-.228 0-.424.088-.192.084-.335.232-.14.148-.22.348-.08.196-.08.415 0 .22.08.416.08.196.22.344.143.144.335.232.196.083.424.083.292 0 .487-.111.2-.112.356-.288z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M66.962 70.036v-.34h-.351v.34zm-.024 2.517v-2.066h-.308v2.066z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M67.961 71.37q0-.143.044-.263.048-.12.132-.204.084-.088.196-.132.116-.048.248-.048.275 0 .423.168.148.168.148.456v1.206h.308v-1.282q0-.184-.056-.336-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.331-.06-.268 0-.436.12-.168.116-.272.284v-.36h-.307v2.066h.307z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M72.081 72.194v-1.707h-.307v.372q-.064-.084-.144-.16-.08-.076-.18-.132-.096-.056-.22-.088-.12-.036-.264-.036-.183 0-.36.068-.175.068-.311.196-.136.124-.22.308-.08.18-.08.408 0 .227.08.407.084.18.22.308.136.124.308.192.176.064.363.064.284 0 .48-.128t.332-.308v.244q0 .363-.204.547-.204.188-.548.188-.223 0-.42-.068-.195-.068-.375-.196l-.14.24q.208.144.444.216.236.072.495.072.24 0 .432-.064.196-.064.332-.192.14-.124.211-.312.076-.187.076-.44zm-.295-.775q0 .16-.064.291-.064.128-.172.22-.104.092-.24.144-.136.048-.284.048-.144 0-.275-.048-.128-.052-.228-.144-.096-.096-.156-.224-.056-.132-.056-.291 0-.16.056-.288.056-.132.152-.224.1-.092.228-.14.131-.048.28-.048.147 0 .283.052.136.048.24.14.108.092.172.224.064.128.064.288z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M74.383 70.76h.656v-.273h-.656v-.623h-.308v.623h-.287v.272h.287v1.247q0 .156.044.268.044.112.12.184.08.068.188.1.108.031.232.031.112 0 .2-.024.092-.02.172-.063v-.264q-.08.04-.152.056-.068.016-.148.016-.156 0-.252-.076t-.096-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M75.942 70.036v-.34h-.352v.34zm-.024 2.517v-2.066h-.308v2.066z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M78.272 70.855q-.084-.176-.248-.292-.164-.12-.416-.12-.132 0-.232.032t-.18.088q-.08.052-.143.124-.06.072-.112.148v-.348h-.308v2.066h.308v-1.178q0-.144.044-.26.044-.12.12-.208.08-.088.183-.136.104-.048.228-.048.248 0 .388.164.144.16.144.452v1.214h.303v-1.19q0-.152.044-.272.048-.12.124-.2.08-.084.184-.124.104-.044.22-.044.252 0 .392.164.143.16.143.46v1.206h.308v-1.278q0-.388-.204-.608-.204-.224-.563-.224-.136 0-.248.032-.108.032-.196.088-.088.056-.16.132-.068.076-.123.16z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M80.617 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.132.144-.207.344-.072.196-.072.42 0 .24.08.439.083.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.156 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M30.109 77.11v-.007q0-.14-.06-.236-.056-.096-.152-.164t-.216-.112q-.116-.044-.235-.08-.104-.032-.2-.064-.096-.032-.172-.072-.072-.04-.116-.092-.044-.056-.044-.128v-.008q0-.127.108-.211.108-.088.292-.088.152 0 .307.056.16.052.304.144l.14-.232q-.16-.104-.356-.164-.195-.064-.387-.064-.152 0-.284.044-.128.04-.224.12-.092.076-.144.188-.052.108-.052.24v.007q0 .144.06.24.064.096.16.16.1.064.22.108.124.04.244.076.1.028.192.06.095.032.167.076.072.04.112.096.044.056.044.132v.008q0 .151-.12.24-.12.087-.307.087-.184 0-.368-.068-.18-.068-.348-.196l-.156.22q.176.14.404.224.228.08.452.08.155 0 .287-.04.136-.044.232-.124.1-.08.156-.192.06-.116.06-.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M32.766 76.655q0-.256-.083-.456-.084-.2-.224-.335-.14-.14-.32-.212-.176-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.176v-.416h-.308v2.705h.308v-1.035q.06.088.136.168.08.08.176.14.096.06.216.096.12.036.263.036.188 0 .364-.072.18-.076.316-.212.14-.14.224-.34.083-.2.083-.455zm-.315.004q0 .188-.06.34-.056.148-.156.251-.096.1-.232.156-.132.052-.28.052-.143 0-.28-.056-.131-.06-.24-.164-.103-.103-.167-.251-.06-.152-.06-.332 0-.18.06-.328.064-.148.168-.251.108-.104.24-.16.136-.06.28-.06.147 0 .28.056.131.056.23.164.1.103.157.251.06.148.06.332z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M33.518 76.77h1.638q.004-.027.004-.051v-.044q0-.228-.064-.428-.064-.2-.188-.347-.123-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.343-.072.196-.072.42 0 .24.08.44.084.2.224.343.14.14.327.22.188.076.4.076.296 0 .496-.104.2-.108.355-.284l-.192-.171q-.127.131-.283.211-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.163-.22-.064-.131-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.087-.095.203-.147.116-.056.252-.056.156 0 .272.06.12.056.2.152.084.095.128.223.047.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M36.007 76.507q0-.144.044-.264.048-.12.132-.203.084-.088.196-.132.116-.048.248-.048.276 0 .423.168.148.167.148.455v1.207h.308v-1.283q0-.184-.056-.335-.052-.152-.156-.26-.1-.112-.248-.172-.143-.06-.331-.06-.268 0-.436.12-.168.116-.272.284v-.36H35.7v2.066h.307z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M38.577 75.896h.655v-.272h-.655v-.623h-.308v.623h-.287v.272h.287v1.247q0 .155.044.267.044.112.12.184.08.068.188.1t.232.032q.112 0 .2-.024.091-.02.171-.064v-.264q-.08.04-.152.056-.067.016-.147.016-.156 0-.252-.076t-.096-.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M43 76.655q0-.22-.08-.416-.08-.195-.223-.34-.144-.147-.344-.231-.195-.088-.427-.088-.236 0-.436.088-.196.084-.34.232-.143.148-.223.347-.08.196-.08.416 0 .22.08.416.08.195.224.343.143.144.34.232.195.084.427.084.232 0 .431-.084.2-.088.344-.236.144-.148.224-.343.084-.2.084-.42zm-.315.008q0 .168-.056.316-.056.144-.156.251-.1.108-.24.172-.14.06-.307.06-.164 0-.304-.064-.14-.064-.244-.172-.1-.107-.16-.255-.055-.148-.055-.316t.055-.312q.056-.148.152-.255.1-.112.24-.172.14-.064.308-.064.164 0 .304.064.14.064.243.176.104.107.16.255.06.148.06.316z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M43.864 76.507q0-.144.044-.264.048-.12.132-.203.084-.088.196-.132.116-.048.247-.048.276 0 .424.168.148.167.148.455v1.207h.308v-1.283q0-.184-.056-.335-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.332-.06-.267 0-.435.12-.168.116-.272.284v-.36h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M47.632 75.896h.656v-.272h-.656v-.623h-.307v.623h-.288v.272h.288v1.247q0 .155.044.267.044.112.12.184.08.068.187.1.108.032.232.032.112 0 .2-.024.092-.02.172-.064v-.264q-.08.04-.152.056-.068.016-.148.016-.156 0-.252-.076t-.096-.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M49.139 76.507q0-.144.044-.264.048-.12.132-.203.084-.088.196-.132.116-.048.247-.048.276 0 .424.168.148.167.148.455v1.207h.308v-1.283q0-.184-.056-.335-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.332-.06-.267 0-.435.12-.168.116-.272.284v-1.211h-.308v2.917h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M51.477 76.77h1.638q.004-.027.004-.051v-.044q0-.228-.064-.428-.064-.2-.188-.347-.123-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.343-.072.196-.072.42 0 .24.08.44.084.2.224.343.14.14.327.22.188.076.4.076.296 0 .496-.104.2-.108.355-.284l-.192-.171q-.127.131-.283.211-.152.08-.368.08-.136 0-.26-.044-.124-.048-.223-.136-.1-.092-.164-.22-.064-.131-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.087-.095.203-.147.116-.056.252-.056.156 0 .272.06.12.056.2.152.084.095.128.223.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M56.944 76.655q0-.256-.084-.456t-.224-.335q-.14-.14-.32-.212-.176-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.176v-.416h-.307v2.705h.307v-1.035q.06.088.136.168.08.08.176.14.096.06.216.096.12.036.264.036.187 0 .363-.072.18-.076.316-.212.14-.14.224-.34.084-.2.084-.455zm-.316.004q0 .188-.06.34-.056.148-.156.251-.096.1-.232.156-.132.052-.28.052-.143 0-.28-.056-.131-.06-.239-.164-.104-.103-.168-.251-.06-.152-.06-.332 0-.18.06-.328.064-.148.168-.251.108-.104.24-.16.136-.06.28-.06.147 0 .28.056.131.056.231.164.1.103.156.251.06.148.06.332z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M57.819 76.507q0-.144.044-.264.048-.12.132-.203.084-.088.195-.132.116-.048.248-.048.276 0 .424.168.148.167.148.455v1.207h.307v-1.283q0-.184-.056-.335-.052-.152-.155-.26-.1-.112-.248-.172-.144-.06-.332-.06-.268 0-.435.12-.168.116-.272.284v-1.211h-.308v2.917h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M61.999 76.655q0-.22-.08-.416-.08-.195-.224-.34-.144-.147-.344-.231-.195-.088-.427-.088-.236 0-.436.088-.196.084-.34.232-.143.148-.223.347-.08.196-.08.416 0 .22.08.416.08.195.224.343.143.144.34.232.195.084.427.084.232 0 .431-.084.2-.088.344-.236.144-.148.224-.343.084-.2.084-.42zm-.316.008q0 .168-.056.316-.056.144-.156.251-.1.108-.24.172-.14.06-.307.06-.164 0-.304-.064-.14-.064-.244-.172-.1-.107-.16-.255-.055-.148-.055-.316t.056-.312q.055-.148.151-.255.1-.112.24-.172.14-.064.308-.064.164 0 .304.064.14.064.243.176.104.107.16.255.06.148.06.316z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M62.862 76.507q0-.144.044-.264.048-.12.132-.203.084-.088.196-.132.116-.048.247-.048.276 0 .424.168.148.167.148.455v1.207h.308v-1.283q0-.184-.056-.335-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.332-.06-.267 0-.435.12-.168.116-.272.284v-.36h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M65.2 76.77h1.638q.004-.027.004-.051v-.044q0-.228-.064-.428-.064-.2-.187-.347-.124-.148-.304-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.343-.072.196-.072.42 0 .24.08.44.084.2.224.343.14.14.328.22.187.076.4.076.295 0 .495-.104.2-.108.355-.284l-.191-.171q-.128.131-.284.211-.152.08-.368.08-.136 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.131-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.095.203-.147.116-.056.252-.056.156 0 .272.06.12.056.2.152.084.095.128.223.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M67.673 77.69v-.42h-.367v.42z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
        </g>
        <g
          ariaLabel="See a 37% improvement in medication adherence."
          style={{ lineHeight: "1.25" }}
          fill="#000"
          strokeWidth="0.249"
          fontSize="3.424"
          transform="translate(0 -35)"
        >
          <path d="M45.024 99.762q0-.082.034-.154.038-.072.103-.123.069-.051.161-.082.096-.031.212-.031.185 0 .353.062.171.061.339.198l.157-.209q-.185-.147-.383-.219-.195-.075-.459-.075-.171 0-.315.051-.144.048-.25.137-.106.086-.164.206-.058.12-.058.263 0 .15.048.26.048.11.147.192.1.082.25.14.154.059.36.103.188.041.307.086.124.044.192.1.069.054.096.122.027.065.027.15 0 .186-.147.299-.144.113-.387.113-.25 0-.441-.082-.192-.086-.38-.257l-.168.198q.216.196.452.291.24.093.527.093.178 0 .329-.048.15-.048.256-.137.11-.09.172-.216.061-.126.061-.28 0-.278-.191-.435-.189-.158-.59-.247-.198-.04-.325-.085-.126-.048-.198-.103-.072-.058-.1-.127-.027-.068-.027-.154z"></path>
          <path d="M47.12 100.772h1.403q.003-.024.003-.044v-.038q0-.195-.054-.366-.055-.172-.161-.298-.106-.127-.26-.199-.154-.075-.35-.075-.185 0-.342.072-.154.072-.267.198-.113.124-.178.295-.062.168-.062.36 0 .205.069.376.072.171.192.294.12.12.28.189.161.065.343.065.253 0 .424-.09.171-.092.305-.243l-.165-.147q-.11.113-.243.182-.13.068-.315.068-.116 0-.222-.037-.106-.041-.192-.117-.085-.078-.14-.188-.055-.113-.069-.257zm0-.195q.013-.13.061-.24.048-.11.123-.188.076-.082.175-.127.1-.048.216-.048.133 0 .232.052.103.048.172.13.071.082.11.192.04.11.05.229z"></path>
          <path d="M49.146 100.772h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.054-.172-.16-.298-.107-.127-.26-.199-.155-.075-.35-.075-.185 0-.342.072-.154.072-.267.198-.113.124-.178.295-.062.168-.062.36 0 .205.069.376.071.171.191.294.12.12.281.189.16.065.342.065.254 0 .425-.09.171-.092.305-.243l-.165-.147q-.11.113-.243.182-.13.068-.315.068-.116 0-.222-.037-.106-.041-.192-.117-.086-.078-.14-.188-.055-.113-.069-.257zm0-.195q.014-.13.062-.24.048-.11.123-.188.075-.082.175-.127.099-.048.215-.048.134 0 .233.052.103.048.171.13.072.082.11.192.04.11.051.229z"></path>
          <path d="M52.683 99.998q.25 0 .386.124.14.12.14.36v.06q-.119-.033-.246-.054-.126-.02-.298-.02-.167 0-.308.037-.137.038-.24.11-.099.072-.153.181-.055.106-.055.247 0 .14.055.243.058.102.15.174.096.069.213.103.12.034.243.034.236 0 .39-.092.157-.093.25-.213v.268h.253v-1.082q0-.353-.195-.531-.195-.181-.558-.181-.195 0-.346.04-.15.038-.298.107l.08.215q.122-.058.249-.092.13-.038.288-.038zm.01.665q.164 0 .29.024.131.024.23.05v.172q0 .106-.048.195-.044.086-.126.15-.08.062-.189.1-.106.034-.229.034-.089 0-.171-.024-.079-.024-.14-.068-.062-.048-.1-.113-.034-.065-.034-.15 0-.172.133-.271.134-.1.384-.1z"></path>
          <path
            stroke="#000"
            d="M56.226 99.406l-.757.853.055.16h.154q.298 0 .483.12.188.12.188.346 0 .106-.04.195-.042.086-.114.148-.068.061-.164.095-.096.031-.206.031-.222 0-.393-.092-.168-.093-.308-.264l-.196.175q.148.192.37.311.223.117.524.117.168 0 .312-.052.147-.054.253-.15.11-.096.171-.23.065-.133.065-.294 0-.164-.065-.288-.065-.123-.175-.205-.106-.082-.25-.127-.143-.044-.3-.058l.752-.835v-.199h-1.53v.243z"
          ></path>
          <path
            stroke="#000"
            d="M57.007 99.41h1.318l-1.082 2.15h.301l1.089-2.195v-.202h-1.626z"
          ></path>
          <path
            stroke="#000"
            d="M60.077 99.745q0-.123-.04-.233-.038-.11-.11-.191-.069-.086-.168-.134-.1-.051-.22-.051-.122 0-.225.051-.1.048-.171.134-.072.085-.113.198-.038.11-.038.233 0 .123.038.233.04.11.11.195.071.082.17.133.1.048.223.048t.223-.048q.099-.051.17-.137.073-.085.11-.195.041-.113.041-.236zm1.164-.582h-.243l-1.76 2.397h.247zm.247 1.808q0-.124-.038-.233-.037-.11-.11-.192-.068-.085-.167-.133-.1-.052-.223-.052t-.222.052q-.1.048-.171.133-.072.086-.113.199-.038.11-.038.233 0 .123.038.232.04.11.11.196.071.082.17.133.1.048.22.048.123 0 .222-.048.103-.051.171-.137.072-.085.11-.195.04-.113.04-.236zm-1.637-1.219q0 .089-.023.164-.021.076-.062.134-.041.055-.1.089-.058.03-.126.03-.069 0-.127-.03-.055-.034-.099-.09-.045-.057-.068-.133-.024-.078-.024-.17 0-.086.02-.162.024-.079.065-.133.041-.059.096-.09.058-.034.13-.034.065 0 .123.035.059.03.1.089.044.054.068.133.027.076.027.168zm1.411 1.226q0 .089-.024.164-.02.075-.062.133-.04.055-.099.09-.055.03-.127.03-.068 0-.126-.03-.055-.035-.1-.09-.044-.058-.068-.133-.024-.079-.024-.171 0-.086.02-.161.025-.079.066-.134.04-.058.099-.089.058-.034.127-.034.065 0 .123.034.058.031.103.09.044.054.068.133.024.075.024.168z"
          ></path>
          <path
            stroke="#000"
            d="M63.299 99.403v-.291h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
          ></path>
          <path
            stroke="#000"
            d="M65.295 100.105q-.072-.151-.212-.25-.14-.103-.356-.103-.113 0-.199.027-.086.028-.154.076-.069.044-.123.106-.052.061-.096.126v-.297h-.264v1.77h.264v-1.01q0-.124.037-.223.038-.103.103-.178.069-.075.158-.116.089-.041.195-.041.212 0 .332.14.123.137.123.387v1.04h.26v-1.02q0-.13.038-.232.041-.103.106-.172.069-.072.158-.106.089-.037.188-.037.216 0 .335.14.124.137.124.394v1.034h.263v-1.096q0-.332-.174-.52-.175-.192-.483-.192-.116 0-.212.027-.093.028-.168.076-.075.048-.137.113-.058.065-.106.137z"
          ></path>
          <path
            stroke="#000"
            d="M68.934 100.673q0-.22-.072-.39-.072-.172-.192-.288-.12-.12-.273-.181-.151-.062-.309-.062-.123 0-.225.034-.1.031-.182.086-.082.051-.15.123-.066.072-.12.15v-.355h-.264v2.317h.264v-.886q.051.075.116.143.068.069.15.12.083.052.186.082.102.031.225.031.161 0 .312-.061.154-.065.27-.182.12-.12.192-.29.072-.172.072-.391zm-.27.003q0 .16-.052.29-.048.128-.133.217-.082.085-.199.133-.113.045-.24.045-.123 0-.24-.048-.112-.052-.205-.14-.089-.09-.143-.216-.052-.13-.052-.284 0-.155.052-.281.054-.127.143-.216.093-.089.206-.137.116-.051.24-.051.126 0 .24.048.112.048.198.14.085.09.133.216.052.126.052.284z"
          ></path>
          <path
            stroke="#000"
            d="M69.684 100.854q0-.198.055-.349.054-.154.15-.257.096-.102.223-.154.13-.051.274-.051h.02v-.284q-.126-.004-.236.03-.11.035-.202.103-.092.065-.164.158-.072.092-.12.202v-.462h-.264v1.77h.264z"
          ></path>
          <path
            stroke="#000"
            d="M72.467 100.673q0-.188-.068-.356-.069-.168-.192-.291-.123-.127-.294-.199-.168-.075-.367-.075-.202 0-.373.075-.168.072-.29.199-.124.127-.193.298-.068.167-.068.356 0 .188.068.356.069.168.192.294.123.123.291.199.168.072.366.072.199 0 .37-.072.171-.076.295-.202.123-.127.191-.295.072-.17.072-.36zm-.27.007q0 .144-.048.27-.048.124-.134.216-.085.092-.205.147-.12.052-.264.052-.14 0-.26-.055t-.209-.147q-.085-.093-.137-.22-.048-.126-.048-.27 0-.144.048-.267.048-.127.13-.22.086-.095.206-.146.12-.055.263-.055.14 0 .26.055t.21.15q.088.093.136.22.052.126.052.27z"
          ></path>
          <path
            stroke="#000"
            d="M73.552 101.258l-.595-1.468h-.291l.767 1.783h.232l.77-1.783h-.283z"
          ></path>
          <path
            stroke="#000"
            d="M74.905 100.772h1.403q.004-.024.004-.044v-.038q0-.195-.055-.366-.055-.172-.161-.298-.106-.127-.26-.199-.154-.075-.35-.075-.184 0-.342.072-.154.072-.267.198-.113.124-.178.295-.061.168-.061.36 0 .205.068.376.072.171.192.294.12.12.28.189.161.065.343.065.253 0 .424-.09.172-.092.305-.243l-.164-.147q-.11.113-.243.182-.13.068-.315.068-.117 0-.223-.037-.106-.041-.192-.117-.085-.078-.14-.188-.055-.113-.068-.257zm0-.195q.013-.13.061-.24.048-.11.124-.188.075-.082.174-.127.1-.048.216-.048.133 0 .233.052.102.048.17.13.073.082.11.192.041.11.052.229z"
          ></path>
          <path
            stroke="#000"
            d="M78.178 100.105q-.072-.151-.213-.25-.14-.103-.356-.103-.113 0-.198.027-.086.028-.154.076-.069.044-.124.106-.051.061-.095.126v-.297h-.264v1.77h.264v-1.01q0-.124.037-.223.038-.103.103-.178.068-.075.157-.116.09-.041.196-.041.212 0 .332.14.123.137.123.387v1.04h.26v-1.02q0-.13.038-.232.04-.103.106-.172.068-.072.157-.106.09-.037.189-.037.215 0 .335.14.123.137.123.394v1.034h.264v-1.096q0-.332-.175-.52-.174-.192-.482-.192-.117 0-.213.027-.092.028-.167.076-.076.048-.137.113-.058.065-.106.137z"
          ></path>
          <path
            stroke="#000"
            d="M80.187 100.772h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.054-.172-.16-.298-.107-.127-.26-.199-.155-.075-.35-.075-.185 0-.342.072-.154.072-.267.198-.113.124-.178.295-.062.168-.062.36 0 .205.069.376.071.171.191.294.12.12.281.189.16.065.342.065.254 0 .425-.09.171-.092.305-.243l-.165-.147q-.11.113-.243.182-.13.068-.315.068-.116 0-.222-.037-.106-.041-.192-.117-.086-.078-.14-.188-.055-.113-.069-.257zm0-.195q.014-.13.062-.24.048-.11.123-.188.075-.082.175-.127.099-.048.215-.048.134 0 .233.052.103.048.171.13.072.082.11.192.04.11.051.229z"
          ></path>
          <path
            stroke="#000"
            d="M82.32 100.546q0-.123.038-.226.04-.102.113-.174.072-.076.167-.113.1-.041.213-.041.236 0 .363.143.126.144.126.39v1.035h.264v-1.1q0-.157-.048-.287-.045-.13-.134-.222-.085-.096-.212-.148-.123-.051-.284-.051-.23 0-.373.103-.144.099-.233.243v-.308h-.264v1.77h.264z"
          ></path>
          <path
            stroke="#000"
            d="M84.521 100.022h.562v-.232h-.562v-.534h-.263v.534h-.247v.232h.247v1.069q0 .133.037.229.038.096.103.157.069.059.161.086.092.027.199.027.095 0 .17-.02.08-.017.148-.055v-.226q-.069.034-.13.048-.058.014-.127.014-.133 0-.215-.065-.083-.065-.083-.23z"
          ></path>
          <path
            d="M45.079 103.803v-.29h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M45.935 104.947q0-.124.038-.226.04-.103.112-.175.072-.075.168-.113.1-.041.213-.041.236 0 .362.144.127.144.127.39v1.034h.264v-1.099q0-.157-.048-.288-.045-.13-.134-.222-.085-.096-.212-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-.308h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M50.21 104.505q-.071-.15-.211-.25-.14-.103-.356-.103-.113 0-.199.028-.086.027-.154.075-.069.045-.123.106-.052.062-.096.127v-.298h-.264v1.77h.264v-1.01q0-.123.037-.222.038-.103.103-.178.069-.076.158-.117.089-.041.195-.041.212 0 .332.14.123.137.123.387v1.041h.26v-1.02q0-.13.038-.233.041-.103.106-.171.069-.072.158-.106.089-.038.188-.038.216 0 .335.14.124.137.124.394v1.034h.263v-1.096q0-.332-.174-.52-.175-.192-.483-.192-.116 0-.212.028-.093.027-.168.075-.075.048-.137.113-.058.065-.106.137z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M52.22 105.173h1.404q.003-.024.003-.045v-.038q0-.195-.054-.366-.055-.171-.161-.298-.106-.126-.26-.198-.154-.076-.35-.076-.184 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.072.171.192.295.12.12.28.188.161.065.343.065.253 0 .424-.089.171-.092.305-.243l-.164-.147q-.11.113-.244.181-.13.069-.315.069-.116 0-.222-.038-.106-.041-.192-.116-.085-.079-.14-.189-.055-.113-.069-.256zm0-.196q.014-.13.062-.24.048-.109.123-.187.076-.083.175-.127.1-.048.216-.048.133 0 .232.051.103.048.172.13.071.083.11.192.04.11.05.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M55.774 105.96v-2.5h-.264v1.069q-.05-.075-.12-.144-.064-.068-.147-.12-.082-.051-.184-.082-.103-.03-.226-.03-.161 0-.312.064-.15.062-.27.182-.12.116-.192.287-.072.172-.072.39 0 .22.072.39.072.172.192.292.12.116.27.178.15.062.312.062.123 0 .222-.031.103-.031.185-.086.082-.055.147-.127.069-.071.123-.15v.356zm-.253-.887q0 .154-.055.284-.051.127-.144.216-.089.09-.205.14-.113.048-.236.048-.127 0-.24-.048-.113-.047-.199-.136-.085-.093-.137-.22-.048-.126-.048-.284 0-.16.048-.287.052-.13.134-.216.085-.089.198-.133.113-.048.244-.048.123 0 .236.05.116.049.205.138.093.089.144.216.055.126.055.28z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M56.671 103.803v-.29h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M58.783 105.659l-.167-.158q-.107.113-.24.189-.13.075-.301.075-.14 0-.26-.052-.117-.054-.206-.147-.086-.096-.137-.222-.048-.127-.048-.27 0-.145.048-.268.048-.126.134-.219.085-.096.202-.147.116-.055.25-.055.181 0 .304.079.127.078.233.188l.175-.185q-.062-.065-.134-.123-.072-.058-.157-.1-.086-.044-.189-.068-.102-.024-.229-.024-.195 0-.363.075-.164.072-.288.199-.12.127-.188.298-.068.168-.068.356 0 .188.068.356.069.168.188.294.124.124.288.199.168.072.363.072.25 0 .418-.096.17-.096.304-.247z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M59.858 104.399q.25 0 .387.123.14.12.14.36v.061q-.12-.034-.246-.055-.127-.02-.298-.02-.168 0-.308.037-.137.038-.24.11-.099.072-.154.181-.054.107-.054.247t.054.243q.059.103.151.175.096.068.212.102.12.035.243.035.237 0 .39-.093.158-.092.25-.212v.267h.254v-1.082q0-.352-.195-.53-.195-.182-.558-.182-.195 0-.346.041-.15.038-.298.106l.079.216q.123-.058.25-.092.13-.038.287-.038zm.01.664q.165 0 .292.024.13.024.229.051v.172q0 .106-.048.195-.044.085-.127.15-.078.062-.188.1-.106.034-.23.034-.088 0-.17-.024-.08-.024-.14-.069-.062-.048-.1-.113-.034-.065-.034-.15 0-.172.133-.27.134-.1.384-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M61.54 104.423h.56v-.233h-.56v-.534h-.264v.534h-.247v.233h.247v1.068q0 .133.037.23.038.095.103.157.068.058.16.085.093.028.2.028.095 0 .17-.02.08-.018.148-.055v-.226q-.069.034-.13.047-.058.014-.127.014-.133 0-.216-.065-.082-.065-.082-.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M62.874 103.803v-.29h-.3v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M65.202 105.073q0-.188-.068-.356-.069-.167-.192-.29-.123-.127-.294-.2-.168-.075-.366-.075-.202 0-.374.076-.167.072-.29.198-.124.127-.192.298-.069.168-.069.356 0 .188.069.356.068.168.191.295.124.123.291.198.168.072.367.072.198 0 .37-.072.17-.075.294-.202.123-.126.192-.294.071-.171.071-.36zm-.27.007q0 .144-.048.27-.048.124-.133.216-.086.093-.206.148-.12.051-.263.051-.14 0-.26-.055t-.21-.147q-.085-.092-.136-.22-.048-.126-.048-.27 0-.143.048-.267.047-.126.13-.219.085-.096.205-.147.12-.055.264-.055.14 0 .26.055t.209.15q.089.093.137.22.051.127.051.27z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M65.942 104.947q0-.124.038-.226.04-.103.113-.175.071-.075.167-.113.1-.041.213-.041.236 0 .362.144.127.144.127.39v1.034h.264v-1.099q0-.157-.048-.288-.045-.13-.134-.222-.085-.096-.212-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-.308h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M69.454 104.399q.25 0 .387.123.14.12.14.36v.061q-.12-.034-.246-.055-.127-.02-.298-.02-.167 0-.308.037-.137.038-.24.11-.099.072-.154.181-.054.107-.054.247t.054.243q.059.103.151.175.096.068.212.102.12.035.243.035.237 0 .39-.093.158-.092.25-.212v.267h.254v-1.082q0-.352-.195-.53-.195-.182-.558-.182-.195 0-.346.041-.15.038-.298.106l.079.216q.123-.058.25-.092.13-.038.287-.038zm.01.664q.165 0 .292.024.13.024.23.051v.172q0 .106-.049.195-.044.085-.127.15-.078.062-.188.1-.106.034-.23.034-.088 0-.17-.024-.08-.024-.14-.069-.062-.048-.1-.113-.034-.065-.034-.15 0-.172.133-.27.134-.1.384-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M72.48 105.96v-2.5h-.263v1.069q-.051-.075-.12-.144-.065-.068-.147-.12-.082-.051-.185-.082-.102-.03-.226-.03-.16 0-.311.064-.15.062-.27.182-.12.116-.192.287-.072.172-.072.39 0 .22.072.39.072.172.191.292.12.116.27.178.151.062.312.062.124 0 .223-.031.103-.031.185-.086.082-.055.147-.127.068-.071.123-.15v.356zm-.253-.887q0 .154-.054.284-.052.127-.144.216-.09.09-.205.14-.113.048-.237.048-.126 0-.24-.048-.112-.047-.198-.136-.086-.093-.137-.22-.048-.126-.048-.284 0-.16.048-.287.051-.13.134-.216.085-.089.198-.133.113-.048.243-.048.124 0 .237.05.116.049.205.138.092.089.144.216.054.126.054.28z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M73.333 104.947q0-.124.038-.226.041-.103.113-.175.072-.075.168-.113.099-.041.212-.041.236 0 .363.144.126.144.126.39v1.034h.264v-1.099q0-.157-.048-.288-.044-.13-.133-.222-.086-.096-.213-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-1.037h-.263v2.499h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M75.336 105.173h1.404q.003-.024.003-.045v-.038q0-.195-.055-.366-.054-.171-.16-.298-.107-.126-.26-.198-.155-.076-.35-.076-.185 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.068.376.072.171.192.295.12.12.28.188.162.065.343.065.254 0 .425-.089.17-.092.304-.243l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.107-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.196q.014-.13.062-.24.048-.109.123-.187.075-.083.175-.127.099-.048.215-.048.134 0 .233.051.103.048.171.13.072.083.11.192.04.11.051.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M77.469 105.255q0-.199.055-.35.054-.153.15-.256.096-.103.223-.154.13-.052.274-.052h.02v-.284q-.126-.003-.236.031-.11.034-.202.103-.092.065-.164.157-.072.093-.12.202v-.462h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M78.674 105.173h1.404q.003-.024.003-.045v-.038q0-.195-.055-.366-.055-.171-.16-.298-.107-.126-.26-.198-.155-.076-.35-.076-.185 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.068.376.072.171.192.295.12.12.28.188.162.065.343.065.254 0 .425-.089.17-.092.304-.243l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.107-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.196q.014-.13.062-.24.048-.109.123-.187.075-.083.174-.127.1-.048.216-.048.134 0 .233.051.103.048.171.13.072.083.11.192.04.11.051.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M80.807 104.947q0-.124.037-.226.042-.103.113-.175.072-.075.168-.113.1-.041.212-.041.237 0 .363.144.127.144.127.39v1.034h.264v-1.099q0-.157-.048-.288-.045-.13-.134-.222-.085-.096-.212-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-.308h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M84.172 105.659l-.168-.158q-.106.113-.24.189-.13.075-.3.075-.14 0-.26-.052-.117-.054-.206-.147-.086-.096-.137-.222-.048-.127-.048-.27 0-.145.048-.268.048-.126.133-.219.086-.096.202-.147.117-.055.25-.055.182 0 .305.079.127.078.233.188l.174-.185q-.061-.065-.133-.123-.072-.058-.158-.1-.085-.044-.188-.068-.103-.024-.23-.024-.194 0-.362.075-.164.072-.288.199-.12.127-.188.298-.068.168-.068.356 0 .188.068.356.068.168.188.294.124.124.288.199.168.072.363.072.25 0 .417-.096.172-.096.305-.247z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M84.713 105.173h1.404q.003-.024.003-.045v-.038q0-.195-.055-.366-.054-.171-.16-.298-.107-.126-.26-.198-.155-.076-.35-.076-.185 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.068.376.072.171.192.295.12.12.28.188.162.065.343.065.254 0 .425-.089.171-.092.304-.243l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.107-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.196q.014-.13.062-.24.048-.109.123-.187.075-.083.175-.127.099-.048.215-.048.134 0 .233.051.103.048.171.13.072.083.11.192.04.11.051.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M86.832 105.96v-.36h-.315v.36z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
        </g>
        <g
          ariaLabel="See a 37% improvement in medication adherence."
          style={{ lineHeight: "1.25" }}
          fill="#000"
          strokeWidth="0.249"
          fontSize="3.424"
          transform="translate(0 -35)"
        >
          <path d="M61.162 125.93q0-.082.034-.154.038-.072.103-.124.069-.05.161-.082.096-.03.212-.03.185 0 .353.061.171.062.339.199l.157-.21q-.185-.146-.383-.218-.195-.076-.459-.076-.171 0-.315.052-.144.048-.25.137-.106.085-.164.205-.058.12-.058.264 0 .15.048.26.048.11.147.192.1.082.25.14.154.058.36.103.187.04.307.085.124.045.192.1.068.054.096.123.027.065.027.15 0 .185-.147.298-.144.113-.387.113-.25 0-.441-.082-.192-.086-.38-.257l-.168.199q.215.195.452.29.24.093.527.093.178 0 .329-.048.15-.048.256-.137.11-.089.172-.215.061-.127.061-.281 0-.277-.192-.435-.188-.157-.588-.246-.199-.041-.326-.086-.126-.048-.198-.103-.072-.058-.1-.126-.027-.069-.027-.154z"></path>
          <path d="M63.257 126.94h1.404q.003-.024.003-.045v-.038q0-.195-.054-.366-.055-.171-.161-.298-.106-.126-.26-.198-.155-.076-.35-.076-.185 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.072.172.191.295.12.12.281.188.161.065.343.065.253 0 .424-.089.171-.092.305-.243l-.165-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.106-.041-.192-.116-.085-.079-.14-.189-.055-.113-.069-.256zm0-.195q.014-.13.062-.24.048-.11.123-.188.075-.083.175-.127.099-.048.215-.048.134 0 .233.051.103.048.171.13.072.083.11.192.041.11.051.23z"></path>
          <path d="M65.284 126.94h1.404q.003-.024.003-.045v-.038q0-.195-.055-.366-.054-.171-.16-.298-.107-.126-.26-.198-.155-.076-.35-.076-.185 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.068.376.072.172.192.295.12.12.28.188.162.065.343.065.254 0 .425-.089.17-.092.304-.243l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.107-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.195q.014-.13.062-.24.048-.11.123-.188.075-.083.174-.127.1-.048.216-.048.134 0 .233.051.103.048.171.13.072.083.11.192.04.11.051.23z"></path>
          <path d="M68.82 126.166q.25 0 .387.123.14.12.14.36v.061q-.12-.034-.246-.055-.126-.02-.298-.02-.167 0-.308.038-.137.037-.24.11-.099.071-.153.18-.055.107-.055.247t.055.243q.058.103.15.175.096.068.212.103.12.034.244.034.236 0 .39-.093.157-.092.25-.212v.267h.253v-1.082q0-.352-.195-.53-.195-.182-.558-.182-.195 0-.346.041-.15.038-.298.106l.079.216q.123-.058.25-.092.13-.038.288-.038zm.01.664q.165 0 .292.024.13.024.23.052v.17q0 .107-.049.196-.044.085-.126.15-.08.062-.189.1-.106.034-.23.034-.088 0-.17-.024-.079-.024-.14-.069-.062-.047-.1-.113-.034-.065-.034-.15 0-.171.133-.27.134-.1.384-.1z"></path>
          <path
            stroke="#000"
            d="M72.364 125.574l-.757.852.055.161h.154q.298 0 .483.12.188.12.188.346 0 .106-.041.195-.041.085-.113.147-.068.062-.164.096-.096.03-.206.03-.222 0-.393-.092-.168-.092-.308-.263l-.196.174q.148.192.37.312.223.116.524.116.168 0 .311-.051.148-.055.254-.15.11-.097.171-.23.065-.134.065-.295 0-.164-.065-.287-.065-.123-.175-.206-.106-.082-.25-.126-.143-.045-.3-.058l.752-.836v-.198h-1.53v.243z"
          ></path>
          <path
            stroke="#000"
            d="M73.144 125.577h1.318l-1.081 2.15h.3l1.09-2.194v-.202h-1.627z"
          ></path>
          <path
            stroke="#000"
            d="M76.215 125.913q0-.124-.04-.233-.038-.11-.11-.192-.069-.085-.168-.133-.1-.052-.22-.052-.122 0-.225.052-.1.047-.171.133-.072.086-.113.199-.038.11-.038.232 0 .124.038.233.04.11.11.195.071.083.17.134.1.048.223.048t.223-.048q.099-.051.17-.137.073-.086.11-.195.041-.113.041-.236zm1.164-.582h-.243l-1.76 2.396h.247zm.247 1.807q0-.123-.038-.233-.037-.11-.11-.191-.068-.086-.167-.134-.1-.051-.223-.051t-.222.051q-.1.048-.171.134-.072.085-.113.198-.038.11-.038.233 0 .123.038.233.04.11.11.195.071.082.17.134.1.047.22.047.123 0 .222-.047.103-.052.171-.137.072-.086.11-.196.04-.113.04-.236zm-1.637-1.219q0 .09-.024.165-.02.075-.061.133-.041.055-.1.09-.058.03-.126.03-.069 0-.127-.03-.055-.035-.1-.09-.044-.058-.068-.133-.024-.079-.024-.171 0-.086.021-.161.024-.079.065-.134.041-.058.096-.089.058-.034.13-.034.065 0 .123.034.058.031.1.09.044.054.068.133.027.075.027.167zm1.41 1.226q0 .09-.023.164-.02.076-.062.134-.04.055-.099.089-.055.03-.127.03-.068 0-.126-.03-.055-.034-.1-.09-.044-.057-.068-.133-.024-.078-.024-.17 0-.086.02-.162.024-.078.066-.133.04-.058.099-.09.058-.033.126-.033.066 0 .124.034.058.03.102.089.045.055.069.133.024.076.024.168z"
          ></path>
          <path
            stroke="#000"
            d="M79.437 125.57v-.29h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
          ></path>
          <path
            stroke="#000"
            d="M81.433 126.272q-.072-.15-.212-.25-.14-.103-.357-.103-.113 0-.198.028-.086.027-.154.075-.069.045-.123.106-.052.062-.096.127v-.298h-.264v1.77h.264v-1.01q0-.123.037-.222.038-.103.103-.178.069-.076.158-.117.089-.04.195-.04.212 0 .332.14.123.136.123.386v1.041h.26v-1.02q0-.13.038-.233.041-.103.106-.171.069-.072.158-.106.089-.038.188-.038.215 0 .335.14.124.137.124.394v1.034h.263v-1.096q0-.332-.174-.52-.175-.192-.483-.192-.117 0-.212.028-.093.027-.168.075-.075.048-.137.113-.058.065-.106.137z"
          ></path>
          <path
            stroke="#000"
            d="M85.072 126.84q0-.219-.072-.39t-.192-.287q-.12-.12-.274-.182-.15-.062-.308-.062-.123 0-.226.035-.099.03-.181.085-.082.052-.15.124-.066.071-.12.15v-.356h-.264v2.318h.264v-.887q.05.075.116.144.068.068.15.12.083.051.185.082.103.03.226.03.161 0 .312-.06.154-.066.27-.182.12-.12.192-.291.072-.171.072-.39zm-.27.003q0 .161-.052.291-.048.127-.133.216-.083.086-.199.134-.113.044-.24.044-.123 0-.24-.048-.112-.051-.205-.14-.089-.09-.143-.216-.052-.13-.052-.284 0-.154.052-.28.054-.127.143-.216.093-.09.206-.137.116-.052.24-.052.126 0 .24.048.112.048.198.14.085.09.133.216.052.127.052.284z"
          ></path>
          <path
            stroke="#000"
            d="M85.822 127.022q0-.199.055-.35.054-.153.15-.256.096-.103.223-.154.13-.051.274-.051h.02v-.285q-.127-.003-.236.031-.11.034-.202.103-.093.065-.164.157-.072.093-.12.202v-.462h-.264v1.77h.264z"
          ></path>
          <path
            stroke="#000"
            d="M88.605 126.84q0-.188-.068-.356-.069-.167-.192-.29-.123-.127-.295-.2-.167-.075-.366-.075-.202 0-.373.076-.168.072-.291.198-.123.127-.192.298-.068.168-.068.356 0 .189.068.356.069.168.192.295.123.123.291.198.168.072.366.072.199 0 .37-.072.171-.075.294-.202.124-.126.192-.294.072-.171.072-.36zm-.27.007q0 .144-.048.27-.048.124-.134.217-.085.092-.205.147-.12.051-.264.051-.14 0-.26-.055t-.209-.147q-.085-.092-.137-.22-.048-.126-.048-.27 0-.143.048-.267.048-.126.13-.219.086-.096.206-.147.12-.055.263-.055.14 0 .26.055t.21.15q.088.093.136.22.052.127.052.27z"
          ></path>
          <path
            stroke="#000"
            d="M89.69 127.426l-.595-1.469h-.291l.766 1.784h.233l.77-1.784h-.284z"
          ></path>
          <path
            stroke="#000"
            d="M91.043 126.94h1.403q.004-.024.004-.045v-.038q0-.195-.055-.366-.055-.171-.161-.298-.106-.126-.26-.198-.154-.076-.35-.076-.184 0-.342.072-.154.072-.267.199-.113.123-.178.294-.061.168-.061.36 0 .205.068.376.072.172.192.295.12.12.28.188.161.065.343.065.253 0 .424-.089.171-.092.305-.243l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.117 0-.223-.038-.106-.041-.192-.116-.085-.079-.14-.189-.055-.113-.068-.256zm0-.195q.013-.13.061-.24.048-.11.123-.188.076-.083.175-.127.1-.048.216-.048.133 0 .233.051.102.048.17.13.073.083.11.192.041.11.052.23z"
          ></path>
          <path
            stroke="#000"
            d="M94.315 126.272q-.071-.15-.212-.25-.14-.103-.356-.103-.113 0-.198.028-.086.027-.154.075-.069.045-.124.106-.051.062-.096.127v-.298h-.263v1.77h.263v-1.01q0-.123.038-.222.038-.103.103-.178.068-.076.157-.117.09-.04.195-.04.213 0 .333.14.123.136.123.386v1.041h.26v-1.02q0-.13.038-.233.04-.103.106-.171.068-.072.157-.106.09-.038.189-.038.215 0 .335.14.123.137.123.394v1.034h.264v-1.096q0-.332-.175-.52-.174-.192-.482-.192-.117 0-.213.028-.092.027-.167.075-.076.048-.137.113-.059.065-.107.137z"
          ></path>
          <path
            stroke="#000"
            d="M96.325 126.94h1.404q.003-.024.003-.045v-.038q0-.195-.055-.366-.054-.171-.16-.298-.107-.126-.26-.198-.155-.076-.35-.076-.185 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.071.172.191.295.12.12.28.188.162.065.343.065.254 0 .425-.089.171-.092.305-.243l-.165-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.107-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.195q.014-.13.062-.24.048-.11.123-.188.075-.083.175-.127.099-.048.215-.048.134 0 .233.051.103.048.171.13.072.083.11.192.04.11.051.23z"
          ></path>
          <path
            stroke="#000"
            d="M98.458 126.714q0-.124.038-.226.04-.103.113-.175.071-.075.167-.113.1-.04.213-.04.236 0 .362.143.127.144.127.39v1.034h.264v-1.099q0-.157-.048-.287-.045-.13-.134-.223-.085-.096-.212-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-.308h-.264v1.77h.264z"
          ></path>
          <path
            stroke="#000"
            d="M100.66 126.19h.56v-.233h-.56v-.534h-.264v.534h-.247v.233h.247v1.068q0 .134.037.23.038.095.103.157.069.058.161.086.092.027.199.027.095 0 .17-.02.08-.018.148-.055v-.226q-.069.034-.13.048-.058.013-.127.013-.133 0-.216-.065-.082-.065-.082-.23z"
          ></path>
          <path
            d="M61.217 129.97v-.29h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M62.073 131.114q0-.123.037-.226.042-.103.113-.174.072-.076.168-.113.1-.041.212-.041.237 0 .363.143.127.144.127.39v1.034h.264v-1.098q0-.158-.048-.288-.045-.13-.134-.223-.085-.095-.212-.147-.123-.051-.284-.051-.23 0-.373.103-.144.099-.233.243v-.309h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M66.349 130.673q-.072-.151-.213-.25-.14-.103-.356-.103-.113 0-.198.027-.086.028-.154.076-.069.044-.123.106-.052.061-.096.126v-.297h-.264v1.77h.264v-1.01q0-.124.037-.223.038-.103.103-.178.069-.075.158-.116.089-.042.195-.042.212 0 .332.14.123.138.123.388v1.04h.26v-1.02q0-.13.038-.233.04-.102.106-.17.068-.073.157-.107.09-.038.189-.038.215 0 .335.14.124.138.124.395v1.033h.263v-1.095q0-.332-.174-.52-.175-.192-.483-.192-.117 0-.212.027-.093.028-.168.076-.076.047-.137.112-.058.065-.106.137z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M68.358 131.34h1.404q.003-.024.003-.044v-.038q0-.195-.054-.366-.055-.172-.161-.298-.106-.127-.26-.199-.155-.075-.35-.075-.185 0-.342.072-.154.072-.267.198-.113.124-.178.295-.062.168-.062.36 0 .205.069.376.072.171.191.294.12.12.281.189.161.065.343.065.253 0 .424-.09.171-.092.305-.243l-.165-.147q-.11.113-.243.182-.13.068-.315.068-.116 0-.222-.038-.106-.04-.192-.116-.085-.079-.14-.188-.055-.113-.069-.257zm0-.195q.014-.13.062-.24.048-.11.123-.188.076-.082.175-.127.1-.048.215-.048.134 0 .233.052.103.048.171.13.072.082.11.192.041.11.051.229z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M71.912 132.127v-2.499h-.264v1.068q-.051-.075-.12-.143-.065-.069-.147-.12-.082-.052-.185-.082-.102-.031-.226-.031-.16 0-.311.065-.15.061-.27.181-.12.117-.192.288-.072.171-.072.39 0 .22.072.39.072.172.191.291.12.117.271.178.15.062.311.062.124 0 .223-.03.103-.032.185-.086.082-.055.147-.127.069-.072.123-.15v.355zm-.253-.886q0 .154-.055.284-.052.127-.144.216-.089.089-.205.14-.113.048-.237.048-.126 0-.24-.048-.112-.048-.198-.137-.085-.092-.137-.219-.048-.127-.048-.284 0-.161.048-.288.052-.13.134-.215.085-.09.198-.134.113-.048.243-.048.124 0 .237.052.116.047.205.136.092.09.144.216.055.127.055.28z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M72.809 129.97v-.29h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M74.921 131.826l-.168-.157q-.106.113-.24.188-.13.075-.3.075-.14 0-.26-.051-.117-.055-.206-.147-.086-.096-.137-.223-.048-.126-.048-.27 0-.144.048-.267.048-.127.134-.22.085-.095.202-.147.116-.054.25-.054.18 0 .304.078.127.08.233.189l.175-.185q-.062-.065-.134-.123-.072-.059-.157-.1-.086-.044-.189-.068-.102-.024-.23-.024-.194 0-.362.075-.164.072-.288.199-.12.126-.188.297-.068.168-.068.357 0 .188.068.356.069.167.188.294.124.123.288.199.168.071.363.071.25 0 .418-.095.17-.096.304-.247z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M75.996 130.566q.25 0 .387.124.14.12.14.36v.06q-.12-.034-.246-.054-.127-.02-.298-.02-.168 0-.308.037-.137.038-.24.11-.099.072-.154.181-.054.106-.054.247 0 .14.054.243.058.102.15.174.097.069.213.103.12.034.243.034.236 0 .39-.092.158-.093.25-.212v.267h.254v-1.082q0-.353-.195-.53-.195-.182-.558-.182-.196 0-.346.04-.15.038-.298.107l.079.216q.123-.059.25-.093.13-.037.287-.037zm.01.665q.165 0 .291.024.13.024.23.05v.172q0 .106-.048.195-.045.086-.127.15-.078.062-.188.1-.106.034-.23.034-.088 0-.17-.024-.08-.024-.14-.068-.063-.048-.1-.113-.034-.065-.034-.15 0-.172.133-.271.134-.1.383-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M77.677 130.59h.562v-.232h-.562v-.535h-.263v.535h-.247v.232h.247v1.068q0 .134.037.23.038.096.103.157.068.059.16.086.093.027.2.027.095 0 .17-.02.08-.017.148-.055v-.226q-.069.034-.13.048-.059.014-.127.014-.133 0-.216-.065-.082-.065-.082-.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M79.012 129.97v-.29h-.3v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M81.34 131.24q0-.187-.068-.355-.069-.168-.192-.291-.123-.127-.294-.199-.168-.075-.367-.075-.202 0-.373.075-.168.072-.29.199-.124.126-.192.298-.069.167-.069.356 0 .188.069.356.068.167.191.294.123.123.291.199.168.072.367.072.198 0 .37-.072.17-.076.294-.202.123-.127.191-.295.072-.17.072-.36zm-.27.008q0 .144-.048.27-.048.124-.134.216-.085.092-.205.147-.12.051-.264.051-.14 0-.26-.054-.12-.055-.209-.148-.085-.092-.137-.219-.047-.126-.047-.27 0-.144.047-.267.048-.127.13-.22.086-.095.206-.146.12-.055.264-.055.14 0 .26.055.12.054.209.15.089.093.137.22.05.126.05.27z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M82.08 131.114q0-.123.037-.226.042-.103.113-.174.072-.076.168-.113.1-.041.212-.041.237 0 .363.143.127.144.127.39v1.034h.264v-1.098q0-.158-.048-.288-.045-.13-.134-.223-.085-.095-.212-.147-.123-.051-.284-.051-.23 0-.373.103-.144.099-.233.243v-.309h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M85.592 130.566q.25 0 .387.124.14.12.14.36v.06q-.12-.034-.246-.054-.127-.02-.298-.02-.168 0-.308.037-.137.038-.24.11-.099.072-.154.181-.054.106-.054.247 0 .14.054.243.059.102.151.174.096.069.212.103.12.034.243.034.237 0 .39-.092.158-.093.25-.212v.267h.254v-1.082q0-.353-.195-.53-.195-.182-.558-.182-.195 0-.346.04-.15.038-.298.107l.079.216q.123-.059.25-.093.13-.037.287-.037zm.01.665q.165 0 .292.024.13.024.229.05v.172q0 .106-.048.195-.044.086-.127.15-.078.062-.188.1-.106.034-.23.034-.088 0-.17-.024-.08-.024-.14-.068-.062-.048-.1-.113-.034-.065-.034-.15 0-.172.133-.271.134-.1.384-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M88.619 132.127v-2.499h-.264v1.068q-.051-.075-.12-.143-.065-.069-.147-.12-.082-.052-.185-.082-.102-.031-.226-.031-.16 0-.311.065-.15.061-.27.181-.12.117-.192.288-.072.171-.072.39 0 .22.072.39.071.172.191.291.12.117.27.178.151.062.312.062.124 0 .223-.03.103-.032.185-.086.082-.055.147-.127.068-.072.123-.15v.355zm-.254-.886q0 .154-.054.284-.052.127-.144.216-.09.089-.206.14-.113.048-.236.048-.126 0-.24-.048-.112-.048-.198-.137-.086-.092-.137-.219-.048-.127-.048-.284 0-.161.048-.288.051-.13.134-.215.085-.09.198-.134.113-.048.243-.048.123 0 .236.052.117.047.206.136.092.09.144.216.054.127.054.28z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M89.471 131.114q0-.123.038-.226.04-.103.113-.174.072-.076.168-.113.099-.041.212-.041.236 0 .363.143.126.144.126.39v1.034h.264v-1.098q0-.158-.048-.288-.044-.13-.133-.223-.086-.095-.213-.147-.123-.051-.284-.051-.23 0-.373.103-.144.099-.233.243v-1.038h-.263v2.5h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M91.474 131.34h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.172-.16-.298-.107-.127-.26-.199-.155-.075-.35-.075-.185 0-.342.072-.154.072-.267.198-.113.124-.178.295-.062.168-.062.36 0 .205.068.376.072.171.192.294.12.12.28.189.162.065.343.065.254 0 .425-.09.17-.092.304-.243l-.164-.147q-.11.113-.243.182-.13.068-.315.068-.116 0-.223-.038-.106-.04-.191-.116-.086-.079-.14-.188-.055-.113-.07-.257zm0-.195q.014-.13.062-.24.048-.11.123-.188.075-.082.174-.127.1-.048.216-.048.134 0 .233.052.103.048.171.13.072.082.11.192.04.11.051.229z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M93.607 131.422q0-.198.055-.349.054-.154.15-.257.096-.102.223-.154.13-.051.274-.051h.02v-.284q-.127-.003-.236.03-.11.035-.202.103-.092.065-.164.158-.072.092-.12.202v-.462h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M94.812 131.34h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.172-.16-.298-.107-.127-.26-.199-.155-.075-.35-.075-.185 0-.342.072-.155.072-.268.198-.113.124-.178.295-.061.168-.061.36 0 .205.068.376.072.171.192.294.12.12.28.189.162.065.343.065.253 0 .425-.09.17-.092.304-.243l-.164-.147q-.11.113-.243.182-.13.068-.315.068-.116 0-.223-.038-.106-.04-.191-.116-.086-.079-.14-.188-.055-.113-.07-.257zm0-.195q.014-.13.062-.24.047-.11.123-.188.075-.082.174-.127.1-.048.216-.048.134 0 .233.052.103.048.171.13.072.082.11.192.04.11.05.229z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M96.945 131.114q0-.123.037-.226.041-.103.113-.174.072-.076.168-.113.1-.041.212-.041.237 0 .363.143.127.144.127.39v1.034h.264v-1.098q0-.158-.048-.288-.045-.13-.134-.223-.085-.095-.212-.147-.123-.051-.284-.051-.23 0-.373.103-.144.099-.233.243v-.309h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M100.31 131.826l-.168-.157q-.106.113-.24.188-.13.075-.3.075-.141 0-.26-.051-.117-.055-.206-.147-.086-.096-.137-.223-.048-.126-.048-.27 0-.144.048-.267.048-.127.133-.22.086-.095.202-.147.117-.054.25-.054.182 0 .305.078.127.08.233.189l.174-.185q-.061-.065-.133-.123-.072-.059-.158-.1-.085-.044-.188-.068-.103-.024-.23-.024-.194 0-.362.075-.165.072-.288.199-.12.126-.188.297-.069.168-.069.357 0 .188.069.356.068.167.188.294.123.123.288.199.168.071.363.071.25 0 .417-.095.172-.096.305-.247z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M100.851 131.34h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.172-.161-.298-.106-.127-.26-.199-.154-.075-.35-.075-.184 0-.342.072-.154.072-.267.198-.113.124-.178.295-.061.168-.061.36 0 .205.068.376.072.171.192.294.12.12.28.189.161.065.343.065.253 0 .424-.09.172-.092.305-.243l-.164-.147q-.11.113-.243.182-.13.068-.315.068-.117 0-.223-.038-.106-.04-.192-.116-.085-.079-.14-.188-.055-.113-.068-.257zm0-.195q.014-.13.062-.24.047-.11.123-.188.075-.082.174-.127.1-.048.216-.048.134 0 .233.052.103.048.171.13.072.082.11.192.04.11.051.229z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M102.97 132.127v-.359h-.315v.36z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
        </g>
        <path
          fill="#e56670"
          strokeWidth="5.241"
          d="M135.231 25.091a2.635 2.635 0 01-2.635 2.636 2.635 2.635 0 01-2.635-2.636 2.635 2.635 0 012.635-2.635 2.635 2.635 0 012.635 2.635z"
        ></path>
        <g
          ariaLabel="2"
          style={{ lineHeight: "1.25" }}
          fill="#fff"
          strokeWidth="0.091"
          fontSize="3.645"
          transform="translate(0 -35)"
        >
          <path
            d="M132.649 59.056q.102 0 .197.037.095.033.164.098.069.062.11.153.043.088.043.2 0 .096-.03.187-.025.087-.09.182-.063.091-.165.197-.102.105-.247.237l-.926.816v.222h1.782v-.262h-1.352l.682-.605q.167-.146.287-.266.12-.124.2-.237.081-.117.118-.233.04-.117.04-.26 0-.156-.059-.287-.058-.135-.164-.23-.105-.098-.251-.153-.142-.054-.317-.054-.168 0-.303.036-.13.037-.24.106-.106.069-.197.171-.091.102-.179.23l.212.153q.153-.215.31-.325.156-.113.375-.113z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
        </g>
        <g
          ariaLabel="Pre-operative"
          style={{ lineHeight: "1.25" }}
          fill="#e56670"
          strokeWidth="0.1"
          fontFamily="Gotham"
          fontSize="3.996"
          transform="translate(0 -35)"
        >
          <path
            d="M138.376 59.294q0-.208-.075-.371-.072-.168-.212-.28-.136-.116-.332-.176-.192-.064-.428-.064h-1.047V61.2h.316v-.987h.68q.223 0 .423-.056.2-.06.348-.175.152-.116.24-.288.087-.172.087-.4zm-.32.012q0 .14-.055.256-.052.112-.156.196-.1.08-.244.124-.14.044-.312.044h-.69v-1.231h.706q.34 0 .544.156.208.151.208.455z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M139.208 60.377q0-.232.064-.407.063-.18.175-.3.112-.12.26-.18.152-.06.32-.06h.024v-.332q-.148-.004-.276.036-.128.04-.236.12-.108.076-.192.184-.083.108-.14.236v-.54h-.307V61.2h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M140.614 60.281h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.131.144-.207.344-.072.196-.072.42 0 .24.08.439.084.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.104.2-.107.356-.283l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.228q.016-.151.072-.28.056-.127.144-.219.088-.096.204-.148.116-.056.252-.056.156 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M143.823 60.161v-.323h-1.11v.323z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M146.453 60.165q0-.22-.08-.415-.08-.196-.224-.34-.144-.148-.344-.232-.195-.088-.427-.088-.236 0-.436.088-.196.084-.34.232-.143.148-.223.348-.08.196-.08.415 0 .22.08.416.08.196.224.344.143.144.34.231.195.084.427.084.232 0 .431-.084.2-.087.344-.235.144-.148.224-.344.084-.2.084-.42zm-.316.008q0 .168-.056.316-.056.144-.156.252-.1.108-.24.172-.14.06-.307.06-.164 0-.304-.064-.14-.064-.244-.172-.1-.108-.16-.256-.055-.148-.055-.316 0-.167.055-.311.056-.148.152-.256.1-.112.24-.172.14-.064.308-.064.164 0 .304.064.14.064.243.176.104.108.16.256.06.148.06.315z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M149.094 60.165q0-.255-.084-.455-.083-.2-.223-.336-.14-.14-.32-.212-.176-.072-.36-.072-.144 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.176v-.416h-.308v2.706h.308v-1.035q.06.088.136.168.08.08.176.14.096.06.216.095.12.036.263.036.188 0 .364-.072.18-.075.316-.211.14-.14.223-.34.084-.2.084-.456zm-.315.004q0 .188-.06.34-.056.148-.156.252-.096.1-.232.156-.132.052-.28.052-.143 0-.28-.056-.131-.06-.24-.164-.103-.104-.167-.252-.06-.152-.06-.332 0-.18.06-.327.064-.148.168-.252.108-.104.24-.16.136-.06.28-.06.147 0 .28.056.131.056.23.164.1.104.157.252.06.148.06.331z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M149.846 60.281h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .496-.104.2-.107.355-.283l-.192-.172q-.128.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.163-.22-.064-.132-.08-.3zm0-.228q.016-.151.072-.28.055-.127.143-.219.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.083.096.127.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M152.335 60.377q0-.232.064-.407.064-.18.176-.3.112-.12.26-.18.152-.06.32-.06h.023v-.332q-.147-.004-.275.036-.128.04-.236.12-.108.076-.192.184t-.14.236v-.54h-.307V61.2h.307z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M154.293 59.378q.292 0 .452.144.164.14.164.42v.072q-.14-.04-.288-.064-.148-.024-.348-.024-.195 0-.36.044-.16.044-.279.127-.116.084-.18.212-.064.124-.064.288 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.284.04.275 0 .455-.109.184-.107.292-.247v.311h.296v-1.262q0-.412-.228-.62-.228-.212-.652-.212-.227 0-.403.048-.176.044-.348.124l.092.252q.144-.068.292-.108.152-.044.335-.044zm.012.775q.192 0 .34.028.152.028.268.06v.2q0 .124-.056.228-.052.1-.148.176-.092.072-.22.116-.124.04-.267.04-.104 0-.2-.028-.092-.028-.164-.08-.072-.056-.116-.132-.04-.076-.04-.176 0-.2.156-.316.156-.116.447-.116z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M156.256 59.406h.655v-.272h-.655v-.623h-.308v.623h-.288v.272h.288v1.247q0 .156.044.268.044.112.12.183.08.068.187.1.108.032.232.032.112 0 .2-.024.092-.02.172-.064v-.263q-.08.04-.152.056-.068.016-.148.016-.156 0-.252-.076-.095-.076-.095-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M157.814 58.683v-.34h-.352v.34zm-.024 2.517v-2.066h-.308V61.2z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M159.337 60.849l-.696-1.715h-.34l.896 2.082h.272l.899-2.082h-.332z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M160.915 60.281h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.132.144-.208.344-.071.196-.071.42 0 .24.08.439.083.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.104.2-.107.356-.283l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.228q.016-.151.072-.28.056-.127.144-.219.088-.096.204-.148.116-.056.252-.056.155 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
        </g>
        <g
          ariaLabel="Save $4,550 per prevented cancellation."
          style={{ lineHeight: "1.25" }}
          fill="#66e3d4"
          strokeWidth="0.29"
          fontSize="3.996"
          transform="translate(0 -35)"
        >
          <path
            fill="#000"
            d="M130.429 65.319q0-.096.04-.18.044-.084.12-.144.08-.06.188-.096.112-.036.247-.036.216 0 .412.072.2.072.396.232l.184-.244q-.216-.172-.448-.255-.228-.088-.536-.088-.2 0-.367.06-.168.055-.292.16-.124.1-.192.24-.068.139-.068.307 0 .176.056.304.056.127.172.223.116.096.292.164.18.068.42.12.22.048.36.1.143.052.223.116.08.064.112.144.032.076.032.176 0 .215-.172.347-.168.132-.452.132-.291 0-.515-.096-.224-.1-.444-.3l-.196.232q.252.228.528.34.28.108.615.108.208 0 .384-.056.176-.056.3-.16.128-.104.2-.252.071-.148.071-.327 0-.324-.223-.508-.22-.184-.688-.288-.231-.048-.38-.1-.147-.055-.231-.12-.084-.067-.116-.147-.032-.08-.032-.18z"
          ></path>
          <path
            fill="#000"
            d="M133.438 65.595q.292 0 .452.144.164.14.164.42v.071q-.14-.04-.288-.064-.148-.024-.348-.024-.196 0-.36.044-.16.044-.28.128-.115.084-.18.212-.063.124-.063.287 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.283.04.276 0 .456-.108.184-.108.292-.248v.312h.295v-1.263q0-.412-.228-.62-.227-.211-.65-.211-.229 0-.405.048-.175.044-.347.124l.092.251q.144-.067.291-.107.152-.044.336-.044zm.012.775q.192 0 .34.028.152.028.268.06v.2q0 .124-.056.227-.052.1-.148.176-.092.072-.22.116-.124.04-.268.04-.104 0-.2-.028-.091-.028-.163-.08-.072-.056-.116-.132-.04-.076-.04-.175 0-.2.156-.316.155-.116.447-.116z"
          ></path>
          <path
            fill="#000"
            d="M135.756 67.065l-.695-1.714h-.34l.895 2.082h.272l.899-2.082h-.332z"
          ></path>
          <path
            fill="#000"
            d="M137.334 66.498h1.639q.004-.028.004-.052v-.044q0-.228-.064-.428-.064-.2-.188-.347-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.311.232-.132.143-.208.343-.072.196-.072.42 0 .24.08.44.084.2.224.343.14.14.327.22.188.076.4.076.296 0 .495-.104.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.151.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.063-.131-.08-.3zm0-.228q.016-.152.072-.28.056-.128.144-.22.088-.095.204-.147.116-.056.252-.056.156 0 .272.06.12.056.2.151.083.096.127.224.048.128.06.268z"
          ></path>
          <path
            fill="#e56670"
            stroke="#e56670"
            d="M142.645 66.662q0-.304-.203-.488-.204-.188-.66-.292V64.9q.16.028.308.096.152.068.296.192l.172-.24q-.176-.136-.356-.216-.18-.083-.408-.103v-.24h-.256v.232q-.183.008-.34.068-.151.06-.263.16-.112.095-.176.227-.06.128-.06.284 0 .316.204.495.204.18.647.284v1q-.227-.025-.411-.12-.184-.097-.364-.26l-.18.24q.212.18.444.283.232.104.5.128v.4h.255v-.392q.188-.012.344-.068.16-.06.272-.16.112-.1.171-.232.064-.132.064-.295zm-1.095-.836q-.163-.04-.271-.087-.104-.052-.164-.112-.06-.06-.084-.132-.024-.072-.024-.156 0-.092.036-.172.04-.08.108-.14.072-.06.172-.096.1-.04.228-.044zm.788.856q0 .2-.152.327-.148.128-.404.14v-.955q.168.04.272.088.108.048.172.108.064.06.088.132.024.072.024.16z"
          ></path>
          <path
            fill="#e56670"
            stroke="#e56670"
            d="M144.668 64.6l-1.583 1.926.08.228h1.475v.663h.303v-.663h.436v-.264h-.436V64.6zm-.028 1.89h-1.175l1.175-1.443z"
          ></path>
          <path
            fill="#e56670"
            stroke="#e56670"
            d="M145.778 67.765l.048.14q.228-.052.336-.18.108-.124.108-.368v-.36h-.368v.42h.144q.012.128-.052.208-.064.084-.216.14z"
          ></path>
          <path
            fill="#e56670"
            stroke="#e56670"
            d="M146.97 65.982l.211.14q.124-.068.264-.112.144-.048.324-.048.155 0 .283.044.128.044.22.124.092.076.14.184.052.108.052.24 0 .136-.052.251-.048.112-.136.196-.088.084-.216.132-.124.044-.271.044-.216 0-.416-.1-.196-.104-.38-.283l-.207.227q.191.192.443.32.256.124.556.124.22 0 .403-.064.184-.068.316-.188.132-.124.204-.292.076-.171.076-.38 0-.2-.076-.359-.072-.16-.204-.268-.132-.112-.308-.172-.176-.06-.38-.06-.175 0-.303.037-.124.031-.248.087l.06-.895h1.335v-.291h-1.61z"
          ></path>
          <path
            fill="#e56670"
            stroke="#e56670"
            d="M149.415 65.982l.212.14q.124-.068.264-.112.143-.048.323-.048.156 0 .284.044.128.044.22.124.092.076.14.184.052.108.052.24 0 .136-.052.251-.048.112-.136.196-.088.084-.216.132-.124.044-.272.044-.216 0-.415-.1-.196-.104-.38-.283l-.208.227q.192.192.444.32.256.124.555.124.22 0 .404-.064.184-.068.316-.188.131-.124.203-.292.076-.171.076-.38 0-.2-.076-.359-.071-.16-.203-.268-.132-.112-.308-.172-.176-.06-.38-.06-.176 0-.303.037-.124.031-.248.087l.06-.895h1.334v-.291h-1.61z"
          ></path>
          <path
            fill="#e56670"
            stroke="#e56670"
            d="M154.075 66.014q0-.295-.084-.555-.08-.264-.236-.46-.152-.2-.372-.312-.215-.115-.483-.115-.272 0-.492.115-.22.116-.375.316-.152.196-.236.46-.084.264-.084.56 0 .295.08.559.084.26.236.46.155.195.371.311.22.112.492.112.268 0 .487-.116.22-.116.372-.312.156-.2.24-.463.084-.264.084-.56zm-.328.008q0 .232-.06.444-.056.208-.168.367-.108.156-.268.252-.155.092-.351.092-.196 0-.356-.092-.16-.096-.272-.256-.111-.16-.171-.371-.06-.212-.06-.444t.06-.44q.06-.211.167-.367.112-.16.268-.252.16-.096.356-.096.196 0 .351.096.16.096.272.256.112.16.172.372.06.207.06.44z"
          ></path>
          <path
            fill="#000"
            d="M157.951 66.382q0-.256-.084-.456t-.224-.335q-.14-.14-.32-.212-.175-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.175.144-.076.084-.14.175v-.415h-.308v2.705h.308v-1.035q.06.088.136.168.08.08.175.14.096.06.216.096.12.036.264.036.188 0 .364-.072.18-.076.315-.212.14-.14.224-.34.084-.2.084-.455zm-.316.004q0 .188-.06.34-.055.147-.155.251-.096.1-.232.156-.132.052-.28.052-.144 0-.28-.056-.132-.06-.24-.164-.103-.104-.167-.251-.06-.152-.06-.332 0-.18.06-.328.064-.148.168-.252.107-.103.24-.16.135-.06.279-.06.148 0 .28.057.132.056.232.163.1.104.155.252.06.148.06.332z"
          ></path>
          <path
            fill="#000"
            d="M158.702 66.498h1.639q.004-.028.004-.052v-.044q0-.228-.064-.428-.064-.2-.188-.347-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.131.143-.207.343-.072.196-.072.42 0 .24.08.44.083.2.223.343.14.14.328.22.188.076.4.076.295 0 .495-.104.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.131-.08-.3zm0-.228q.016-.152.072-.28.056-.128.144-.22.088-.095.204-.147.116-.056.252-.056.155 0 .271.06.12.056.2.151.084.096.128.224.048.128.06.268z"
          ></path>
          <path
            fill="#000"
            d="M161.192 66.594q0-.232.064-.408.064-.18.176-.3.112-.12.26-.18.151-.06.32-.06h.023v-.331q-.148-.004-.276.036-.127.04-.235.12-.108.076-.192.184-.084.107-.14.235v-.54h-.308v2.067h.308z"
          ></path>
          <path
            d="M132.22 71.518q0-.255-.085-.455-.084-.2-.223-.336-.14-.14-.32-.212-.176-.072-.36-.072-.144 0-.264.04-.115.036-.211.1-.096.06-.176.144-.076.084-.14.176v-.416h-.308v2.706h.308v-1.035q.06.088.136.168.08.08.176.14.096.06.215.095.12.036.264.036.188 0 .364-.071.18-.076.316-.212.14-.14.223-.34.084-.2.084-.456zm-.316.004q0 .188-.06.34-.056.148-.156.252-.096.1-.232.156-.132.052-.28.052-.144 0-.28-.056-.131-.06-.24-.164-.103-.104-.167-.252-.06-.152-.06-.332 0-.18.06-.327.064-.148.168-.252.108-.104.24-.16.135-.06.28-.06.147 0 .279.056.132.056.232.164.1.104.156.252.06.148.06.331z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M133.094 71.73q0-.232.064-.407.064-.18.176-.3.112-.12.26-.18.152-.06.32-.06h.024v-.332q-.148-.004-.276.036-.128.04-.236.12-.108.076-.192.184t-.14.236v-.54h-.307v2.066h.307z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M134.501 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.132.144-.208.344-.071.196-.071.42 0 .24.08.439.083.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.251-.056.156 0 .272.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M137.414 72.202l-.695-1.715h-.34l.896 2.082h.271l.9-2.082h-.332z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M138.993 71.634h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.063-.2-.187-.348-.124-.148-.304-.232-.18-.088-.408-.088-.215 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.328.22.187.075.4.075.295 0 .495-.103.2-.108.355-.284l-.191-.172q-.128.132-.284.212-.152.08-.368.08-.136 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.203-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M141.483 71.37q0-.143.044-.263.047-.12.131-.204.084-.088.196-.132.116-.048.248-.048.276 0 .424.168.147.168.147.456v1.206h.308v-1.282q0-.184-.056-.336-.052-.152-.156-.26-.1-.112-.247-.172-.144-.06-.332-.06-.268 0-.436.12-.168.116-.271.284v-.36h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M144.052 70.76h.656v-.273h-.656v-.623h-.308v.623h-.287v.272h.287v1.247q0 .156.044.268.044.112.12.184.08.068.188.1.108.031.232.031.112 0 .2-.024.092-.02.172-.063v-.264q-.08.04-.152.056-.068.016-.148.016-.156 0-.252-.076t-.096-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M145.375 71.634h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.063-.2-.187-.348-.124-.148-.304-.232-.18-.088-.408-.088-.215 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.328.22.187.075.4.075.295 0 .495-.103.2-.108.355-.284l-.191-.172q-.128.132-.284.212-.152.08-.368.08-.136 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.203-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M149.523 72.553v-2.917h-.308v1.247q-.06-.088-.14-.168-.076-.08-.171-.14-.096-.06-.216-.096-.12-.036-.264-.036-.188 0-.364.076-.175.072-.315.212-.14.136-.224.336-.084.2-.084.455 0 .256.084.456t.224.34q.14.136.315.207.176.072.364.072.144 0 .26-.036.12-.036.216-.1.095-.063.171-.147.08-.084.144-.176v.415zm-.296-1.035q0 .18-.064.332-.06.148-.167.252-.104.104-.24.164-.132.056-.276.056-.148 0-.28-.056-.132-.056-.232-.16-.1-.108-.16-.256-.055-.148-.055-.332 0-.187.056-.335.06-.152.155-.252.1-.104.232-.156.132-.056.284-.056.144 0 .276.06.136.056.24.16.107.104.167.252.064.148.064.327z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M153.184 72.202l-.196-.184q-.124.132-.28.22-.152.088-.352.088-.164 0-.303-.06-.136-.064-.24-.172-.1-.112-.16-.26-.056-.148-.056-.316 0-.167.056-.311.056-.148.156-.256.1-.112.236-.172.135-.064.291-.064.212 0 .356.092.148.092.272.22l.204-.216q-.072-.076-.156-.144-.084-.068-.184-.116-.1-.052-.22-.08-.12-.028-.268-.028-.227 0-.423.088-.192.084-.336.232-.14.148-.22.348-.08.196-.08.415 0 .22.08.416.08.196.22.344.144.144.336.232.196.083.423.083.292 0 .488-.111.2-.112.356-.288z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M154.438 70.731q.292 0 .452.144.164.14.164.42v.072q-.14-.04-.288-.064-.148-.024-.348-.024-.195 0-.36.044-.16.044-.279.128-.116.083-.18.211-.064.124-.064.288 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.283.04.276 0 .456-.108.184-.108.292-.248v.311h.295v-1.262q0-.412-.227-.62-.228-.212-.652-.212-.227 0-.403.048-.176.044-.348.124l.092.252q.144-.068.292-.108.151-.044.335-.044zm.012.775q.192 0 .34.028.152.028.268.06v.2q0 .124-.056.228-.052.1-.148.176-.092.072-.22.116-.124.04-.268.04-.103 0-.2-.028-.091-.028-.163-.08-.072-.056-.116-.132-.04-.076-.04-.176 0-.2.156-.316.156-.116.447-.116z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M156.313 71.37q0-.143.044-.263.048-.12.131-.204.084-.088.196-.132.116-.048.248-.048.276 0 .424.168.147.168.147.456v1.206h.308v-1.282q0-.184-.056-.336-.052-.152-.156-.26-.1-.112-.247-.172-.144-.06-.332-.06-.268 0-.436.12-.167.116-.271.284v-.36h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M160.24 72.202l-.195-.184q-.124.132-.28.22-.152.088-.351.088-.164 0-.304-.06-.136-.064-.24-.172-.1-.112-.16-.26-.056-.148-.056-.316 0-.167.056-.311.056-.148.156-.256.1-.112.236-.172.136-.064.292-.064.211 0 .355.092.148.092.272.22l.204-.216q-.072-.076-.156-.144-.084-.068-.184-.116-.1-.052-.22-.08-.12-.028-.267-.028-.228 0-.424.088-.192.084-.336.232-.14.148-.22.348-.08.196-.08.415 0 .22.08.416.08.196.22.344.144.144.336.232.196.083.424.083.291 0 .487-.111.2-.112.356-.288z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M160.872 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.132.144-.207.344-.072.196-.072.42 0 .24.08.439.083.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.155 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M163.39 72.553v-2.917h-.308v2.917z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M164.44 72.553v-2.917h-.307v2.917z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M165.904 70.731q.291 0 .451.144.164.14.164.42v.072q-.14-.04-.288-.064-.148-.024-.347-.024-.196 0-.36.044-.16.044-.28.128-.116.083-.18.211-.064.124-.064.288 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.284.04.275 0 .455-.108.184-.108.292-.248v.311h.296v-1.262q0-.412-.228-.62-.228-.212-.652-.212-.227 0-.403.048-.176.044-.348.124l.092.252q.144-.068.292-.108.152-.044.336-.044zm.012.775q.191 0 .34.028.151.028.267.06v.2q0 .124-.056.228-.052.1-.148.176-.092.072-.22.116-.123.04-.267.04-.104 0-.2-.028-.092-.028-.164-.08-.072-.056-.116-.132-.04-.076-.04-.176 0-.2.156-.316.156-.116.448-.116z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M167.866 70.76h.655v-.273h-.655v-.623h-.308v.623h-.288v.272h.288v1.247q0 .156.044.268.044.112.12.184.08.068.188.1.108.031.231.031.112 0 .2-.024.092-.02.172-.063v-.264q-.08.04-.152.056-.068.016-.148.016-.155 0-.251-.076t-.096-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M169.424 70.036v-.34h-.351v.34zm-.024 2.517v-2.066h-.308v2.066z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M172.142 71.518q0-.22-.08-.415-.08-.196-.224-.34-.144-.148-.344-.232-.196-.088-.427-.088-.236 0-.436.088-.196.084-.34.232-.143.148-.223.348-.08.196-.08.415 0 .22.08.416.08.196.223.344.144.144.34.232.196.083.428.083.231 0 .431-.083.2-.088.344-.236.144-.148.224-.344.084-.2.084-.42zm-.316.008q0 .168-.056.316-.056.144-.156.252-.1.108-.24.172-.14.06-.307.06-.164 0-.304-.064-.14-.064-.244-.172-.1-.108-.16-.256-.056-.148-.056-.316 0-.167.056-.311.056-.148.152-.256.1-.112.24-.172.14-.064.308-.064.164 0 .303.064.14.064.244.176.104.108.16.256.06.148.06.315z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M173.005 71.37q0-.143.044-.263.048-.12.132-.204.084-.088.196-.132.115-.048.247-.048.276 0 .424.168.148.168.148.456v1.206h.307v-1.282q0-.184-.055-.336-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.332-.06-.268 0-.435.12-.168.116-.272.284v-.36h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M175.49 72.553v-.42h-.367v.42z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
        </g>
        <path
          fill="#6974c2"
          strokeWidth="5.241"
          d="M225 25.091a2.635 2.635 0 01-2.635 2.636 2.635 2.635 0 01-2.635-2.636 2.635 2.635 0 012.635-2.635 2.635 2.635 0 012.636 2.635z"
        ></path>
        <g
          ariaLabel="3"
          style={{ lineHeight: "1.25" }}
          fill="#fff"
          strokeWidth="0.091"
          fontSize="3.645"
          transform="translate(0 -35)"
        >
          <path
            d="M222.845 59.053l-.806.907.059.172h.164q.317 0 .513.127.2.128.2.368 0 .113-.043.208-.044.091-.12.157-.073.065-.175.102-.102.032-.219.032-.237 0-.419-.098-.178-.098-.328-.28l-.208.185q.157.204.394.332.237.124.558.124.178 0 .331-.055.157-.058.27-.16.117-.102.182-.244.07-.142.07-.314 0-.175-.07-.306-.069-.131-.186-.219-.113-.087-.266-.134-.153-.048-.32-.062l.801-.89v-.211h-1.629v.259z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
        </g>
        <g
          ariaLabel="Pre-operative"
          style={{ lineHeight: "1.25" }}
          fill="#6974c2"
          strokeWidth="0.1"
          fontFamily="Gotham"
          fontSize="3.996"
          transform="translate(0 -35)"
        >
          <path
            d="M228.146 59.294q0-.208-.076-.371-.072-.168-.212-.28-.136-.116-.332-.176-.192-.064-.427-.064h-1.047V61.2h.315v-.987h.68q.224 0 .423-.056.2-.06.348-.175.152-.116.24-.288t.088-.4zm-.32.012q0 .14-.056.256-.052.112-.156.196-.1.08-.244.124-.14.044-.311.044h-.692v-1.231h.708q.34 0 .543.156.208.151.208.455z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M228.977 60.377q0-.232.064-.407.064-.18.176-.3.112-.12.26-.18.151-.06.32-.06h.023v-.332q-.148-.004-.276.036-.128.04-.235.12-.108.076-.192.184t-.14.236v-.54h-.308V61.2h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M230.384 60.281h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .496-.104.2-.107.355-.283l-.192-.172q-.128.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.163-.22-.064-.132-.08-.3zm0-.228q.016-.151.071-.28.056-.127.144-.219.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.083.096.127.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M233.593 60.161v-.323h-1.111v.323z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M236.222 60.165q0-.22-.08-.415-.08-.196-.224-.34-.143-.148-.343-.232-.196-.088-.428-.088-.236 0-.435.088-.196.084-.34.232-.144.148-.224.348-.08.196-.08.415 0 .22.08.416.08.196.224.344.144.144.34.231.195.084.427.084.232 0 .432-.084.2-.087.343-.235.144-.148.224-.344.084-.2.084-.42zm-.316.008q0 .168-.056.316-.055.144-.155.252-.1.108-.24.172-.14.06-.308.06-.164 0-.304-.064-.14-.064-.243-.172-.1-.108-.16-.256-.056-.148-.056-.316 0-.167.056-.311.056-.148.152-.256.1-.112.24-.172.14-.064.307-.064.164 0 .304.064.14.064.244.176.104.108.16.256.06.148.06.315z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M238.864 60.165q0-.255-.084-.455t-.224-.336q-.14-.14-.32-.212-.176-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.176v-.416h-.307v2.706h.307v-1.035q.06.088.136.168.08.08.176.14.096.06.216.095.12.036.264.036.187 0 .363-.072.18-.075.316-.211.14-.14.224-.34.084-.2.084-.456zm-.316.004q0 .188-.06.34-.056.148-.156.252-.096.1-.232.156-.132.052-.28.052-.143 0-.28-.056-.131-.06-.239-.164-.104-.104-.168-.252-.06-.152-.06-.332 0-.18.06-.327.064-.148.168-.252.108-.104.24-.16.136-.06.28-.06.147 0 .28.056.131.056.231.164.1.104.156.252.06.148.06.331z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M239.615 60.281h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.063-.2-.187-.348-.124-.148-.304-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.328.22.187.075.4.075.295 0 .495-.104.2-.107.355-.283l-.191-.172q-.128.132-.284.212-.152.08-.368.08-.136 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.228q.016-.151.072-.28.056-.127.144-.219.088-.096.203-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.084.096.128.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M242.105 60.377q0-.232.063-.407.064-.18.176-.3.112-.12.26-.18.152-.06.32-.06h.024v-.332q-.148-.004-.276.036-.128.04-.236.12-.108.076-.192.184t-.14.236v-.54h-.307V61.2h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M244.063 59.378q.291 0 .451.144.164.14.164.42v.072q-.14-.04-.288-.064-.147-.024-.347-.024-.196 0-.36.044-.16.044-.28.127-.116.084-.18.212-.063.124-.063.288 0 .164.064.284.067.12.175.204.112.08.248.12.14.04.284.04.276 0 .455-.109.184-.107.292-.247v.311h.296v-1.262q0-.412-.228-.62-.228-.212-.651-.212-.228 0-.404.048-.176.044-.348.124l.092.252q.144-.068.292-.108.152-.044.336-.044zm.012.775q.192 0 .34.028.151.028.267.06v.2q0 .124-.056.228-.052.1-.148.176-.092.072-.22.116-.123.04-.267.04-.104 0-.2-.028-.092-.028-.164-.08-.072-.056-.116-.132-.04-.076-.04-.176 0-.2.156-.316.156-.116.448-.116z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M246.025 59.406h.655v-.272h-.655v-.623h-.308v.623h-.288v.272h.288v1.247q0 .156.044.268.044.112.12.183.08.068.188.1t.232.032q.112 0 .2-.024.091-.02.171-.064v-.263q-.08.04-.152.056-.067.016-.147.016-.156 0-.252-.076t-.096-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M247.583 58.683v-.34h-.351v.34zm-.024 2.517v-2.066h-.307V61.2z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M249.106 60.849l-.695-1.715h-.34l.895 2.082h.272l.899-2.082h-.332z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M250.684 60.281h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .495-.104.2-.107.356-.283l-.192-.172q-.128.132-.284.212-.151.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.063-.132-.08-.3zm0-.228q.016-.151.072-.28.056-.127.144-.219.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.083.096.127.224.048.128.06.267z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
        </g>
        <g
          ariaLabel="Save $4,550 per prevented cancellation."
          style={{ lineHeight: "1.25" }}
          fill="#66e3d4"
          strokeWidth="0.29"
          fontSize="3.996"
          transform="translate(0 -35)"
        >
          <path
            fill="#000"
            d="M220.198 65.319q0-.096.04-.18.044-.084.12-.144.08-.06.188-.096.112-.036.248-.036.216 0 .411.072.2.072.396.232l.184-.244q-.216-.172-.448-.255-.227-.088-.535-.088-.2 0-.368.06-.168.055-.292.16-.123.1-.191.24-.068.139-.068.307 0 .176.056.304.055.127.171.223.116.096.292.164.18.068.42.12.22.048.36.1.143.052.223.116.08.064.112.144.032.076.032.176 0 .215-.172.347-.168.132-.451.132-.292 0-.516-.096-.224-.1-.443-.3l-.196.232q.252.228.527.34.28.108.616.108.208 0 .383-.056.176-.056.3-.16.128-.104.2-.252.072-.148.072-.327 0-.324-.224-.508-.22-.184-.687-.288-.232-.048-.38-.1-.148-.055-.232-.12-.084-.067-.116-.147-.032-.08-.032-.18z"
          ></path>
          <path
            fill="#000"
            d="M223.207 65.595q.292 0 .452.144.164.14.164.42v.071q-.14-.04-.288-.064-.148-.024-.347-.024-.196 0-.36.044-.16.044-.28.128-.116.084-.18.212-.064.124-.064.287 0 .164.064.284.068.12.176.204.112.08.248.12.14.04.284.04.275 0 .455-.108.184-.108.292-.248v.312h.296v-1.263q0-.412-.228-.62-.228-.211-.652-.211-.227 0-.403.048-.176.044-.348.124l.092.251q.144-.067.292-.107.152-.044.335-.044zm.012.775q.192 0 .34.028.152.028.268.06v.2q0 .124-.056.227-.052.1-.148.176-.092.072-.22.116-.124.04-.267.04-.104 0-.2-.028-.092-.028-.164-.08-.072-.056-.116-.132-.04-.076-.04-.175 0-.2.156-.316.156-.116.447-.116z"
          ></path>
          <path
            fill="#000"
            d="M225.525 67.065l-.695-1.714h-.34l.895 2.082h.272l.9-2.082h-.332z"
          ></path>
          <path
            fill="#000"
            d="M227.104 66.498h1.638q.004-.028.004-.052v-.044q0-.228-.064-.428-.064-.2-.187-.347-.124-.148-.304-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.143-.208.343-.072.196-.072.42 0 .24.08.44.084.2.224.343.14.14.328.22.187.076.4.076.295 0 .495-.104.2-.108.355-.284l-.191-.172q-.128.132-.284.212-.152.08-.368.08-.136 0-.26-.044-.123-.048-.223-.136-.1-.092-.164-.22-.064-.131-.08-.3zm0-.228q.016-.152.072-.28.056-.128.144-.22.087-.095.203-.147.116-.056.252-.056.156 0 .272.06.12.056.2.151.084.096.128.224.048.128.06.268z"
          ></path>
          <path
            fill="#6974c2"
            stroke="#6974c2"
            d="M232.415 66.662q0-.304-.204-.488-.204-.188-.66-.292V64.9q.16.028.308.096.152.068.296.192l.172-.24q-.176-.136-.356-.216-.18-.083-.407-.103v-.24h-.256v.232q-.184.008-.34.068-.152.06-.264.16-.111.095-.175.227-.06.128-.06.284 0 .316.203.495.204.18.648.284v1q-.228-.025-.412-.12-.184-.097-.363-.26l-.18.24q.211.18.443.283.232.104.5.128v.4h.256v-.392q.187-.012.343-.068.16-.06.272-.16.112-.1.172-.232.064-.132.064-.295zm-1.095-.836q-.164-.04-.272-.087-.104-.052-.164-.112-.06-.06-.084-.132-.024-.072-.024-.156 0-.092.036-.172.04-.08.108-.14.072-.06.172-.096.1-.04.228-.044zm.787.856q0 .2-.152.327-.148.128-.403.14v-.955q.167.04.271.088.108.048.172.108.064.06.088.132.024.072.024.16z"
          ></path>
          <path
            fill="#6974c2"
            stroke="#6974c2"
            d="M234.437 64.6l-1.583 1.926.08.228h1.475v.663h.304v-.663h.435v-.264h-.435V64.6zm-.028 1.89h-1.175l1.175-1.443z"
          ></path>
          <path
            fill="#6974c2"
            stroke="#6974c2"
            d="M235.548 67.765l.048.14q.228-.052.335-.18.108-.124.108-.368v-.36h-.367v.42h.144q.012.128-.052.208-.064.084-.216.14z"
          ></path>
          <path
            fill="#6974c2"
            stroke="#6974c2"
            d="M236.739 65.982l.212.14q.123-.068.263-.112.144-.048.324-.048.156 0 .284.044.128.044.22.124.091.076.14.184.051.108.051.24 0 .136-.052.251-.048.112-.136.196-.087.084-.215.132-.124.044-.272.044-.216 0-.416-.1-.195-.104-.38-.283l-.207.227q.192.192.443.32.256.124.556.124.22 0 .404-.064.183-.068.315-.188.132-.124.204-.292.076-.171.076-.38 0-.2-.076-.359-.072-.16-.204-.268-.132-.112-.307-.172-.176-.06-.38-.06-.176 0-.304.037-.124.031-.248.087l.06-.895h1.335v-.291h-1.61z"
          ></path>
          <path
            fill="#6974c2"
            stroke="#6974c2"
            d="M239.184 65.982l.212.14q.124-.068.264-.112.144-.048.324-.048.156 0 .283.044.128.044.22.124.092.076.14.184.052.108.052.24 0 .136-.052.251-.048.112-.136.196-.088.084-.216.132-.124.044-.271.044-.216 0-.416-.1-.196-.104-.38-.283l-.207.227q.191.192.443.32.256.124.556.124.22 0 .403-.064.184-.068.316-.188.132-.124.204-.292.076-.171.076-.38 0-.2-.076-.359-.072-.16-.204-.268-.132-.112-.308-.172-.176-.06-.38-.06-.175 0-.303.037-.124.031-.248.087l.06-.895h1.335v-.291h-1.61z"
          ></path>
          <path
            fill="#6974c2"
            stroke="#6974c2"
            d="M243.844 66.014q0-.295-.084-.555-.08-.264-.236-.46-.152-.2-.371-.312-.216-.115-.484-.115-.272 0-.491.115-.22.116-.376.316-.152.196-.236.46-.084.264-.084.56 0 .295.08.559.084.26.236.46.156.195.372.311.22.112.491.112.268 0 .488-.116.22-.116.371-.312.156-.2.24-.463.084-.264.084-.56zm-.328.008q0 .232-.06.444-.055.208-.167.367-.108.156-.268.252-.156.092-.352.092-.196 0-.356-.092-.16-.096-.271-.256-.112-.16-.172-.371-.06-.212-.06-.444t.06-.44q.06-.211.168-.367.112-.16.268-.252.16-.096.355-.096.196 0 .352.096.16.096.272.256.111.16.171.372.06.207.06.44z"
          ></path>
          <path
            fill="#000"
            d="M247.72 66.382q0-.256-.084-.456-.083-.2-.223-.335-.14-.14-.32-.212-.176-.072-.36-.072-.144 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.175v-.415h-.308v2.705h.308v-1.035q.06.088.136.168.08.08.176.14.096.06.216.096.12.036.263.036.188 0 .364-.072.18-.076.316-.212.14-.14.223-.34.084-.2.084-.455zm-.315.004q0 .188-.06.34-.056.147-.156.251-.096.1-.232.156-.132.052-.28.052-.144 0-.28-.056-.131-.06-.24-.164-.103-.104-.167-.251-.06-.152-.06-.332 0-.18.06-.328.064-.148.168-.252.108-.103.24-.16.135-.06.28-.06.147 0 .279.057.132.056.232.163.1.104.156.252.06.148.06.332z"
          ></path>
          <path
            fill="#000"
            d="M248.472 66.498h1.638q.004-.028.004-.052v-.044q0-.228-.064-.428-.064-.2-.188-.347-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.143-.208.343-.072.196-.072.42 0 .24.08.44.084.2.224.343.14.14.327.22.188.076.4.076.296 0 .496-.104.2-.108.355-.284l-.192-.172q-.127.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.163-.22-.064-.131-.08-.3zm0-.228q.016-.152.072-.28.056-.128.143-.22.088-.095.204-.147.116-.056.252-.056.156 0 .272.06.12.056.2.151.083.096.127.224.048.128.06.268z"
          ></path>
          <path
            fill="#000"
            d="M250.961 66.594q0-.232.064-.408.064-.18.176-.3.112-.12.26-.18.152-.06.32-.06h.023v-.331q-.147-.004-.275.036-.128.04-.236.12-.108.076-.192.184-.084.107-.14.235v-.54h-.307v2.067h.307z"
          ></path>
          <path
            d="M221.989 71.518q0-.255-.084-.455t-.224-.336q-.14-.14-.32-.212-.176-.072-.36-.072-.143 0-.263.04-.116.036-.212.1-.096.06-.176.144-.076.084-.14.176v-.416h-.307v2.706h.307v-1.035q.06.088.136.168.08.08.176.14.096.06.216.095.12.036.264.036.187 0 .363-.071.18-.076.316-.212.14-.14.224-.34.084-.2.084-.456zm-.316.004q0 .188-.06.34-.056.148-.156.252-.096.1-.232.156-.132.052-.28.052-.143 0-.28-.056-.131-.06-.239-.164-.104-.104-.168-.252-.06-.152-.06-.332 0-.18.06-.327.064-.148.168-.252.108-.104.24-.16.136-.06.28-.06.147 0 .28.056.131.056.231.164.1.104.156.252.06.148.06.331z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M222.864 71.73q0-.232.064-.407.064-.18.176-.3.111-.12.26-.18.151-.06.319-.06h.024v-.332q-.148-.004-.276.036-.128.04-.236.12-.107.076-.191.184t-.14.236v-.54h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M224.27 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.063-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.083.096.127.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M227.184 72.202l-.696-1.715h-.34l.896 2.082h.272l.899-2.082h-.332z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M228.762 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.132.144-.207.344-.072.196-.072.42 0 .24.08.439.083.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.155 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M231.252 71.37q0-.143.044-.263.048-.12.132-.204.084-.088.196-.132.115-.048.247-.048.276 0 .424.168.148.168.148.456v1.206h.308v-1.282q0-.184-.056-.336-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.332-.06-.267 0-.435.12-.168.116-.272.284v-.36h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M233.821 70.76h.656v-.273h-.656v-.623h-.307v.623h-.288v.272h.288v1.247q0 .156.044.268.044.112.12.184.08.068.187.1.108.031.232.031.112 0 .2-.024.092-.02.172-.063v-.264q-.08.04-.152.056-.068.016-.148.016-.156 0-.252-.076-.095-.076-.095-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M235.144 71.634h1.639q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.304-.232-.18-.088-.407-.088-.216 0-.4.084-.18.084-.312.232-.132.144-.207.344-.072.196-.072.42 0 .24.08.439.083.2.223.344.14.14.328.22.188.075.4.075.295 0 .495-.103.2-.108.356-.284l-.192-.172q-.128.132-.284.212-.152.08-.367.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.164-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.056-.128.144-.22.088-.096.204-.148.116-.056.252-.056.155 0 .271.06.12.056.2.152.084.096.128.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M239.292 72.553v-2.917h-.307v1.247q-.06-.088-.14-.168-.076-.08-.172-.14-.096-.06-.216-.096-.12-.036-.264-.036-.187 0-.363.076-.176.072-.316.212-.14.136-.224.336-.084.2-.084.455 0 .256.084.456t.224.34q.14.136.316.207.176.072.363.072.144 0 .26-.036.12-.036.216-.1.096-.063.172-.147.08-.084.144-.176v.415zm-.295-1.035q0 .18-.064.332-.06.148-.168.252-.104.104-.24.164-.132.056-.276.056-.148 0-.28-.056-.131-.056-.231-.16-.1-.108-.16-.256-.056-.148-.056-.332 0-.187.056-.335.06-.152.156-.252.1-.104.232-.156.131-.056.283-.056.144 0 .276.06.136.056.24.16.108.104.168.252.064.148.064.327z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M242.953 72.202l-.196-.184q-.124.132-.28.22-.152.088-.351.088-.164 0-.304-.06-.136-.064-.24-.172-.1-.112-.16-.26-.056-.148-.056-.316 0-.167.056-.311.056-.148.156-.256.1-.112.236-.172.136-.064.292-.064.211 0 .355.092.148.092.272.22l.204-.216q-.072-.076-.156-.144-.084-.068-.184-.116-.1-.052-.22-.08-.12-.028-.267-.028-.228 0-.424.088-.192.084-.336.232-.14.148-.22.348-.08.196-.08.415 0 .22.08.416.08.196.22.344.144.144.336.232.196.083.424.083.291 0 .487-.111.2-.112.356-.288z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M244.208 70.731q.291 0 .451.144.164.14.164.42v.072q-.14-.04-.288-.064-.147-.024-.347-.024-.196 0-.36.044-.16.044-.28.128-.116.083-.18.211-.063.124-.063.288 0 .164.063.284.068.12.176.204.112.08.248.12.14.04.284.04.276 0 .455-.108.184-.108.292-.248v.311h.296v-1.262q0-.412-.228-.62-.228-.212-.651-.212-.228 0-.404.048-.176.044-.348.124l.092.252q.144-.068.292-.108.152-.044.336-.044zm.012.775q.192 0 .34.028.151.028.267.06v.2q0 .124-.056.228-.052.1-.148.176-.092.072-.22.116-.123.04-.267.04-.104 0-.2-.028-.092-.028-.164-.08-.072-.056-.116-.132-.04-.076-.04-.176 0-.2.156-.316.156-.116.448-.116z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M246.082 71.37q0-.143.044-.263.048-.12.132-.204.084-.088.196-.132.115-.048.247-.048.276 0 .424.168.148.168.148.456v1.206h.308v-1.282q0-.184-.056-.336-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.332-.06-.267 0-.435.12-.168.116-.272.284v-.36h-.308v2.066h.308z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M250.01 72.202l-.196-.184q-.123.132-.28.22-.151.088-.351.088-.164 0-.304-.06-.136-.064-.24-.172-.1-.112-.16-.26-.055-.148-.055-.316 0-.167.056-.311.056-.148.156-.256.1-.112.235-.172.136-.064.292-.064.212 0 .356.092.148.092.271.22l.204-.216q-.072-.076-.156-.144-.084-.068-.183-.116-.1-.052-.22-.08-.12-.028-.268-.028-.228 0-.424.088-.191.084-.335.232-.14.148-.22.348-.08.196-.08.415 0 .22.08.416.08.196.22.344.144.144.335.232.196.083.424.083.292 0 .488-.111.2-.112.355-.288z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M250.642 71.634h1.638q.004-.028.004-.052v-.044q0-.227-.064-.427-.064-.2-.188-.348-.124-.148-.303-.232-.18-.088-.408-.088-.216 0-.4.084-.18.084-.311.232-.132.144-.208.344-.072.196-.072.42 0 .24.08.439.084.2.224.344.14.14.327.22.188.075.4.075.296 0 .496-.103.2-.108.355-.284l-.192-.172q-.128.132-.283.212-.152.08-.368.08-.136 0-.26-.044-.124-.048-.224-.136-.1-.092-.163-.22-.064-.132-.08-.3zm0-.227q.016-.152.072-.28.055-.128.143-.22.088-.096.204-.148.116-.056.252-.056.156 0 .272.06.12.056.2.152.083.096.127.224.048.128.06.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M253.16 72.553v-2.917h-.308v2.917z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M254.21 72.553v-2.917h-.307v2.917z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M255.673 70.731q.292 0 .451.144.164.14.164.42v.072q-.14-.04-.287-.064-.148-.024-.348-.024-.196 0-.36.044-.16.044-.28.128-.115.083-.18.211-.063.124-.063.288 0 .164.064.284.068.12.175.204.112.08.248.12.14.04.284.04.276 0 .456-.108.183-.108.291-.248v.311h.296v-1.262q0-.412-.228-.62-.228-.212-.651-.212-.228 0-.404.048-.176.044-.347.124l.091.252q.144-.068.292-.108.152-.044.336-.044zm.012.775q.192 0 .34.028.151.028.267.06v.2q0 .124-.056.228-.052.1-.147.176-.092.072-.22.116-.124.04-.268.04-.104 0-.2-.028-.092-.028-.164-.08-.072-.056-.116-.132-.04-.076-.04-.176 0-.2.156-.316.156-.116.448-.116z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M257.635 70.76h.655v-.273h-.655v-.623h-.308v.623h-.287v.272h.287v1.247q0 .156.044.268.044.112.12.184.08.068.188.1.108.031.232.031.112 0 .2-.024.092-.02.171-.063v-.264q-.08.04-.151.056-.068.016-.148.016-.156 0-.252-.076t-.096-.268z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M259.194 70.036v-.34h-.352v.34zm-.024 2.517v-2.066h-.308v2.066z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M261.911 71.518q0-.22-.08-.415-.08-.196-.224-.34-.144-.148-.343-.232-.196-.088-.428-.088-.236 0-.436.088-.195.084-.34.232-.143.148-.223.348-.08.196-.08.415 0 .22.08.416.08.196.224.344.144.144.34.232.195.083.427.083.232 0 .432-.083.2-.088.343-.236.144-.148.224-.344.084-.2.084-.42zm-.316.008q0 .168-.056.316-.056.144-.155.252-.1.108-.24.172-.14.06-.308.06-.164 0-.304-.064-.14-.064-.243-.172-.1-.108-.16-.256-.056-.148-.056-.316 0-.167.056-.311.056-.148.152-.256.1-.112.24-.172.14-.064.307-.064.164 0 .304.064.14.064.244.176.103.108.16.256.06.148.06.315z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M262.774 71.37q0-.143.044-.263.048-.12.132-.204.084-.088.196-.132.116-.048.248-.048.275 0 .423.168.148.168.148.456v1.206h.308v-1.282q0-.184-.056-.336-.052-.152-.156-.26-.1-.112-.248-.172-.144-.06-.331-.06-.268 0-.436.12-.168.116-.272.284v-.36h-.307v2.066h.307z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
          <path
            d="M265.26 72.553v-.42h-.368v.42z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fill="#000"
            fontFamily="Gotham"
          ></path>
        </g>
        <g
          ariaLabel="See a 50% reduction in infections and readmissions."
          style={{ lineHeight: "1.25" }}
          fill="#000"
          strokeWidth="0.249"
          fontFamily="Gotham"
          fontSize="3.424"
          transform="translate(0 -35)"
        >
          <path
            d="M236.905 99.712q0-.082.034-.154.038-.072.103-.123.068-.051.16-.082.096-.03.213-.03.185 0 .352.06.172.062.34.2l.157-.21q-.185-.147-.384-.219-.195-.075-.458-.075-.171 0-.315.051-.144.048-.25.137-.106.086-.165.206-.058.12-.058.263 0 .151.048.26.048.11.147.192.1.082.25.14.154.059.36.103.188.041.308.086.123.044.192.1.068.054.096.122.027.065.027.151 0 .185-.147.298-.144.113-.387.113-.25 0-.442-.082-.191-.086-.38-.257l-.168.199q.216.195.452.29.24.093.528.093.178 0 .328-.048.15-.048.257-.137.11-.089.171-.216.062-.126.062-.28 0-.278-.192-.435-.188-.158-.589-.247-.198-.04-.325-.085-.127-.048-.199-.103-.071-.058-.099-.127-.027-.068-.027-.154z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M239 100.722h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.171-.161-.298-.106-.127-.26-.199-.154-.075-.35-.075-.184 0-.342.072-.154.072-.267.199-.113.123-.178.294-.061.168-.061.36 0 .205.068.376.072.171.192.294.12.12.28.189.161.065.343.065.253 0 .424-.09.172-.092.305-.242l-.164-.148q-.11.113-.243.182-.13.068-.315.068-.117 0-.223-.037-.106-.041-.192-.117-.085-.078-.14-.188-.055-.113-.068-.257zm0-.195q.014-.13.062-.24.047-.109.123-.188.075-.082.174-.126.1-.048.216-.048.134 0 .233.051.103.048.171.13.072.082.11.192.04.11.05.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M241.027 100.722h1.403q.003-.024.003-.044v-.038q0-.195-.054-.366-.055-.171-.161-.298-.106-.127-.26-.199-.155-.075-.35-.075-.185 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.072.171.191.294.12.12.281.189.161.065.343.065.253 0 .424-.09.171-.092.305-.242l-.165-.148q-.11.113-.243.182-.13.068-.315.068-.116 0-.222-.037-.106-.041-.192-.117-.085-.078-.14-.188-.055-.113-.069-.257zm0-.195q.013-.13.061-.24.048-.109.124-.188.075-.082.174-.126.1-.048.216-.048.133 0 .233.051.102.048.17.13.073.082.11.192.041.11.052.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M244.563 99.949q.25 0 .387.123.14.12.14.36v.06q-.12-.033-.246-.054-.127-.02-.298-.02-.168 0-.308.037-.137.038-.24.11-.099.072-.154.181-.055.106-.055.247 0 .14.055.243.058.103.15.174.097.069.213.103.12.034.243.034.236 0 .39-.092.158-.092.25-.212v.267h.254v-1.082q0-.353-.195-.53-.196-.182-.558-.182-.196 0-.346.04-.15.039-.298.107l.079.216q.123-.058.25-.093.13-.037.287-.037zm.01.664q.165 0 .291.024.13.024.23.051v.171q0 .106-.048.195-.045.086-.127.151-.078.062-.188.1-.106.034-.23.034-.088 0-.17-.024-.08-.024-.14-.069-.063-.048-.1-.113-.034-.065-.034-.15 0-.172.133-.271.134-.1.384-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M246.977 100.28l.181.12q.106-.058.226-.095.123-.041.277-.041.134 0 .244.037.11.038.188.106.079.065.12.158.044.092.044.205 0 .117-.044.216-.041.096-.117.168-.075.072-.185.113-.106.037-.232.037-.185 0-.356-.085-.168-.09-.326-.243l-.178.195q.165.164.38.274.22.106.476.106.188 0 .346-.055.157-.058.27-.16.113-.107.175-.25.065-.148.065-.326 0-.171-.065-.308-.062-.137-.175-.23-.113-.095-.263-.147-.15-.051-.325-.051-.151 0-.26.03-.107.028-.213.076l.051-.767h1.144v-.25h-1.38z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M250.969 100.308q0-.253-.072-.476-.069-.226-.202-.393-.13-.172-.319-.268-.185-.099-.414-.099-.233 0-.421.1-.188.099-.322.27-.13.168-.202.394-.072.226-.072.479t.069.48q.072.222.202.393.133.168.318.267.188.096.421.096.23 0 .418-.1.188-.099.318-.267.134-.17.206-.397.072-.226.072-.479zm-.281.007q0 .199-.052.38-.047.178-.143.315-.093.134-.23.216-.133.078-.301.078-.168 0-.305-.078-.137-.082-.232-.22-.096-.136-.148-.318-.051-.181-.051-.38 0-.198.051-.376.052-.182.144-.315.096-.137.23-.216.136-.082.304-.082.168 0 .301.082.137.082.233.22.096.136.147.318.052.178.052.376z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M252.444 99.695q0-.123-.04-.233-.039-.11-.11-.191-.069-.086-.168-.134-.1-.051-.22-.051-.123 0-.225.051-.1.048-.171.134-.072.085-.113.198-.038.11-.038.233 0 .123.038.233.04.11.11.195.071.082.17.134.1.047.223.047t.222-.047q.1-.052.171-.137.072-.086.11-.195.041-.113.041-.237zm1.164-.582h-.243l-1.76 2.397h.247zm.247 1.808q0-.123-.038-.233-.038-.11-.11-.192-.068-.085-.167-.133-.1-.051-.223-.051t-.222.05q-.1.049-.172.134-.071.086-.113.199-.037.11-.037.233 0 .123.037.233.042.11.11.195.072.082.171.133.1.048.22.048.123 0 .222-.048.102-.051.171-.137.072-.085.11-.195.04-.113.04-.236zm-1.637-1.219q0 .09-.024.164-.02.076-.061.134-.042.055-.1.089-.058.03-.126.03-.069 0-.127-.03-.055-.034-.1-.089-.044-.058-.068-.134-.024-.078-.024-.17 0-.086.02-.162.025-.078.066-.133.04-.058.096-.09.058-.033.13-.033.065 0 .123.034.058.03.1.089.044.055.068.133.027.076.027.168zm1.41 1.226q0 .089-.023.164-.02.075-.062.134-.041.054-.1.089-.054.03-.126.03-.068 0-.127-.03-.054-.035-.099-.09-.044-.058-.068-.133-.024-.079-.024-.171 0-.086.02-.161.024-.079.065-.134.041-.058.1-.089.058-.034.126-.034.065 0 .123.034.059.031.103.09.045.054.069.133.024.075.024.168z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M255.621 100.805q0-.2.055-.35.055-.154.15-.256.097-.103.223-.155.13-.05.274-.05h.02v-.285q-.126-.003-.236.03-.11.035-.202.103-.092.066-.164.158-.072.092-.12.202v-.462h-.263v1.77h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M256.826 100.722h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.171-.16-.298-.107-.127-.261-.199-.154-.075-.35-.075-.184 0-.342.072-.154.072-.267.199-.113.123-.178.294-.061.168-.061.36 0 .205.068.376.072.171.192.294.12.12.28.189.162.065.343.065.253 0 .425-.09.17-.092.304-.242l-.164-.148q-.11.113-.243.182-.13.068-.315.068-.116 0-.223-.037-.106-.041-.191-.117-.086-.078-.14-.188-.055-.113-.07-.257zm0-.195q.014-.13.062-.24.048-.109.123-.188.075-.082.175-.126.099-.048.215-.048.134 0 .233.051.103.048.171.13.072.082.11.192.041.11.051.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M260.38 101.51v-2.5h-.264v1.069q-.051-.076-.12-.144-.065-.069-.147-.12-.082-.051-.185-.082-.102-.03-.226-.03-.16 0-.311.064-.15.062-.27.182-.12.116-.192.287-.072.171-.072.39 0 .22.072.39.072.172.191.292.12.116.27.178.151.061.312.061.124 0 .223-.03.103-.031.185-.086.082-.055.147-.127.069-.072.123-.15v.356zm-.253-.887q0 .154-.055.284-.052.127-.144.216-.089.089-.205.14-.113.048-.237.048-.126 0-.24-.048-.112-.048-.198-.137-.085-.092-.137-.219-.048-.126-.048-.284 0-.16.048-.288.052-.13.134-.215.085-.09.198-.134.113-.048.243-.048.124 0 .237.052.116.048.205.137.092.089.144.215.055.127.055.281z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M262.225 100.753q0 .123-.041.226-.041.103-.113.178-.068.072-.168.113-.096.038-.209.038-.236 0-.362-.144-.127-.144-.127-.39V99.74h-.264v1.099q0 .157.045.287.048.13.133.226.09.093.213.144.126.051.287.051.23 0 .373-.099.144-.103.233-.246v.308h.26v-1.77h-.26z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M264.598 101.208l-.168-.157q-.106.113-.24.188-.13.076-.301.076-.14 0-.26-.052-.117-.055-.206-.147-.085-.096-.137-.222-.047-.127-.047-.27 0-.145.047-.268.048-.127.134-.22.086-.095.202-.146.116-.055.25-.055.181 0 .305.079.126.078.232.188l.175-.185q-.062-.065-.134-.123-.071-.058-.157-.1-.086-.044-.188-.068-.103-.024-.23-.024-.195 0-.363.075-.164.072-.287.199-.12.127-.188.298-.069.168-.069.356 0 .188.069.356.068.168.188.294.123.124.287.199.168.072.363.072.25 0 .418-.096.171-.096.305-.246z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M265.388 99.973h.562v-.233h-.562v-.534h-.263v.534h-.247v.233h.247v1.068q0 .133.038.23.037.095.102.157.069.058.161.085.093.028.199.028.096 0 .17-.02.08-.018.148-.056v-.226q-.068.035-.13.048-.058.014-.127.014-.133 0-.215-.065-.083-.065-.083-.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M266.724 99.353v-.291h-.302v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M269.052 100.623q0-.188-.069-.356-.068-.168-.192-.291-.123-.127-.294-.199-.168-.075-.366-.075-.202 0-.373.075-.168.072-.291.199-.124.127-.192.298-.069.168-.069.356 0 .188.069.356.068.168.192.294.123.124.29.199.168.072.367.072.198 0 .37-.072.17-.075.294-.202.123-.127.192-.294.072-.172.072-.36zm-.27.007q0 .144-.049.27-.048.124-.133.216-.086.093-.206.147-.12.052-.263.052-.14 0-.26-.055t-.21-.147q-.085-.093-.136-.22-.048-.126-.048-.27 0-.144.048-.267.048-.127.13-.219.085-.096.205-.147.12-.055.264-.055.14 0 .26.055t.209.15q.089.093.137.22.051.126.051.27z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M269.791 100.496q0-.123.038-.226.04-.102.113-.174.072-.075.168-.113.099-.041.212-.041.236 0 .363.144.126.143.126.39v1.034h.264v-1.1q0-.157-.048-.287-.044-.13-.133-.222-.086-.096-.213-.148-.123-.05-.284-.05-.23 0-.373.102-.144.1-.233.243v-.308h-.263v1.77h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            stroke="#000"
          ></path>
          <path
            d="M272.972 99.353v-.291h-.302v.29zm-.02 2.157v-1.77h-.265v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M273.827 100.496q0-.123.038-.226.041-.102.113-.174.072-.075.168-.113.1-.041.212-.041.236 0 .363.144.127.143.127.39v1.034h.263v-1.1q0-.157-.048-.287-.044-.13-.133-.222-.086-.096-.212-.148-.124-.05-.285-.05-.229 0-.373.102-.143.1-.232.243v-.308h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M236.96 103.753v-.29h-.302v.29zm-.02 2.157v-1.77h-.265v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M237.815 104.897q0-.123.038-.226.041-.103.113-.175.072-.075.168-.113.1-.04.212-.04.236 0 .363.143.127.144.127.39v1.034h.263v-1.099q0-.157-.048-.287-.044-.13-.133-.223-.086-.096-.212-.147-.124-.052-.285-.052-.229 0-.373.103-.143.1-.232.243v-.308h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M240.02 104.147v-.144q0-.376.308-.376.069 0 .127.013.058.01.13.031v-.23q-.065-.023-.133-.033-.065-.01-.158-.01-.257 0-.397.15-.137.15-.137.442v.154h-.243v.229h.243v1.537h.264v-1.537h.558v-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M241.04 105.123h1.404q.003-.024.003-.045v-.037q0-.196-.055-.367-.055-.17-.16-.298-.107-.126-.26-.198-.155-.075-.35-.075-.185 0-.342.071-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.068.376.072.172.192.295.12.12.28.188.162.065.343.065.254 0 .425-.089.17-.092.304-.243l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.107-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.195q.014-.13.062-.24.048-.11.123-.188.076-.083.175-.127.1-.048.216-.048.133 0 .232.052.103.047.172.13.071.082.11.191.04.11.05.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M244.43 105.609l-.168-.158q-.106.113-.24.189-.13.075-.301.075-.14 0-.26-.051-.117-.055-.206-.148-.085-.095-.137-.222-.047-.127-.047-.27 0-.144.047-.268.048-.126.134-.219.086-.096.202-.147.116-.055.25-.055.181 0 .305.079.126.079.232.188l.175-.185q-.062-.065-.134-.123-.071-.058-.157-.1-.086-.044-.188-.068-.103-.024-.23-.024-.195 0-.363.076-.164.072-.287.198-.12.127-.188.298-.069.168-.069.356 0 .188.069.356.068.168.188.295.123.123.287.198.168.072.363.072.25 0 .418-.096.171-.096.305-.246z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M245.22 104.373h.562v-.233h-.561v-.534h-.264v.534h-.247v.233h.247v1.068q0 .134.038.23.037.095.102.157.069.058.161.086.093.027.199.027.096 0 .171-.02.079-.018.147-.055v-.226q-.068.034-.13.048-.058.013-.127.013-.133 0-.215-.065-.082-.065-.082-.229z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M246.556 103.753v-.29h-.302v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M248.884 105.023q0-.188-.069-.356-.068-.167-.192-.29-.123-.127-.294-.2-.168-.074-.366-.074-.202 0-.373.075-.168.072-.291.198-.124.127-.192.298-.069.168-.069.356 0 .189.069.356.068.168.192.295.123.123.29.198.168.072.367.072.198 0 .37-.072.17-.075.294-.202.123-.126.192-.294.072-.171.072-.36zm-.27.007q0 .144-.049.27-.048.124-.133.217-.086.092-.206.147-.12.051-.263.051-.14 0-.26-.055-.12-.054-.21-.147-.085-.092-.136-.219-.048-.127-.048-.27 0-.144.048-.267.048-.127.13-.22.086-.096.205-.147.12-.055.264-.055.14 0 .26.055t.209.15q.089.093.137.22.051.127.051.27z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M249.623 104.897q0-.123.038-.226.041-.103.113-.175.072-.075.168-.113.099-.04.212-.04.236 0 .363.143.126.144.126.39v1.034h.264v-1.099q0-.157-.048-.287-.044-.13-.133-.223-.086-.096-.213-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-.308h-.263v1.77h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M252.684 105.414v-.007q0-.12-.052-.202-.047-.082-.13-.14-.082-.059-.184-.097-.1-.037-.202-.068-.09-.027-.172-.055-.082-.027-.147-.061-.061-.035-.1-.08-.037-.047-.037-.109v-.007q0-.11.093-.181.092-.075.25-.075.13 0 .263.048.137.044.26.123l.12-.199q-.137-.089-.305-.14-.167-.055-.332-.055-.13 0-.243.038-.11.034-.191.102-.08.065-.124.161-.044.093-.044.206v.007q0 .123.051.205.055.082.137.137.086.055.188.092.106.035.21.065.085.024.163.052.083.027.144.065.062.034.096.082.038.048.038.113v.007q0 .13-.103.205-.103.076-.264.076-.157 0-.315-.058-.154-.059-.297-.168l-.134.188q.15.12.346.192.195.068.387.068.133 0 .246-.034.117-.038.199-.106.085-.069.133-.164.052-.1.052-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M254.84 104.349q.25 0 .387.123.14.12.14.36v.061q-.119-.034-.246-.054-.126-.021-.298-.021-.167 0-.308.038-.137.037-.24.11-.099.071-.153.18-.055.107-.055.247t.055.243q.058.103.15.175.096.068.213.103.12.034.243.034.236 0 .39-.093.157-.092.25-.212v.267h.253v-1.082q0-.352-.195-.53-.195-.182-.558-.182-.195 0-.346.041-.15.038-.298.107l.08.215q.122-.058.249-.092.13-.038.288-.038zm.01.664q.165 0 .292.024.13.024.23.051v.172q0 .106-.049.195-.044.085-.126.15-.079.062-.189.1-.106.034-.229.034-.089 0-.171-.024-.079-.024-.14-.069-.062-.047-.1-.113-.034-.065-.034-.15 0-.171.134-.27.133-.1.383-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M256.446 104.897q0-.123.038-.226.041-.103.113-.175.072-.075.168-.113.099-.04.212-.04.236 0 .363.143.126.144.126.39v1.034h.264v-1.099q0-.157-.048-.287-.044-.13-.133-.223-.086-.096-.213-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-.308h-.263v1.77h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M259.976 105.91v-2.499h-.264v1.068q-.051-.075-.12-.144-.065-.068-.147-.12-.082-.05-.185-.082-.102-.03-.226-.03-.16 0-.311.065-.15.061-.27.181-.12.116-.192.288-.072.17-.072.39 0 .219.072.39t.191.291q.12.116.27.178.152.062.312.062.124 0 .223-.031.103-.03.185-.086.082-.054.147-.126.069-.072.123-.151v.356zm-.253-.887q0 .155-.055.285-.052.126-.144.215-.089.09-.205.14-.113.049-.237.049-.126 0-.24-.048-.112-.048-.198-.137-.085-.093-.137-.22-.048-.126-.048-.284 0-.16.048-.287.052-.13.134-.216.085-.089.198-.133.113-.048.243-.048.124 0 .237.051.116.048.205.137.092.089.144.216.055.126.055.28z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M261.855 105.205q0-.199.055-.35.055-.153.15-.256.097-.103.223-.154.13-.051.274-.051h.02v-.285q-.126-.003-.235.031-.11.034-.202.103-.093.065-.165.157-.072.093-.12.202v-.462h-.263v1.77h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M263.06 105.123h1.404q.003-.024.003-.045v-.037q0-.196-.055-.367-.054-.17-.16-.298-.107-.126-.26-.198-.155-.075-.35-.075-.185 0-.342.071-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.071.172.191.295.12.12.281.188.16.065.342.065.254 0 .425-.089.171-.092.305-.243l-.165-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.106-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.195q.014-.13.062-.24.048-.11.123-.188.076-.083.175-.127.1-.048.216-.048.133 0 .232.052.103.047.172.13.071.082.11.191.04.11.05.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M265.57 104.349q.25 0 .387.123.14.12.14.36v.061q-.12-.034-.246-.054-.127-.021-.298-.021-.168 0-.308.038-.137.037-.24.11-.1.071-.154.18-.055.107-.055.247t.055.243q.058.103.15.175.097.068.213.103.12.034.243.034.236 0 .39-.093.158-.092.25-.212v.267h.253v-1.082q0-.352-.195-.53-.195-.182-.558-.182-.195 0-.345.041-.151.038-.298.107l.078.215q.124-.058.25-.092.13-.038.288-.038zm.01.664q.165 0 .291.024.13.024.23.051v.172q0 .106-.048.195-.045.085-.127.15-.079.062-.188.1-.106.034-.23.034-.089 0-.17-.024-.08-.024-.141-.069-.062-.047-.1-.113-.034-.065-.034-.15 0-.171.134-.27.133-.1.383-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M268.596 105.91v-2.499h-.263v1.068q-.052-.075-.12-.144-.065-.068-.147-.12-.083-.05-.185-.082-.103-.03-.226-.03-.161 0-.312.065-.15.061-.27.181-.12.116-.192.288-.072.17-.072.39 0 .219.072.39t.192.291q.12.116.27.178.15.062.312.062.123 0 .222-.031.103-.03.185-.086.082-.054.147-.126.069-.072.124-.151v.356zm-.253-.887q0 .155-.055.285-.051.126-.144.215-.089.09-.205.14-.113.049-.236.049-.127 0-.24-.048-.113-.048-.198-.137-.086-.093-.137-.22-.048-.126-.048-.284 0-.16.048-.287.051-.13.133-.216.086-.089.199-.133.113-.048.243-.048.123 0 .236.051.116.048.205.137.093.089.144.216.055.126.055.28z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M270.589 104.455q-.072-.15-.212-.25-.14-.102-.357-.102-.112 0-.198.027-.086.027-.154.075-.069.045-.123.106-.052.062-.096.127v-.298h-.264v1.77h.264v-1.01q0-.123.037-.222.038-.103.103-.178.069-.076.158-.117.089-.04.195-.04.212 0 .332.14.123.136.123.386v1.041h.26v-1.02q0-.13.038-.233.041-.103.106-.171.069-.072.158-.106.089-.038.188-.038.216 0 .335.14.124.137.124.394v1.034h.263v-1.095q0-.333-.174-.52-.175-.193-.483-.193-.116 0-.212.028-.093.027-.168.075-.075.048-.137.113-.058.065-.106.137z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M272.749 103.753v-.29h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M274.557 105.414v-.007q0-.12-.052-.202-.048-.082-.13-.14-.082-.059-.185-.097-.099-.037-.202-.068-.089-.027-.17-.055-.083-.027-.148-.061-.062-.035-.1-.08-.037-.047-.037-.109v-.007q0-.11.092-.181.093-.075.25-.075.13 0 .264.048.137.044.26.123l.12-.199q-.137-.089-.305-.14-.168-.055-.332-.055-.13 0-.243.038-.11.034-.192.102-.078.065-.123.161-.044.093-.044.206v.007q0 .123.05.205.056.082.138.137.085.055.188.092.106.035.209.065.086.024.164.052.082.027.144.065.062.034.096.082.038.048.038.113v.007q0 .13-.103.205-.103.076-.264.076-.157 0-.315-.058-.154-.059-.297-.168l-.134.188q.15.12.346.192.195.068.387.068.133 0 .246-.034.117-.038.199-.106.085-.069.133-.164.052-.1.052-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M276.227 105.414v-.007q0-.12-.051-.202-.048-.082-.13-.14-.082-.059-.185-.097-.1-.037-.202-.068-.089-.027-.171-.055-.082-.027-.147-.061-.062-.035-.1-.08-.037-.047-.037-.109v-.007q0-.11.092-.181.092-.075.25-.075.13 0 .264.048.136.044.26.123l.12-.199q-.137-.089-.305-.14-.168-.055-.332-.055-.13 0-.243.038-.11.034-.192.102-.079.065-.123.161-.045.093-.045.206v.007q0 .123.052.205.054.082.137.137.085.055.188.092.106.035.209.065.085.024.164.052.082.027.144.065.061.034.096.082.037.048.037.113v.007q0 .13-.102.205-.103.076-.264.076-.157 0-.315-.058-.154-.059-.298-.168l-.133.188q.15.12.346.192.195.068.386.068.134 0 .247-.034.116-.038.198-.106.086-.069.134-.164.051-.1.051-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M277.025 103.753v-.29h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M279.353 105.023q0-.188-.069-.356-.068-.167-.191-.29-.123-.127-.295-.2-.167-.074-.366-.074-.202 0-.373.075-.168.072-.291.198-.123.127-.192.298-.068.168-.068.356 0 .189.068.356.069.168.192.295.123.123.29.198.169.072.367.072.199 0 .37-.072.171-.075.294-.202.124-.126.192-.294.072-.171.072-.36zm-.27.007q0 .144-.048.27-.048.124-.134.217-.085.092-.205.147-.12.051-.264.051-.14 0-.26-.055-.12-.054-.209-.147-.086-.092-.137-.219-.048-.127-.048-.27 0-.144.048-.267.048-.127.13-.22.086-.096.206-.147.12-.055.263-.055.14 0 .26.055t.21.15q.088.093.136.22.052.127.052.27z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M280.092 104.897q0-.123.038-.226.041-.103.113-.175.072-.075.168-.113.1-.04.212-.04.236 0 .363.143.127.144.127.39v1.034h.263v-1.099q0-.157-.048-.287-.044-.13-.133-.223-.086-.096-.212-.147-.124-.052-.284-.052-.23 0-.374.103-.143.1-.232.243v-.308h-.264v1.77h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M283.153 105.414v-.007q0-.12-.051-.202-.048-.082-.13-.14-.083-.059-.185-.097-.1-.037-.202-.068-.09-.027-.171-.055-.083-.027-.148-.061-.061-.035-.099-.08-.038-.047-.038-.109v-.007q0-.11.093-.181.092-.075.25-.075.13 0 .263.048.137.044.26.123l.12-.199q-.137-.089-.304-.14-.168-.055-.332-.055-.13 0-.244.038-.11.034-.191.102-.079.065-.123.161-.045.093-.045.206v.007q0 .123.051.205.055.082.137.137.086.055.189.092.106.035.208.065.086.024.165.052.082.027.143.065.062.034.096.082.038.048.038.113v.007q0 .13-.103.205-.102.076-.263.076-.158 0-.315-.058-.154-.059-.298-.168l-.134.188q.151.12.346.192.195.068.387.068.134 0 .246-.034.117-.038.199-.106.086-.069.134-.164.051-.1.051-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
          <path
            d="M283.927 105.91v-.36h-.315v.36z"
            style={{ InkscapeFontSpecification: "Gotham" }}
          ></path>
        </g>
        <g
          ariaLabel="Send 33% more patients home without assistance."
          style={{ lineHeight: "1.25" }}
          fill="#000"
          strokeWidth="0.249"
          fontSize="3.424"
          transform="translate(0 -35)"
        >
          <path d="M252.227 124.948q0-.082.034-.154.038-.072.103-.123.069-.051.16-.082.097-.03.213-.03.185 0 .353.06.171.062.339.2l.157-.21q-.185-.147-.383-.219-.195-.075-.459-.075-.171 0-.315.051-.144.048-.25.137-.106.086-.164.206-.058.12-.058.263 0 .15.048.26.047.11.147.192.099.082.25.14.154.059.36.103.187.041.307.086.123.044.192.1.068.054.096.122.027.065.027.151 0 .185-.147.298-.144.113-.387.113-.25 0-.441-.082-.192-.086-.38-.257l-.168.198q.215.196.452.291.24.093.527.093.178 0 .329-.048.15-.048.256-.137.11-.089.172-.216.061-.126.061-.28 0-.278-.192-.435-.188-.158-.588-.247-.199-.04-.326-.085-.126-.048-.198-.103-.072-.058-.1-.127-.027-.068-.027-.154z"></path>
          <path d="M254.322 125.958h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.171-.16-.298-.107-.127-.26-.199-.155-.075-.35-.075-.185 0-.342.072-.155.072-.268.199-.112.123-.178.294-.061.168-.061.36 0 .205.068.376.072.171.192.295.12.12.28.188.162.065.343.065.254 0 .425-.09.17-.092.304-.242l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.223-.038-.106-.041-.191-.117-.086-.078-.14-.188-.055-.113-.07-.257zm0-.195q.014-.13.062-.24.048-.109.123-.188.075-.082.175-.126.1-.048.215-.048.134 0 .233.051.103.048.171.13.072.082.11.192.041.11.051.23z"></path>
          <path d="M256.455 125.732q0-.123.038-.226.041-.102.113-.174.072-.075.168-.113.099-.041.212-.041.236 0 .363.144.126.143.126.39v1.034h.264v-1.1q0-.157-.048-.287-.044-.13-.133-.222-.086-.096-.213-.148-.123-.05-.284-.05-.23 0-.373.102-.144.1-.233.243v-.308h-.263v1.77h.263z"></path>
          <path d="M259.985 126.746v-2.5h-.264v1.069q-.051-.076-.12-.144-.065-.069-.147-.12-.082-.051-.185-.082-.102-.03-.226-.03-.16 0-.311.064-.15.062-.27.182-.12.116-.192.287-.072.171-.072.39 0 .22.072.39.072.172.191.292.12.116.27.178.151.061.312.061.124 0 .223-.03.103-.031.185-.086.082-.055.147-.127.068-.072.123-.15v.356zm-.254-.887q0 .154-.054.284-.052.127-.144.216-.09.089-.206.14-.112.048-.236.048-.126 0-.24-.048-.112-.048-.198-.137-.086-.092-.137-.219-.048-.126-.048-.284 0-.16.048-.288.052-.13.134-.215.085-.09.198-.134.113-.048.243-.048.124 0 .236.052.117.048.206.137.092.089.144.215.054.127.054.281z"></path>
          <path
            stroke="#000"
            d="M262.775 124.592l-.757.853.055.16h.154q.298 0 .483.12.188.12.188.346 0 .106-.04.195-.042.086-.114.148-.068.061-.164.096-.096.03-.206.03-.222 0-.393-.092-.168-.092-.308-.264l-.196.175q.148.192.37.311.223.117.524.117.168 0 .312-.051.147-.055.253-.151.11-.096.171-.23.065-.133.065-.294 0-.164-.065-.287-.065-.124-.175-.206-.106-.082-.25-.127-.143-.044-.3-.058l.752-.835v-.199h-1.53v.243z"
          ></path>
          <path
            stroke="#000"
            d="M264.874 124.592l-.757.853.055.16h.154q.298 0 .483.12.188.12.188.346 0 .106-.041.195-.041.086-.113.148-.069.061-.165.096-.095.03-.205.03-.222 0-.394-.092-.167-.092-.308-.264l-.195.175q.147.192.37.311.222.117.524.117.167 0 .311-.051.147-.055.254-.151.11-.096.17-.23.066-.133.066-.294 0-.164-.065-.287-.065-.124-.175-.206-.106-.082-.25-.127-.144-.044-.301-.058l.753-.835v-.199h-1.53v.243z"
          ></path>
          <path
            stroke="#000"
            d="M266.767 124.931q0-.123-.041-.233-.038-.11-.11-.191-.068-.086-.168-.134-.099-.051-.219-.051-.123 0-.226.051-.099.048-.17.134-.073.085-.114.198-.037.11-.037.233 0 .123.037.233.041.11.11.195.072.082.17.134.1.047.223.047.124 0 .223-.047.1-.052.171-.137.072-.086.11-.196.04-.112.04-.236zm1.164-.582h-.243l-1.76 2.397h.247zm.246 1.808q0-.123-.037-.233-.038-.11-.11-.192-.068-.085-.168-.133-.099-.051-.222-.051t-.223.05q-.1.049-.17.134-.073.086-.114.199-.038.11-.038.233 0 .123.038.233.041.11.11.195.072.082.17.133.1.048.22.048.123 0 .222-.048.103-.051.172-.137.072-.085.11-.195.04-.113.04-.236zm-1.636-1.219q0 .09-.024.164-.02.076-.062.134-.04.055-.099.089-.058.03-.127.03-.068 0-.126-.03-.055-.034-.1-.09-.044-.057-.068-.133-.024-.078-.024-.17 0-.086.02-.162.024-.078.065-.133.041-.058.096-.09.058-.033.13-.033.065 0 .124.034.058.03.099.089.044.055.068.133.028.076.028.168zm1.41 1.226q0 .089-.024.164-.02.075-.061.134-.041.054-.1.089-.054.03-.126.03-.069 0-.127-.03-.055-.035-.1-.09-.044-.058-.068-.133-.024-.079-.024-.171 0-.086.02-.161.025-.079.066-.134.04-.058.1-.089.057-.034.126-.034.065 0 .123.034.058.031.103.09.044.054.068.133.024.075.024.168z"
          ></path>
          <path
            stroke="#000"
            d="M271.084 125.29q-.072-.15-.212-.25-.14-.102-.356-.102-.113 0-.199.027-.086.028-.154.076-.069.044-.123.106-.052.062-.096.127v-.298h-.264v1.77h.264v-1.01q0-.123.038-.223.037-.102.102-.178.069-.075.158-.116.089-.041.195-.041.212 0 .332.14.123.137.123.387v1.04h.26v-1.02q0-.13.038-.232.041-.103.106-.171.069-.072.158-.107.089-.037.188-.037.216 0 .335.14.124.137.124.394v1.034h.263v-1.096q0-.332-.174-.52-.175-.192-.483-.192-.116 0-.212.027-.093.028-.168.076-.075.048-.137.113-.058.065-.106.137z"
          ></path>
          <path
            stroke="#000"
            d="M274.672 125.859q0-.188-.069-.356-.068-.168-.191-.291-.124-.127-.295-.199-.168-.075-.366-.075-.202 0-.373.075-.168.072-.291.199-.124.127-.192.298-.069.168-.069.356 0 .188.069.356.068.168.192.294.123.124.29.199.168.072.367.072.198 0 .37-.072.17-.075.294-.202.123-.127.192-.294.072-.172.072-.36zm-.27.007q0 .144-.049.27-.048.124-.133.216-.086.093-.206.147-.12.052-.263.052-.14 0-.26-.055t-.21-.147q-.085-.093-.136-.22-.048-.126-.048-.27 0-.144.048-.267.048-.127.13-.219.086-.096.205-.147.12-.055.264-.055.14 0 .26.055t.209.15q.089.093.137.22.051.126.051.27z"
          ></path>
          <path
            stroke="#000"
            d="M275.411 126.04q0-.198.055-.349.055-.154.15-.256.097-.103.223-.155.13-.05.274-.05h.02v-.285q-.126-.003-.236.03-.11.035-.202.104-.092.065-.164.157-.072.092-.12.202v-.462h-.263v1.77h.263z"
          ></path>
          <path
            stroke="#000"
            d="M276.616 125.958h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.171-.16-.298-.107-.127-.261-.199-.154-.075-.35-.075-.184 0-.342.072-.154.072-.267.199-.113.123-.178.294-.061.168-.061.36 0 .205.068.376.072.171.192.295.12.12.28.188.162.065.343.065.253 0 .425-.09.17-.092.304-.242l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.223-.038-.106-.041-.191-.117-.086-.078-.14-.188-.055-.113-.07-.257zm0-.195q.014-.13.062-.24.048-.109.123-.188.075-.082.175-.126.099-.048.215-.048.134 0 .233.051.103.048.171.13.072.082.11.192.041.11.051.23z"
          ></path>
          <path
            stroke="#000"
            d="M281.3 125.859q0-.22-.072-.39-.072-.171-.192-.288-.12-.12-.274-.181-.15-.062-.308-.062-.123 0-.226.034-.1.031-.181.086-.083.051-.151.123-.065.072-.12.15v-.355h-.263v2.317h.263v-.886q.051.075.117.144.068.068.15.12.082.05.185.082.103.03.226.03.16 0 .312-.061.154-.065.27-.182.12-.12.192-.29.072-.172.072-.39zm-.27.003q0 .16-.052.291-.048.127-.134.216-.082.085-.198.133-.113.045-.24.045-.123 0-.24-.048-.113-.052-.205-.14-.089-.09-.144-.216-.051-.13-.051-.284 0-.154.051-.281.055-.127.144-.216.092-.089.205-.137.117-.051.24-.051.127 0 .24.048.113.048.198.14.086.09.134.216.051.127.051.284z"
          ></path>
          <path
            stroke="#000"
            d="M282.426 125.185q.25 0 .387.123.14.12.14.36v.06q-.12-.033-.246-.054-.127-.02-.298-.02-.168 0-.308.037-.137.038-.24.11-.1.072-.154.181-.055.106-.055.247 0 .14.055.243.058.103.15.174.097.069.213.103.12.034.243.034.236 0 .39-.092.158-.092.25-.212v.267h.254v-1.082q0-.353-.196-.53-.195-.182-.558-.182-.195 0-.345.04-.151.039-.298.107l.078.216q.124-.058.25-.093.13-.037.288-.037zm.01.664q.165 0 .291.024.13.024.23.051v.171q0 .106-.048.195-.045.086-.127.151-.079.062-.188.1-.106.034-.23.034-.089 0-.17-.024-.08-.024-.141-.069-.062-.048-.1-.113-.034-.065-.034-.15 0-.172.134-.271.133-.1.383-.1z"
          ></path>
          <path
            stroke="#000"
            d="M284.107 125.209h.561v-.233h-.561v-.534h-.264v.534h-.246v.233h.246v1.068q0 .133.038.23.038.095.103.157.068.058.16.085.093.028.2.028.095 0 .17-.02.08-.018.148-.056v-.226q-.069.035-.13.048-.059.014-.127.014-.134 0-.216-.065-.082-.065-.082-.23z"
          ></path>
          <path
            stroke="#000"
            d="M285.442 124.589v-.291h-.301v.29zm-.02 2.157v-1.77h-.264v1.77z"
          ></path>
          <path
            stroke="#000"
            d="M286.192 125.958h1.404q.003-.024.003-.044v-.038q0-.195-.055-.366-.055-.171-.161-.298-.106-.127-.26-.199-.154-.075-.35-.075-.184 0-.342.072-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.072.171.192.295.12.12.28.188.161.065.343.065.253 0 .424-.09.171-.092.305-.242l-.164-.147q-.11.113-.243.181-.13.069-.315.069-.117 0-.223-.038-.106-.041-.192-.117-.085-.078-.14-.188-.055-.113-.069-.257zm0-.195q.014-.13.061-.24.048-.109.124-.188.075-.082.174-.126.1-.048.216-.048.134 0 .233.051.103.048.171.13.072.082.11.192.04.11.05.23z"
          ></path>
          <path
            stroke="#000"
            d="M288.325 125.732q0-.123.037-.226.042-.102.113-.174.072-.075.168-.113.1-.041.212-.041.237 0 .363.144.127.143.127.39v1.034h.264v-1.1q0-.157-.048-.287-.045-.13-.134-.222-.085-.096-.212-.148-.123-.05-.284-.05-.23 0-.373.102-.144.1-.233.243v-.308h-.264v1.77h.264z"
          ></path>
          <path
            stroke="#000"
            d="M290.526 125.209h.562v-.233h-.562v-.534h-.264v.534h-.246v.233h.246v1.068q0 .133.038.23.038.095.103.157.068.058.16.085.093.028.2.028.095 0 .17-.02.08-.018.148-.056v-.226q-.069.035-.13.048-.059.014-.127.014-.134 0-.216-.065-.082-.065-.082-.23z"
          ></path>
          <path
            stroke="#000"
            d="M292.768 126.25v-.008q0-.12-.05-.202-.049-.082-.131-.14-.082-.058-.185-.096-.1-.038-.202-.068l-.171-.055q-.082-.028-.147-.062-.062-.034-.1-.079-.037-.047-.037-.11v-.006q0-.11.092-.182.093-.075.25-.075.13 0 .264.048.137.045.26.123l.12-.198q-.137-.09-.305-.14-.168-.055-.332-.055-.13 0-.243.037-.11.034-.192.103-.079.065-.123.16-.045.093-.045.206v.007q0 .124.052.206.055.082.137.137.085.054.188.092l.209.065q.085.024.164.051.082.028.144.065.062.035.096.083.038.047.038.113v.007q0 .13-.103.205-.103.075-.264.075-.157 0-.315-.058-.154-.058-.298-.168l-.133.189q.15.12.346.191.195.069.387.069.133 0 .246-.034.116-.038.199-.107.085-.068.133-.164.051-.1.051-.226z"
          ></path>
          <path
            d="M252.237 130.133q0-.123.038-.226.041-.103.113-.175.072-.075.168-.113.1-.04.212-.04.236 0 .363.143.127.144.127.39v1.034h.263v-1.099q0-.157-.048-.287-.044-.13-.133-.223-.086-.096-.212-.147-.124-.052-.285-.052-.229 0-.373.103-.144.1-.233.243v-1.037h-.263v2.5h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M255.818 130.26q0-.189-.068-.357-.069-.167-.192-.29-.123-.127-.294-.2-.168-.074-.367-.074-.202 0-.373.075-.167.072-.29.198-.124.127-.192.298-.069.168-.069.356 0 .189.069.356.068.168.191.295.124.123.291.198.168.072.367.072.198 0 .37-.072.17-.075.294-.202.123-.126.191-.294.072-.171.072-.36zm-.27.006q0 .144-.048.27-.048.124-.133.217-.086.092-.206.147-.12.051-.264.051-.14 0-.26-.055-.12-.054-.209-.147-.085-.092-.136-.219-.048-.127-.048-.27 0-.144.048-.268.047-.126.13-.219.085-.096.205-.147.12-.055.264-.055.14 0 .26.055t.209.15q.089.093.137.22.051.127.051.27z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M257.698 129.691q-.072-.15-.212-.25-.14-.102-.356-.102-.113 0-.199.027-.086.027-.154.075-.069.045-.123.106-.052.062-.096.127v-.298h-.264v1.77h.264v-1.01q0-.123.038-.222.037-.103.102-.178.069-.076.158-.117.089-.04.195-.04.212 0 .332.14.123.136.123.386v1.041h.26v-1.02q0-.13.038-.233.041-.103.106-.171.069-.072.158-.106.089-.038.188-.038.216 0 .335.14.124.137.124.394v1.034h.263v-1.095q0-.333-.174-.52-.175-.193-.483-.193-.116 0-.212.028-.093.027-.168.075-.075.048-.137.113-.058.065-.106.137z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M259.708 130.359h1.403q.003-.024.003-.045v-.037q0-.196-.055-.367-.054-.171-.16-.298-.107-.126-.26-.198-.155-.075-.35-.075-.185 0-.342.071-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.071.172.191.295.12.12.281.188.16.065.342.065.254 0 .425-.089.171-.092.305-.243l-.165-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.106-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.195q.013-.13.061-.24.048-.11.123-.188.076-.083.175-.127.1-.048.216-.048.133 0 .232.051.103.048.172.13.072.083.11.192.04.11.05.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M263.894 129.37h-.219l-.475 1.427-.466-1.42h-.28l.619 1.783h.233l.479-1.397.476 1.397h.23l.622-1.784h-.274l-.465 1.42z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M265.86 128.99v-.292h-.302v.291zm-.021 2.156v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M266.808 129.609h.561v-.233h-.561v-.534h-.264v.534h-.246v.233h.246v1.068q0 .134.038.23.038.095.103.157.068.058.16.086.093.027.2.027.095 0 .17-.02.079-.018.148-.055v-.226q-.069.034-.13.048-.059.013-.127.013-.134 0-.216-.065-.082-.065-.082-.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M268.099 130.133q0-.123.037-.226.041-.103.113-.175.072-.075.168-.113.1-.04.212-.04.236 0 .363.143.127.144.127.39v1.034h.263v-1.099q0-.157-.047-.287-.045-.13-.134-.223-.086-.096-.212-.147-.123-.052-.284-.052-.23 0-.374.103-.143.1-.232.243v-1.037h-.264v2.5h.264z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M271.68 130.26q0-.189-.069-.357-.068-.167-.192-.29-.123-.127-.294-.2-.168-.074-.366-.074-.202 0-.374.075-.167.072-.29.198-.124.127-.192.298-.069.168-.069.356 0 .189.069.356.068.168.191.295.124.123.291.198.168.072.367.072.198 0 .37-.072.17-.075.294-.202.123-.126.192-.294.072-.171.072-.36zm-.27.006q0 .144-.049.27-.048.124-.133.217-.086.092-.206.147-.12.051-.263.051-.14 0-.26-.055-.12-.054-.21-.147-.085-.092-.136-.219-.048-.127-.048-.27 0-.144.048-.268.048-.126.13-.219.085-.096.205-.147.12-.055.264-.055.14 0 .26.055t.209.15q.089.093.137.22.051.127.051.27z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M273.412 130.39q0 .123-.041.225-.041.103-.113.179-.069.071-.168.112-.096.038-.209.038-.236 0-.363-.144-.126-.143-.126-.39v-1.034h-.264v1.1q0 .157.045.287.047.13.133.226.09.092.212.143.127.052.288.052.23 0 .373-.1.144-.102.233-.246v.308h.26v-1.77h-.26z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M274.62 129.609h.562v-.233h-.562v-.534h-.263v.534h-.247v.233h.247v1.068q0 .134.037.23.038.095.103.157.069.058.161.086.092.027.199.027.095 0 .17-.02.08-.018.148-.055v-.226q-.069.034-.13.048-.058.013-.127.013-.133 0-.216-.065-.082-.065-.082-.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M277.315 129.585q.25 0 .387.123.14.12.14.36v.061q-.12-.034-.247-.054-.126-.021-.297-.021-.168 0-.309.038-.136.037-.24.11-.099.071-.153.18-.055.107-.055.247t.055.243q.058.103.15.175.096.068.213.103.12.034.243.034.236 0 .39-.093.157-.092.25-.212v.267h.253v-1.082q0-.352-.195-.53-.195-.182-.558-.182-.195 0-.346.041-.15.038-.298.106l.08.216q.122-.058.25-.092.13-.038.287-.038zm.01.664q.164 0 .291.024.13.024.23.052v.17q0 .107-.049.196-.044.085-.126.15-.079.062-.189.1-.106.034-.229.034-.089 0-.171-.024-.079-.024-.14-.068-.062-.048-.1-.113-.034-.065-.034-.151 0-.171.134-.27.133-.1.383-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M279.872 130.65v-.007q0-.12-.051-.202-.048-.082-.13-.14-.083-.059-.185-.097-.1-.037-.202-.068-.09-.027-.171-.055-.083-.027-.148-.061-.061-.035-.099-.08-.038-.047-.038-.109v-.007q0-.11.093-.181.092-.075.25-.075.13 0 .263.047.137.045.26.124l.12-.199q-.137-.089-.304-.14-.168-.055-.333-.055-.13 0-.243.038-.11.034-.191.102-.079.065-.124.161-.044.093-.044.206v.007q0 .123.051.205.055.082.137.137.086.055.188.092.107.035.21.066.085.024.164.05.082.028.143.066.062.034.096.082.038.048.038.113v.007q0 .13-.103.206-.102.075-.263.075-.158 0-.315-.058-.154-.059-.298-.168l-.134.188q.151.12.346.192.195.068.387.068.134 0 .246-.034.117-.037.2-.106.085-.068.133-.164.051-.1.051-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M281.543 130.65v-.007q0-.12-.052-.202-.047-.082-.13-.14-.082-.059-.185-.097-.099-.037-.202-.068-.089-.027-.17-.055-.083-.027-.148-.061-.062-.035-.1-.08-.037-.047-.037-.109v-.007q0-.11.093-.181.092-.075.25-.075.13 0 .263.047.137.045.26.124l.12-.199q-.137-.089-.305-.14-.167-.055-.332-.055-.13 0-.243.038-.11.034-.191.102-.08.065-.124.161-.044.093-.044.206v.007q0 .123.051.205.055.082.137.137.086.055.188.092.107.035.21.066.085.024.164.05.082.028.143.066.062.034.096.082.038.048.038.113v.007q0 .13-.103.206-.102.075-.263.075-.158 0-.315-.058-.154-.059-.298-.168l-.134.188q.151.12.346.192.195.068.387.068.133 0 .246-.034.117-.037.199-.106.086-.068.134-.164.05-.1.05-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M282.34 128.99v-.292h-.3v.291zm-.02 2.156v-1.77h-.264v1.77z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M284.148 130.65v-.007q0-.12-.051-.202-.048-.082-.13-.14-.083-.059-.185-.097-.1-.037-.202-.068-.09-.027-.172-.055-.082-.027-.147-.061-.061-.035-.099-.08-.038-.047-.038-.109v-.007q0-.11.093-.181.092-.075.25-.075.13 0 .263.047.137.045.26.124l.12-.199q-.137-.089-.304-.14-.168-.055-.333-.055-.13 0-.243.038-.11.034-.191.102-.08.065-.124.161-.044.093-.044.206v.007q0 .123.051.205.055.082.137.137.086.055.189.092.106.035.208.066.086.024.165.05.082.028.143.066.062.034.096.082.038.048.038.113v.007q0 .13-.103.206-.102.075-.263.075-.158 0-.315-.058-.154-.059-.298-.168l-.134.188q.151.12.346.192.195.068.387.068.134 0 .247-.034.116-.037.198-.106.086-.068.134-.164.051-.1.051-.226z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M284.96 129.609h.56v-.233h-.56v-.534h-.264v.534h-.247v.233h.247v1.068q0 .134.037.23.038.095.103.157.069.058.161.086.092.027.199.027.095 0 .17-.02.08-.018.148-.055v-.226q-.068.034-.13.048-.058.013-.127.013-.133 0-.215-.065-.083-.065-.083-.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M286.627 129.585q.25 0 .386.123.14.12.14.36v.061q-.119-.034-.246-.054-.126-.021-.297-.021-.168 0-.309.038-.137.037-.24.11-.099.071-.153.18-.055.107-.055.247t.055.243q.058.103.15.175.096.068.213.103.12.034.243.034.236 0 .39-.093.157-.092.25-.212v.267h.253v-1.082q0-.352-.195-.53-.195-.182-.558-.182-.195 0-.346.041-.15.038-.298.106l.08.216q.122-.058.249-.092.13-.038.288-.038zm.01.664q.164 0 .29.024.131.024.23.052v.17q0 .107-.048.196-.044.085-.126.15-.079.062-.189.1-.106.034-.229.034-.089 0-.171-.024-.079-.024-.14-.068-.062-.048-.1-.113-.034-.065-.034-.151 0-.171.134-.27.133-.1.383-.1z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M288.232 130.133q0-.123.038-.226.041-.103.113-.175.072-.075.168-.113.099-.04.212-.04.236 0 .363.143.127.144.127.39v1.034h.263v-1.099q0-.157-.048-.287-.044-.13-.133-.223-.086-.096-.213-.147-.123-.052-.284-.052-.23 0-.373.103-.144.1-.233.243v-.308h-.263v1.77h.263z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M291.598 130.845l-.168-.158q-.106.113-.24.189-.13.075-.301.075-.14 0-.26-.051-.117-.055-.206-.148-.085-.095-.137-.222-.048-.127-.048-.27 0-.144.048-.268.048-.126.134-.219.086-.096.202-.147.116-.055.25-.055.181 0 .305.079.126.079.232.188l.175-.185q-.062-.065-.134-.123-.072-.058-.157-.1-.086-.044-.188-.068-.103-.024-.23-.024-.195 0-.363.076-.164.072-.287.198-.12.127-.189.298-.068.168-.068.356 0 .188.068.356.069.168.189.295.123.123.287.198.168.072.363.072.25 0 .418-.096.171-.096.305-.246z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M292.139 130.359h1.403q.003-.024.003-.045v-.037q0-.196-.055-.367-.054-.171-.16-.298-.107-.126-.26-.198-.155-.075-.35-.075-.185 0-.342.071-.154.072-.267.199-.113.123-.178.294-.062.168-.062.36 0 .205.069.376.071.172.191.295.12.12.281.188.16.065.342.065.254 0 .425-.089.171-.092.305-.243l-.165-.147q-.11.113-.243.181-.13.069-.315.069-.116 0-.222-.038-.106-.041-.192-.116-.086-.079-.14-.189-.055-.113-.069-.256zm0-.195q.013-.13.061-.24.048-.11.123-.188.076-.083.175-.127.1-.048.216-.048.133 0 .232.051.103.048.172.13.072.083.11.192.04.11.05.23z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
          <path
            d="M294.258 131.146v-.36h-.315v.36z"
            style={{ InkscapeFontSpecification: "Gotham" }}
            fontFamily="Gotham"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SavingsGraph;