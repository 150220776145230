/**
=========================================================
* CareplanONE Website
=========================================================
*/
/* eslint-disable */

import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// CareplanONE Website React themes
import theme from "assets/theme";

// CareplanONE Website React routes
import routes from "routes";

// paywall
import { AuthProvider, useAuth } from "./AuthContext";
import Paywall from "pages/Paywall";
import Home from "pages/Home";

import { Cache } from "aws-amplify/utils";
import { configureAutoTrack, identifyUser } from "aws-amplify/analytics";

const Content = () => {
  // All your main application content goes here.
  // This will be rendered only if a valid email is entered.
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Home />} />
      </Routes>
    </ThemeProvider>
  );
};

function App() {
  // create a new session in Cache
  const uuid = uuidv4();
  Cache.setItem("sessionId", uuid);

  let sessionIp;
  let sessionLocation;

  const sendEndpoint2Pinpoint = async (location, ip) => {
    try {
      const attributes = {
        ip: ip,
      };
      const userProfile = {
        location,
        attributes,
      };
      identifyUser({
        userProfile,
      });
      console.log("sent endpoint to Pinpoint");
    } catch (error) {
      console.log("try to get data from Cache: ", error);
    }
  };

  // Function to get the user's IP address and address only ONCE
  // and put them in global Cache
  const acquireIpAddress = async () => {
    const ip = await Cache.getItem("sessionIp");
    // we only go get the IP address if it is not in global cache
    if (!ip || ip === "unknown") {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        Cache.setItem("sessionIp", data.ip);
        console.log("IP address: ", data.ip);
        sessionIp = data.ip;
      } catch (error) {
        console.error("Failed to fetch IP address: ", error);
        Cache.setItem("sessionIp", "unknown");
        sessionIp = "unknown";
      }
      // only if we are able to retrieve IP that we will get the location
      if (sessionIp != "unknown") {
        try {
          const response = await fetch(`http://ip-api.com/json/${sessionIp}`);
          const data = await response.json();
          console.log("Location: ", JSON.stringify(data));
          sessionLocation = {
            latitude: data.lat,
            longitude: data.lon,
            postalCode: data.zip,
            city: data.city,
            region: data.region,
            country: data.countryCode,
          };
          Cache.setItem("sessionLocation", sessionLocation);
          sendEndpoint2Pinpoint(location, ip);
        } catch (error) {
          console.error(`Failed to fetch address for ${sessionIp}: `, error);
        }
      }
    } else {
      sessionIp = ip;
      sessionLocation = await Cache.getItem("sessionLocation");
    }
  };

  // set browser ip address for the session in Cache
  acquireIpAddress();

  configureAutoTrack({
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
    type: "session",
    options: {
      // OPTIONAL, the attributes of the event
      attributes: {
        //sessionLocation: sessionLocation,
        sessionIp: sessionIp,
      },
    },
  });

  configureAutoTrack({
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
    type: "pageView",
    // OPTIONAL, additional options for the tracked event.
    options: {
      // OPTIONAL, the event name. By default, this is 'pageView'
      eventName: "pageView",

      // OPTIONAL, the type of app under tracking. By default, this is 'multiPageApp'.
      // You will need to change it to 'singlePage' if your app is a single-page app like React
      appType: "singlePage",

      // OPTIONAL, provide the URL for the event.
      urlProvider: () => {
        // the default function
        return window.location.hash;
      },
    },
  });

  const { isAuthenticated } = useAuth();
  return <>{!isAuthenticated ? <Paywall /> : <Content />}</>;
}

export default () => (
  <AuthProvider>
    <CssBaseline />
    <App />
  </AuthProvider>
);
