/* eslint-disable */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { identifyUser } from 'aws-amplify/analytics';
import { Cache } from "aws-amplify/utils";

// Create a new context for the authentication
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const sendUserData2Pinpoint =  async() => {
    try {
      const location = await Cache.getItem('sessionLocation');
      const ip = await Cache.getItem('sessionIp');
      const userAttributes = await fetchUserAttributes();

      const attributes = {
        email: userAttributes.email,
        userType: userAttributes['custom:userType'],
        ip: ip
      };
      const userProfile = {
        location,
        attributes
      };
      identifyUser({
        userId: userAttributes.email,
        userProfile,
      });
      console.log('sent user data to Pinpoint');
    } catch (error) {
      console.log('try to get data from Cache: ', error);
    }
  }

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // get current user
        const { username, userId, signInDetails } = await getCurrentUser();
        if (!userId) {
          setIsAuthenticated(false);
          return;
        }
        console.log(`The username: ${username}`);
        console.log(`The userId: ${userId}`);

        setIsAuthenticated(true);
        // send user info to Pinpoint
        sendUserData2Pinpoint();
      } catch (err) {
        console.log(err);
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  const logIn = () => {
    setIsAuthenticated(true);
    sendUserData2Pinpoint();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, logIn }}>
      {children}
    </AuthContext.Provider>
  );
};
