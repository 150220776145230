/**
=========================================================
* CareplanONE Website
=========================================================






 =========================================================


*/

export default {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};
