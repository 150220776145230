/* eslint-disable */
// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { Box, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";

function IconItem({ icon, content, textColor, textVariant }) {
  return (
    <Box display="flex" alignItems="center" p={1}>
      <Box
        width="3rem"
        variant="gradient"
        bgcolor="info"
        color={textColor}
        coloredshadow="info"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="xl"
      >
        <Icon fontSize="small" color={textColor}>
          {icon}
        </Icon>
      </Box>
      <Typography variant={textVariant} color={textColor} pl={1}>
        {content}
      </Typography>
    </Box>
  );
}

// Typechecking props for the IconItem
IconItem.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  textColor: PropTypes.string,
  textVariant: PropTypes.string, // Add propType for textVariant
};

// Default props for the IconItem
IconItem.defaultProps = {
  textColor: colors.white.main, // make sure this references the correct white color in your theme
  textVariant: "info", // Set the default variant to "info"
};

export default IconItem;