/*
=========================================================
* CareplanONE Website
=========================================================
*/

/* eslint-disable */
// PUT SEPARATORS BETWEEN COMPONENTS ON CARD

// styles
import colors from "assets/theme/base/colors";
const { white, grey, cpAqua } = colors;
import MuiLink from "@mui/material/Link";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconItem from "framework/IconItem";
// Images
import bgImage from "assets/images/cp1-bg-careteam.jpg";
import logo from "assets/images/cp1-logo-dark-mode-white-contrast.png";
import { Box, Link, Card, Container, Grid, Typography, Stack } from "@mui/material";
import FilledInfoCard from "framework/Cards/InfoCards/FilledInfoCard";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';

function Offerings() {
  const [isVisible, visibleTime, ref] = useVisiblityTimer('Offerings', { threshold: 0.5 });
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div ref={ref}>
      <Box
        component="header"
        minHeight="50vh"
        width="100%"
        sx={({ breakpoints, typography: { size } }) => ({
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "2%",
          paddingTop: "3%",
          paddingBottom: "3%",
          display: "grid",
        })}
      >
        <Container maxWidth="xl">
          <LogoLink logo={logo} textAlign="left" />
        </Container>
        <Container maxWidth={isSmOrDown ? false : 'xl'} disableGutters={isSmOrDown}>
          <Card
            sx={{
              py: "2%",
              mt: "360px",
              backgroundColor: ({ palette: { cpAqua }, functions: { rgba } }) => rgba(cpAqua.main, 0.4),
              backdropFilter: "saturate(200%) blur(30px)",
              width: "100%"
            }}
          >
            <Box component="section">
              <Container maxWidth='xl'>
                <Grid container alignItems="center">
                  <Grid item sm={12}>
                    <Box>
                      <Box>
                        <Typography variant="h3" sx={{ mb: "2%" }} color={white.main}>
                          Patient engagement for every care journey
                        </Typography>
                      </Box>
                      {/* <Card sx={{ padding: "3%" }}> */}
                      <Box>
                        <Grid container alignItems="top">
                          <Grid item md={6} sm={12}>
                            <Stack>
                              <IconItem icon="check_box_outline_blank" content="Anesthesiology" />
                              <IconItem icon="check_box_outline_blank" content="Cardiology" />
                              <IconItem icon="check_box_outline_blank" content="Gastroenterology" />
                              <IconItem icon="trip_origin" content="General Surgery" />
                              <IconItem
                                icon="check_box_outline_blank"
                                content="Obstetrics and Gynecology Otolaryngology"
                              />
                            </Stack>
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <Stack>
                              <IconItem icon="trip_origin" content="Orthopaedic Surgery" />
                              <IconItem icon="trip_origin" content="Plastic Surgery" />
                              <IconItem icon="check_box_outline_blank" content="Radiology" />
                              <IconItem icon="check_box_outline_blank" content="Urology" />
                              <IconItem icon="change_history" content="And more..." />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid item sm={12}>
                        <FilledInfoCard
                          action={{
                            type: "internal",
                            route: "/#solutions-orthopaedics",
                            color: "light",
                            label: "Learn more",
                          }}
                          color={colors.white.main}
                        />
                      </Grid>
                      {/* </Card> */}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Card>
        </Container>
      </Box>
    </div>
  );
}

export default Offerings;
