/* eslint-disable */
// @mui material components
import { Container, Grid, Box, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import BenefitCardImage from "assets/images/benefit-card-image.jpg";
import FilledInfoCard from "framework/Cards/InfoCards/FilledInfoCard";
import logo from "../../../assets/images/cp1-logo.png";
import { Link } from "react-router-dom";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';

function ProductBenefits() {
  const [isVisible, visibleTime, ref] = useVisiblityTimer('ProductBenefits', { threshold: 0.5 });
  return (
    <Box ref={ref}>
      <Container maxWidth="xl">
        <LogoLink />
        <Typography
          variant="h3"
          color="black"
          sx={({ breakpoints, typography: { size } }) => ({
            marginBottom: "3%",
            marginTop: "5%",
          })}
        >
          Reduce readmissions
          <br />
          Lower your cost of care
        </Typography>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start" spacing={0}>
              <Grid item xs={12} md={6}>
                <Box mb={1}>
                  <FilledInfoCard
                    icon="check_circle"
                    title="Pre-op: Prepare patients for success"
                    description="CareplanONE keeps patients on track for their surgery with reminders, checklists, assessments, and education. Patients can access their care plan anytime from their smartphone."
                    action={{
                      type: "internal",
                      route: "/#benefits-patient",
                      color: "info",
                      label: "Learn more",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={1}>
                  <FilledInfoCard
                    icon="spa"
                    title="Post-op: Optimize recovery"
                    description="CareplanONE stays with patients throughout their recovery; providing video guides and education on physical therapy, wound care, and pain management."
                    action={{
                      type: "internal",
                      route: "/#benefits-patient",
                      color: "info",
                      label: "Learn more",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={1}>
                  <FilledInfoCard
                    icon="clear_all"
                    title="Address potential issues early"
                    description="CareplanONE acts as an early warning system for your care team, notifying them of high risk patients who may experience problems in either the pre- or post-op phase. Thousands of patients have used CareplanONE to navigate their care journey already, making the software smarter every day."
                    action={{
                      type: "internal",
                      route: "/#benefits-address-early",
                      color: "info",
                      label: "Learn more",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={8}>
                  <FilledInfoCard
                    icon="paid"
                    title="Succeed in value-based programs"
                    description="Tracking your patient’s pre- and post-op behavior gives you greater control of your cost of care for every procedure performed. Optimize your surgical calendar by reducing cancellations, complications, and readmissions. CareplanONE's data can also be shared with your EHR for convenient access."
                    action={{
                      type: "internal",
                      route: "/#benefits-value-based-program",
                      color: "info",
                      label: "Learn more",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          component="img"
          src={BenefitCardImage}
          sx={{ display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "2%",
          width: {xs: "40%"} }}
        />
      </Container>
    </Box>
  );
}

export default ProductBenefits;
