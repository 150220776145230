import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { Box, Typography } from "@mui/material";

function DefaultCounterCard({ color, count, title, description, animateCount, ...rest }) {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1); // Increment key to force re-render
  }, [animateCount]); // Dependency array with animateCount

  return (
    <Box p={2} textAlign="center" lineHeight={1}>
      <Typography variant="h3" color={color} textgradient>
        {animateCount ? <CountUp key={key} end={count} duration={1} {...rest} /> : count}
      </Typography>
      {title && (
        <Typography variant="h5" mt={2} mb={1}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="body2" color="text">
          {description}
        </Typography>
      )}
    </Box>
  );
}

DefaultCounterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  count: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  animateCount: PropTypes.bool,
};

export default DefaultCounterCard;
