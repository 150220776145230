/* eslint-disable */
/**
=========================================================
* CareplanONE Website
=========================================================
*/

import { useState } from "react";

// react-router components
//import { Link } from "react-router-dom";
import Link from "@mui/material/Link";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";
const { white, grey, cpAqua } = colors;
// @mui material components
import Collapse from "@mui/material/Collapse";
import MuiLink from "@mui/material/Link";

// CareplanONE Website React examples
import DefaultNavbarDropdown from "framework/Navbars/DefaultNavbar/DefaultNavbarDropdown";
import { Box, Typography } from "@mui/material";

function DefaultNavbarMobile({ routes, open }) {
  const [collapse, setCollapse] = useState("");

  const handleSetCollapse = (name) => (collapse === name ? setCollapse(false) : setCollapse(name));

  const renderNavbarItems = routes.map(
    ({ name, icon, collapse: routeCollapses, href, route, collapse: navCollapse }) => (
      <DefaultNavbarDropdown
        key={name}
        name={name}
        icon={icon}
        collapseStatus={name === collapse}
        onClick={() => handleSetCollapse(name)}
        href={href}
        route={route}
        collapse={Boolean(navCollapse)}
      ></DefaultNavbarDropdown>
    )
  );

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <Box
        width="calc(50%)"
        my={0}
        mr={-2}
        sx={{
          backgroundColor: grey.main,
          backdropFilter: `saturate(200%) blur(130px)`,
          marginLeft: "auto", // This will push the box to the right,
        }}
      >
        {renderNavbarItems}
      </Box>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
