/**
=========================================================
* CareplanONE Website
=========================================================
*/

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
Amplify.configure(amplifyconfig);
// Amplify.configure({
//   ...Amplify.getConfig(),
//   Analytics: {
//     KinesisFirehose: {
//       region: "us-east-2",
//     },
//   },
// });
// console.log(Amplify.getConfig());
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
