/* eslint-disable */
const { Box, Container, Typography, Card, Grid, Stack } = require("@mui/material");
import MuiLink from "@mui/material/Link";

// styles
import colors from "../../../../assets/theme/base/colors";
const { white, grey, cpAqua, black } = colors;
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import testimonialsPhone from "../../../../assets/images/cp1-testimonials-phone.png";
import boneIcon from "../../../../assets/images/cp1-bone.png";
import IconItem from "framework/IconItem";
import { LogoLink } from "framework/LogoLink";

import { useVisiblityTimer } from 'useVisiblityTimer';

function OrthopaedicCarePlans() {
    const [isVisible, visibleTime, ref] = useVisiblityTimer("OrthopaedicCarePlans", { threshold: 0.5 });
    const theme = useTheme();
    const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div ref={ref}>
            <Box
                component="header"
                sx={() => ({
                    display: "grid",
                    mx: "auto",
                    px: {xs: "3%", sm: 0}
                })}
            >
                <Container maxWidth={isSmOrDown ? false : 'xl'} disableGutters={isSmOrDown}>
                    <LogoLink />
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" }, width: "100%", }}>
                        <Box component="img" src={testimonialsPhone} sx={{ width: '35%', height: '35%', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }}} />
                        <Box sx={{
                            height: "100%",
                            width: {xs: "100%", sm: "60%"},
                            textAlign: "left",
                            pl: { xs: "0", sm: "0", md: "3%", lg: "3%", xl: "3%" },
                            mt: { xs: "4%", sm: "4%", md: "0", lg: "0", xl: "0" }
                        }}>
                            <Box component="img" src={boneIcon} sx={{ maxWidth: '7rem', }} />
                            <Box sx={{ mb: "3%" }}>
                                <Typography variant="h3">
                                    CareplanONE for Orthopaedics
                                </Typography>
                                <Typography variant="body2">
                                    Our orthopaedic care plans focus on preparation and post-op care for surgical procedures such as total joint replacements and arthroscopic procedures. Thousands of patients have used CareplanONE to simplify their care journey already, making the system smarter every day.
                                </Typography>
                            </Box>
                            <Typography variant="body2">
                                Example care plans:
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", color: black.main }}>
                                <Grid container alignItems="top">
                                    <Grid item md={6} sm={12}>
                                        <Stack>
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Total knee arthroplasty" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Partial knee arthroplast" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Total shoulder arthroplasty" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Partial shoulder arthroplasty" />
                                            <IconItem
                                                textColor={black.main} 
                                                icon="trip_origin"
                                                textVariant="body2" 
                                                content="Total hip arthroplasty"
                                            />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Partial hip arthroplasty" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Knee revision" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Hip revision" />
                                        </Stack>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Stack>
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Knee arthroscopy" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="ACL reconstruction" />
                                            <IconItem textColor={black.main} icon="trip_origink" textVariant="body2" content="PCL reconstruction" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Lumbar laminectomy/ discectomy" />
                                            <IconItem textColor={black.main} icon="change_history" textVariant="body2" content="Lumbar laminectomy/ fusion" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Lumbar spine fusion" />
                                            <IconItem textColor={black.main} icon="trip_origin" textVariant="body2" content="Fusion/bone stimulatory implant" />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}

export default OrthopaedicCarePlans;