/* eslint-disable */
const { Box, Container, Typography, Card } = require("@mui/material");
import MuiLink from "@mui/material/Link";

// styles
import colors from "../../../../assets/theme/base/colors";
const { white } = colors;
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import bgExec from "../../../../assets/images/cp1-execdashboard-flipped.png";
import testimonialsPhone from "../../../../assets/images/cp1-testimonials-phone.png";
import logo from "../../../../assets/images/cp1-logo-dark-mode-white-careplan-link.png";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';
function Testimonials() {
    const [isVisible, visibleTime, ref] = useVisiblityTimer('Testimonials', { threshold: 0.5 });
    const theme = useTheme();
    const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div ref={ref}>
            <Box
                component="header"
                width="100%"
                height="100%"
                sx={() => ({
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center"
                })}
            >
                <Container maxWidth='xl'>
                    <LogoLink logo={logo} textAlign="left" />
                </Container>

                <Container maxWidth="xl">
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" }, 
                    mx: "auto", width: { sm: "97%", lg: "75%", xl: "60%" }}}>
                        <Card sx={{
                            py: "2%",
                            backgroundColor: "rgba(0, 0, 0, 1)", // white with 85% opacity
                        }}>
                            <Container maxwidth="xl">
                                <Box sx={{
                                    height: "100%",
                                    width: "100%",
                                    textAlign: "left",
                                    padding: "3%"
                                }}>
                                    <Box>
                                        <Typography variant="h5" color={white.main}>
                                            “I was scared before my surgery and thought, ‘the less I know the better’. I started using CareplanONE and—I’m not saying I’m a surgeon, but—I felt educated on the process and much more confident about my surgery.”
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mt: "1em", }}>
                                        <Typography variant="body2" color={white.main}>
                                            CareplanONE patient <br />
                                            Laminectomy and fusion
                                        </Typography>
                                    </Box>
                                </Box>
                            </Container>
                        </Card>
                        <Box component="img" src={testimonialsPhone} sx={{ maxWidth: '20rem', maxHeight: '100%', mx: "auto", display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />
                    </Box>
                </Container>
            </Box>
        </div>
    )
}

export default Testimonials;