/* eslint-disable */

import React from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import PageOneImage from "assets/images/jen.jpg";
import PageTwoImage from "assets/images/henry.jpg";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from "useVisiblityTimer";

function PatientBenefits() {
  const [isVisible, visibleTime, ref] = useVisiblityTimer("PatientBenefits", { threshold: 0.5 });
  return (
    <div ref={ref}>
      <Card sx={{ m: "2%", px: "3%" }}>
        <LogoLink />
        <Typography
          variant="h3"
          color="black"
          sx={({ breakpoints, typography: { size } }) => ({
            /*marginLeft: '40%',
          marginRight: '40%',*/
            marginBottom: "3%",
          })}
        >
          How can you assure patients are ready for surgery?
        </Typography>
        <Grid container spacing={2} sx={{ position: "relative" }}>
          {/* Left Side Card and Image */}
          {/* Card component positioned over the image on the left */}
          <Card
            title="How can you ensure patients
        are ready for surgery?"
            sx={{
              position: "absolute",
              mx: { xs: "20px", sm: "20px" },
              top: { xs: "15%", sm: "15%", md: "10%" }, // Overlaps the top 20% of the image
              width: { md: "30%", lg: "30%" }, // The card occupies 30% of the screen width
              left: { xs: "3%", sm: "3%", md: "3%" }, // Position the card start at 3% from the left of the screen to center it
              zIndex: 2, // Ensure the card is above the image
              backgroundColor: "rgba(255, 255, 255, 0.9)", // White background with 10% transparency
            }}
          >
            <CardContent>
              {/* Card content */}
              <Typography variant="h5" component="div">
                Streamline pre-op readiness
              </Typography>
              <Typography variant="body2">
                CareplanONE helps patients to stay on track with mobile scheduling, reminders, video
                guides, pre-op assessments, and more. All delivered to their personal device in a
                secure and convenient mobile-based experience.
              </Typography>
            </CardContent>
          </Card>
          {/* Left Image Grid item */}
          <Grid item sm={12} md={3} sx={{ zIndex: 1 }}></Grid>
          <Grid item sm={12} md={3} sx={{ zIndex: 1 }}>
            <Box
              component="img"
              sx={{
                width: 1, // 100% width of the grid item
                height: "auto", // Maintains the aspect ratio of the image
              }}
              src={PageOneImage}
              alt="Description of the image"
            />
          </Grid>

          {/* Right Side Card and Image */}
          {/* Card component positioned over the image on the right */}
          <Card
            sx={{
              position: "absolute",
              top: { xs: "64%", sm: "64%", md: "10%" }, // Overlaps the top 20% of the image
              width: { md: "30%", lg: "30%" }, // The card occupies 30% of the screen width
              left: { xs: "3%", sm: "3%", md: "53%" },
              right: { md: "3%", lg: "17%" }, // Position the card start at 3% from the right of the screen to center it
              zIndex: 2, // Ensure the card is above the image
              backgroundColor: "rgba(255, 255, 255, 0.9)", // White background with 10% transparency
              mx: { xs: "20px", sm: "20px" },
            }}
          >
            <CardContent>
              {/* Card content */}
              <Typography variant="h5" component="div">
                Empower recovery at home
              </Typography>
              <Typography variant="body2">
                Post-op, CareplanONE builds a recovery plan for each patient assisting them with
                physical therapy, wound care, pain management, and more.
              </Typography>
            </CardContent>
          </Card>
          {/* Right Image Grid item */}
          <Grid item sm={12} md={3} sx={{ zIndex: 1 }}></Grid>
          <Grid item sm={12} md={3} sx={{ zIndex: 1, marginLeft: "auto" }}>
            <Box
              component="img"
              sx={{
                width: 1, // 100% width of the grid item
                height: "auto", // Maintains the aspect ratio of the image
              }}
              src={PageTwoImage}
              alt="Description of the image"
            />
          </Grid>

          {/* ...other Grid items or content... */}
        </Grid>
      </Card>
    </div>
  );
}

export default PatientBenefits;
