/**
=========================================================
* CareplanONE Website
=========================================================






 =========================================================


*/

export default {
  defaultProps: {
    disableRipple: false,
  },
};
