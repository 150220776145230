/* eslint-disable */
const { Box, Container, Typography, Card } = require("@mui/material");
import MuiLink from "@mui/material/Link";

// styles
import colors from "../../../../assets/theme/base/colors";
const { white, grey, cpAqua, black } = colors;
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import bgSavings from "../../../../assets/images/cp1-bg-savings.png";
import logo from "../../../../assets/images/cp1-logo-dark-mode.png";
import { Link } from "react-router-dom";
import SavingsGraph from "./SavingsGraph";
import { LogoLink } from "framework/LogoLink";
import { useVisiblityTimer } from 'useVisiblityTimer';
function Savings() {
    const [isVisible, visibleTime, ref] = useVisiblityTimer('Savings', { threshold: 0.5 });
    const theme = useTheme();
    const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div ref={ref}>
            <Box
                component="header"
                width="100%"
                sx={() => ({
                    display: "grid",
                })}
            >
                <Box
                    minHeight="100vh"
                    width="100%"
                    sx={({ breakpoints, typography: { size } }) => ({
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.dark.main, 0),
                                rgba(gradients.dark.state, 0)
                            )}, url(${bgSavings})`,
                        backgroundSize: {xs: '200%', sm: '200%', md: 'cover', lg: 'cover'},
                        backgroundPosition: {xs: 'top', sm: 'top', md: 'top', lg: 'center'},
                        backgroundRepeat: "no-repeat",
                        textAlign: "right",
                        padding: "2%",
                        mx: "auto",
                        minHeight: {xs: "70vh", sm: "80vh"}
                    })}>
                </Box>
                <Container maxWidth={isSmOrDown ? false : 'xl'} disableGutters={isSmOrDown}>
                    <Card sx={{
                        py: "5%",
                        width: "100%",
                        mt: "-16em",
                    }}>
                        <Container width="xl">
                            <LogoLink />
                            <Typography variant="h3" sx={{
                                mt: "2%",
                                mb: "-3%"
                            }}>
                                CareplanONE Cost Savings
                            </Typography>
                            <SavingsGraph />
                        </Container>
                    </Card>
                   {/*<Card sx={{
                        py: "5%",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.93)", // white with 85% opacity
                        mt: "1%"
                    }}>
                        <Container width="xl">
                            <Box sx={{
                                mt: "1%", height: "100%",
                                width: "100%",
                                textAlign: {sm: "left", md: "center"},
                            }}>
                                <Box component={MuiLink} href="/">
                                    <Box component="img" src={logo} sx={{ maxWidth: { xs: "8em", sm: "11em" , md: '15rem' } }} />
                                </Box>
                                <Typography variant="h3" color={white.main}>
                                    Reduce readmissions <br />
                                    Lower your total cost of care
                                </Typography>
                                <Typography variant="body2" color={white.main}>
                                    With CareplanONE you can produce savings across the entire surgical episode. <br /><br />
                                </Typography>
                                <Typography variant="body2" color={white.main}>
                                    Get started at careplanone.com<br />
                                    or call 1-720-244-8300.
                                </Typography>
                            </Box>
                        </Container>
                        </Card>*/}
                </Container>
            </Box>
        </div>
    )
}

export default Savings;